<script setup lang="ts">
import { inject, ref, onMounted, type Ref } from "vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import D from "@/composables/TimeDisplay"
import API from "@/api/api"
import * as zod from "zod"

const schema = zod.object({
  title: zod.string()
    .min(3, { message: "Minimum 3 characters." }),
  audience: zod.string()
    .min(3, { message: "Minimum 3 characters." }),
  html: zod.string()
    .min(3, { message: "Minimum 3 characters." })
})

const isOwner = inject<Ref<boolean>>("isOwner") as Ref<boolean>
const isOrganizer = inject<Ref<boolean>>("isOrganizer") as Ref<boolean>
const short = inject<Ref<string>>("short") as Ref<string>

const working = ref(false)
const error = ref(false)
const editorOpen = ref(false)
const initialLoadComplete = ref(false)
const updates = ref<any[]>([])

onMounted(async () => {
  await getChangelog()
  initialLoadComplete.value = true
})

async function getChangelog() {
  if (working.value) return
  working.value = true

  let url: string = `/events/${short.value}/updates`

  try {
    const response = await API().get(url)
    const data = response.data
    updates.value.push(...data.data)
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
}

async function addUpdate(values: any) {
  if (working.value) return
  working.value = true

  const payload: any = {
    title: values.title,
    audience: values.audience,
    html: values.html,
  }

  try {
    await API().post(`/events/${short.value}/updates`, payload)
    working.value = false
    editorOpen.value = false
    // add the new post to the top of the list
    updates.value.unshift({
      ...payload,
      createdAt: new Date()
    })
  } catch (err) {
    error.value = true
    working.value = false
  }
}

async function deleteUpdate(id: string) {
  if (working.value) return
  working.value = true

  try {
    await API().delete(`/events/${short.value}/updates`, { data: { update: id } })
    updates.value = updates.value.filter((update: any) => update._id !== id)
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <main class="grid gap-4 content-start">
    <header class="flex justify-between items-center text-sm">
      <h2 class="font-bold mb-1">Updates</h2>
      <button v-if="(isOwner || isOrganizer) && !editorOpen" @click="editorOpen = true">Add Update</button>
    </header>
    <section v-if="editorOpen">
      <Form @on-submit="addUpdate" class="grid grid-cols-2 gap-2" :schema="schema">
        <div>
          <Label field="title">Title</Label>
          <Field name="title" />
        </div>
        <div>
          <Label field="audience">Audience</Label>
          <Field name="audience" type="select">
            <option value="public">Public</option>
            <option value="attendees">Attendees</option>
          </Field>
        </div>
        <div class="col-span-2">
          <Label field="html">Update</Label>
          <Editor name="html" />
        </div>
        <div class="flex justify-end gap-4 col-span-2 text-sm">
          <button type="button" class="text-gray-400" @click="editorOpen = false">Cancel</button>
          <button type="submit" class="bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5">{{ working ? 'Adding...' : 'Add Update' }}</button>
        </div>
      </Form>
    </section>
    <section>
      <p v-if="!initialLoadComplete">Loading...</p>
      <p v-if="initialLoadComplete && error">Error loading updates.</p>
      <p v-if="initialLoadComplete && updates.length === 0">The organizer(s) of this event can post updates here. There are no updates yet.</p>
      <ul v-if="initialLoadComplete && updates.length > 0" class="space-y-6">
        <li v-for="update in updates" :key="update.createdAt">
          <header class="mb-4">
            <h1 class="font-display text-xl font-bold mb-0.5">{{ update.title }}</h1>
            <h2 class="text-sm dark:text-gray-500 text-gray-500">Shared on {{ D(update.createdAt).format('LLLL') }} <span class="italic dark:text-gray-400 text-gray-600" v-if="update.audience === 'attendees'">(For Attendee Eyes Only)</span></h2>
          </header>
          <article class="CommonBody" v-html="update.html"></article>
          <footer v-if="isOwner || isOrganizer" class="mt-4 text-xs font-semibold">
            <button @click="deleteUpdate(update._id)" class="text-red-600 hover:text-red-500 px-4 py-2 rounded-md dark:bg-submit-700 bg-gray-300 hover:bg-white dark:hover:bg-submit-500">Delete Update</button>
          </footer>
        </li>
      </ul>
    </section>
  </main>
</template>
