<script setup lang="ts">
import { inject, type Ref } from "vue"

const Group = inject('Group') as Ref<any>
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <section class="space-y-6">
      <article>
        <h2 class="font-bold text-sm mb-2">About this Group</h2>
        <div class="EditorBody" v-html="Group.desc ? Group.desc : Group.summary"></div>
        <p class="mt-4"><small>Created on {{ Group.createdAt }}.</small></p>
      </article>
      <article>
        <h2 class="font-bold text-sm mb-2">Group Restrictions</h2>
        <p>{{ Group.restrictions && Group.restrictions.length > 0 ? Group.restrictions : 'This group has no restrictions.'}}</p>
      </article>
      <article>
        <h2 class="font-bold text-sm mb-2">Group Rules</h2>
        <ul v-if="Group.rules && Group.rules.length > 0" class="list-decimal ml-4 space-y-3">
          <li v-for="rule in Group.rules" class="text-sm">
            <h3 class="font-bold">{{ rule.title }}</h3>
            <p>{{rule.desc}}</p>
          </li>
        </ul>
        <p v-if="Group.rules && Group.rules.length === 0">This group has no rules.</p>
        <p v-if="!Group.rules">This group has no rules.</p>
      </article>
      <article v-if="Group.type !== 'official'">
        <h2 class="font-bold text-sm mb-2">Group Moderation Team</h2>
        <p class="mb-2">Owner: <router-link :to="`/${Group.ownerUsername}`">{{ Group.ownerUsername }}</router-link></p>
        <ul class="inline">
          <li class="inline-flex items-center">
            Moderators: <span v-if="(Group.moderators && Group.moderators.length === 0) || (!Group.moderators)">&nbsp;None</span>
          </li>
          <li v-if="Group.moderators && Group.moderators.length > 0" v-for="(moderator, index) in Group.moderators" class="inline">
            {{moderator.username}}{{ index < Group.moderators.length - 1 ? ', ' : ''}}
          </li>
        </ul>
      </article>
    </section>
  </div>
</template>
