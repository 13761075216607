<script setup lang="ts">
import { useHead } from "@unhead/vue"

useHead({
  title: "Contact - Submit",
  meta: [
    { name: "description", content: "Contact Submit for general inquiries, support, or legal requests." },
    { name: "robots", content: "index,follow" },
  ]
})
</script>

<template>
  <main class="px-2">
    <section class="my-24 max-w-[672px] lg:max-w-[656px] w-full mx-auto flex flex-col flex-grow">
      <header class="text-center space-y-3 mb-16">
        <h1 class="font-display text-5xl font-bold dark:text-gold-500 text-gold-700">Contact</h1>
      </header>
      <article class="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <section class="col-span-1 sm:col-span-2 space-y-2">
          <h2 class="text-2xl">General Enquiries, Support, and Questions</h2>
          <p>You can contact us via email. We have a few different email addresses for different purposes. You can find them below.</p>
        </section>
        <section>
          <h2 class="text-xl mb-2">General</h2>
          <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="mailto:hello@submit.gg">hello@submit.gg</a>
        </section>
        <section>
          <h2 class="text-xl mb-2">Support</h2>
          <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="mailto:support@submit.gg">support@submit.gg</a>
        </section>
        <section>
          <h2 class="text-xl mb-2">Legal Requests</h2>
          <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="mailto:legal@submit.gg">legal@submit.gg</a>
        </section>
        <section>
          <h2 class="text-xl mb-2">Privacy Inquiries</h2>
          <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="mailto:privacy@submit.gg">privacy@submit.gg</a>
        </section>
        <section>
          <h2 class="text-xl mb-2">Press Inquiries</h2>
          <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="mailto:press@submit.gg">press@submit.gg</a>
        </section>
      </article>
    </section>
  </main>
</template>
