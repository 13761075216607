<script setup lang="ts">
import { onBeforeMount, onUnmounted, reactive, ref } from "vue"
import PageHeader from "@/components/core/PageHeader.vue"
import CustomField from "@/components/input/Custom.vue"
import { usePlatformStore } from "@/stores/Platform"
import Form from "@/components/input/Form.vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const searchSchema = zod.object({
  search: zod.string()
    .min(3, { message: "Minimum 3 characters." })
})

const Platform = usePlatformStore()
const { loading, searchData, searchOpen, searchQuery } = storeToRefs(Platform)

const initialData = reactive({
  search: ""
})
const searchComplete = ref(false)
const working = ref(false)
const error = ref(false)

onBeforeMount(() => {
  if (searchOpen.value) {
    searchComplete.value = true
    if (searchQuery.value && searchQuery.value.length >= 3) {
      initialData.search = searchQuery.value
    }
    Platform.toggleSearchOpen()
  }
})

onUnmounted(() => {
  Platform.setSearchQuery("")
  Platform.setSearchData({})
})

async function runSearch(values: any) {
  if (working.value) return
  working.value = true

  const data = {
    query: values.search
  }

  try {
    const response = await API().post(`/search`, data)
    Platform.setSearchQuery(values.search)
    Platform.setSearchData(response.data)
    searchComplete.value = true
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <main v-if="!loading">
    <PageHeader>Search</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-full">
          <div>
            <Form @on-submit="runSearch" :initial-values="initialData" :schema="searchSchema" v-slot="{ fields, errors }">
              <div class="flex w-full items-center border-b border-gray-500 border-opacity-20">
                <CustomField name="search" custom-class="h-12 flex-grow border-0 bg-transparent pr-4 dark:text-white focus:ring-0 outline-none border-0 focus:ring-0 focus:border-0 sm:text-sm" placeholder="Search" />
                <button type="submit" class="flex mr-4 ml-4 flex-none">
                  <svg class="fill-gray-500 hover:fill-black dark:hover:fill-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
                </button>
              </div>
              <div class="text-red-500 text-xs font-bold mt-2" v-if="errors.search">
                {{ errors.search }}
              </div>
            </Form>
          </div>
          <div class="space-y-6 mt-6" v-if="searchComplete && !working">
            <div class="space-y-3">
              <h4 class="font-bold text-sm">Users</h4>
              <ul class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <li v-if="searchData.users.length > 0" v-for="item in searchData.users">
                  <router-link class="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 bg-white dark:bg-submit-900 rounded-md w-full" :to="`/${item.username}`">
                    <div class="flex items-center gap-2">
                      <figure class="flex flex-none w-12 h-12 bg-slate-300 dark:bg-[#707482] text-indigo-950 rounded-full items-center text-2xl justify-center font-semibold bg-cover" :style="item.pfp ? `background-image: url('${item.pfp}')` : ''">
                        {{ item.pfp ? '' : item.firstLetter }}
                      </figure>
                      <div>
                        <h1 class="flex text-base sm:text-lg font-display font-semibold sm:leading-6 break-all gap-x-2 items-baseline">
                          {{ item.username }}
                        </h1>
                      </div>
                    </div>
                  </router-link>
                </li>
                <li class="text-sm" v-if="searchComplete && searchData.users.length === 0">
                  No users found.
                </li>
              </ul>
            </div>
            <div class="space-y-3">
              <h4 class="font-bold text-sm">Media</h4>
              <ul class="grid gap-3 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
                <li v-if="searchData.media.length > 0" v-for="item in searchData.media">
                  <router-link :to="`/${item.user}/media/${item._id}`">
                    <img class="aspect-[4/3] object-cover rounded-lg pointer-events-none" loading="lazy" :width="item.width" :height="item.height" :src="item.url" />
                  </router-link>
                </li>
                <li class="text-sm" v-if="searchComplete && searchData.media.length === 0">
                  No media found.
                </li>
              </ul>
            </div>
            <div class="space-y-3">
              <h4 class="font-bold text-sm">Writings</h4>
              <ul class="grid gap-3">
                <li v-if="searchData.writings.length > 0" v-for="item in searchData.writings">
                  <router-link class="dark:bg-submit-900 bg-white block w-full rounded-md p-4" :to="`/${item.user}/writing/${item._id}`">
                    <h3 class="text-xl font-semibold">{{ item.title }}</h3>
                    <p v-if="item.description">{{ item.description }}</p>
                  </router-link>
                </li>
                <li class="text-sm" v-if="searchComplete && searchData.writings.length === 0">
                  No writings found.
                </li>
              </ul>
            </div>
            <div class="space-y-3">
              <h4 class="font-bold text-sm">Groups</h4>
              <ul class="grid gap-3">
                <li v-if="searchData.groups.length > 0" v-for="item in searchData.groups">
                  <router-link class="dark:bg-submit-900 bg-white block w-full rounded-md p-4" :to="`/groups/${item.slug}`">
                    <h3 class="text-xl font-semibold">{{ item.name }}</h3>
                    <ul class="text-sm flex gap-4 mb-3">
                      <li>Members: {{ item.memberCount }}</li>
                      <li>Posts: {{ item.postCount }}</li>
                    </ul>
                    <p v-if="item.summary">{{ item.summary }}</p>
                  </router-link>
                </li>
                <li class="text-sm" v-if="searchComplete && searchData.groups.length === 0">
                  No groups found.
                </li>
              </ul>
            </div>
            <div class="space-y-3">
              <h4 class="font-bold text-sm">Kinks &amp; Interests</h4>
              <ul class="grid gap-3">
                <li v-if="searchData.interests.length > 0" v-for="item in searchData.interests">
                  <router-link class="dark:bg-submit-900 bg-white block w-full rounded-md p-4" :to="`/${item.type === 'official' ? 'kinks' : 'interest'}/${item.slug}`">
                    <h3 class="text-xl font-semibold">{{ item.name }}</h3>
                    <p class="text-sm">{{ item.interestCounter }} users, {{ item.associatedGroupCounter }} groups</p>
                  </router-link>
                </li>
                <li class="text-sm" v-if="searchComplete && searchData.interests.length === 0">
                  No kinks found.
                </li>
              </ul>
            </div>
          </div>
          <div class="space-y-3 mt-6" v-if="!searchComplete && !working">
            Enter a search query above to begin.
          </div>
          <div class="space-y-3 mt-6" v-if="working">
            Searching...
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
