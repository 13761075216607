<script setup lang="ts">
import Collection from "@/components/writing/Collection.vue"
import { useRouter, onBeforeRouteUpdate } from "vue-router"
import { inject, ref, onBeforeMount } from "vue"

const profileData: any = inject("profileData")
const router = useRouter()
const canLoad = ref(false)

onBeforeMount(async () => {
  if (!profileData.value.self) {
    return router.push({ name: "Profile.Writing.Writings", params: { username: profileData.username } })
  } else {
    canLoad.value = true
  }
})

onBeforeRouteUpdate(async (to, from) => {
  if (!profileData.value.self) {
    return router.push({ name: "Profile.Writing.Writings", params: { username: profileData.username } })
  } else {
    canLoad.value = true
  }
})
</script>

<template>
  <Collection collection="drafts" v-if="canLoad" />
</template>
