<script setup lang="ts">
import FollowList from "@/components/profile/FollowList.vue"
import { inject, onBeforeMount } from "vue"

const profileData: any = inject("profileData")

onBeforeMount(() => {
  document.title = `${profileData.value.username}'s Following - Submit`
})
</script>

<template>
  <section class="px-2">
    <FollowList :type="'following'" />
  </section>
</template>
