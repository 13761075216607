<script setup lang="ts">
import AuthWrapper from "@/components/core/AuthWrapper.vue"
import { useUserStore } from "@/stores/User"
import { onBeforeMount } from "vue"

const userStore = useUserStore()

onBeforeMount(async () => {
  await userStore.signOut()
  window.localStorage.removeItem("submit:user")
})
</script>

<template>
  <AuthWrapper>
    <section>
      <h1 class="font-display text-2xl dark:text-white font-bold mb-10">{{ $t("signedout") }}</h1>
      <p>You have been signed out.</p>
    </section>
  </AuthWrapper>
</template>
