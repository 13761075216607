<script setup lang="ts">
import FollowList from "@/components/profile/FollowList.vue"
import { inject, onBeforeMount } from "vue"

const profileData: any = inject("profileData")
const profileContext: any = inject("profileContext")

onBeforeMount(() => {
  if (profileContext.value === "user") {
    document.title = `${profileData.value.username}'s Followers - Submit`
  }
  if (profileContext.value === "organization") {
    document.title = `${profileData.value.name}'s Followers - Submit`

  }
})
</script>

<template>
  <section class="px-2">
    <FollowList :type="'followers'" />
  </section>
</template>
