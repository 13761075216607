<script setup lang="ts">
import { inject, onBeforeMount, computed, ref, type Ref } from "vue"
import Checkbox from "@/components/input/Checkbox.vue"
import { useRouter, useRoute } from "vue-router"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const schema = zod.object({
  reason: zod.string()
    .min(3, { message: "This is required." }),
  commentsLocked: zod.boolean()
})

const initialValues = {
  commentsLocked: false
}

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

const Router = useRouter()
const Route = useRoute()

const isOwner = inject<any>("isOwner") as Ref<boolean>
const isOrganizer = inject<any>("isOrganizer") as Ref<boolean>
const rsvpUsers = inject<any>("RSVPUsers") as Ref<any[]>
const status = inject<any>("status") as Ref<string>
const setStatus = inject<any>("setStatus") as (value: string) => void
const setCommentsLocked = inject<any>("setCommentsLocked") as (value: boolean) => void

const working = ref<boolean>(false)

const deleteError = ref<boolean>(false)
const deleteErrorCode = ref("general")
const deleteErrorMessage = ref("There was an error deleting your event, please try again.")
const deleteModalOpen = ref<boolean>(false)

const cancelError = ref<boolean>(false)
const cancelErrorCode = ref("general")
const cancelErrorMessage = ref("There was an error cancelling your event, please try again.")
const cancelModalOpen = ref<boolean>(false)

function toggleCancelModal() {
  cancelModalOpen.value = !cancelModalOpen.value
}

function toggleDeleteModal() {
  deleteModalOpen.value = !deleteModalOpen.value
}

const canDelete = computed(() => {
  if (isOwner.value && rsvpUsers.value.length === 1 && rsvpUsers.value[0]._id === id.value) {
    return true
  }
  return false
})

onBeforeMount(() => {
  if (!isOwner.value && !isOrganizer.value) {
    return Router.push({ name: "Event.Details", params: { slug: Route.params.event }})
  }
})

async function deleteEvent() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${Route.params.event}`
    await API().delete(path)
    return Router.push({ name: "Events.Discover" })
  } catch (err) {
    console.error(err)
    deleteError.value = true
    working.value = false
  }
}

async function cancelEvent(values: any) {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${Route.params.event}/cancel`
    await API().post(path, { reason: values.reason, commentsLocked: values.commentsLocked })
    setStatus("cancelled")
    setCommentsLocked(values.commentsLocked)
  } catch (err) {
    console.error(err)
    cancelError.value = true
  } finally {
    working.value = false
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Event Details</h2>
      <p>Editing Event Details has been temporarily disabled while we fix a couple bugs, it will be back very soon!</p>
    </section>
    <section class="space-y-6" v-if="status === 'cancelled'">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Event Cancelled</h2>
      <p>This event has been cancelled and has no further actions available.</p>
    </section>
    <section class="space-y-6" v-if="canDelete && status !== 'cancelled' && isOwner">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Delete Event</h2>
      <p>Permanently delete this event. This option is only available if you are the only organizer and no one has RSVP'd or indicated interest.</p>
      <button @click="toggleDeleteModal" class="bg-red-500 font-bold text-white px-4 py-2 rounded-md">Delete Event</button>
      <TransitionRoot appear :show="deleteModalOpen" as="template">
        <Dialog as="div" @close="toggleDeleteModal" class="relative z-[100]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                  >
                    Delete Event
                  </DialogTitle>
                  <div class="flex flex-col mt-2 space-y-4">
                    <p class="text-sm">Permanently delete this event. This action is irreversible.</p>
                    <div class="flex justify-end gap-4">
                      <button type="button" @click="toggleDeleteModal" class="py-2 text-sm px-4 border border-transparent dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                      <button type="submit" @click="deleteEvent" class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md text-white bg-red-500 focus:outline-indigo-950" :disabled="working">{{ working ? 'Deleting...' : 'Delete Event' }}</button>
                    </div>
                    <div v-if="deleteError">
                      <div class="text-red-500 text-sm font-semibold mt-3">
                        <p>{{ deleteErrorMessage }} <small>({{ deleteErrorCode }})</small></p>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </section>
    <section class="space-y-6" v-if="!canDelete && status !== 'cancelled' && isOwner">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Cancel Event</h2>
      <p>Cancel this event.</p>
      <button @click="toggleCancelModal" class="bg-red-500 text-white px-4 py-2 rounded-md">Cancel Event</button>
      <span v-if="cancelError" class="text-red-500">Error canceling event.</span>
      <TransitionRoot appear :show="cancelModalOpen" as="template">
        <Dialog as="div" @close="toggleCancelModal" class="relative z-[100]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                  >
                    Cancel Event
                  </DialogTitle>
                  <div class="flex flex-col mt-2 space-y-4">
                    <p class="text-sm">Cancel this event. Users who have RSVP'd or have indicated interest will be notified.</p>
                    <Form :schema="schema" @on-submit="cancelEvent" :initial-values="initialValues" class="space-y-6">
                      <div>
                        <Label field="reason" />
                        <Field name="reason" type="textarea" alt />
                      </div>
                      <div>
                        <Checkbox name="commentsLocked">
                          Lock comments for this event
                        </Checkbox>
                      </div>
                      <div class="flex justify-end gap-4">
                        <button type="button" @click="toggleCancelModal" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                        <button type="submit" class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md text-black bg-gold-700 dark:bg-gold-500 focus:outline-indigo-950" :disabled="working">{{ working ? 'Cancelling...' : 'Cancel Event' }}</button>
                      </div>
                      <div v-if="cancelError">
                        <div class="text-red-500 text-sm font-semibold mt-3">
                          <p>{{ cancelErrorMessage }} <small>({{ cancelErrorCode }})</small></p>
                        </div>
                      </div>
                    </Form>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </section>
  </main>
</template>
