<script setup lang="ts">
import { ref, onBeforeMount, inject } from "vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { useRoute } from "vue-router"
import API from "@/api/api"

const Route = useRoute()
const User = useUserStore()

const slug = Route.params.slug as string

const setTitle = inject("setTitle") as (title: string) => void
const initialLoadComplete = ref(false)
const announcement = ref<any>(null)

onBeforeMount(async () => {
  setTitle("Announcements")
  await getAnnouncement()
  initialLoadComplete.value = true
  document.title = `${announcement.value.title} - Announcements - Submit`
  // Add the announcement to the user's list of announcements
  User.addAnnouncementToList(announcement.value._id)
})

async function getAnnouncement() {
  try {
    const response = await API().get(`/announcements/${slug}`)
    announcement.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <article v-if="initialLoadComplete">
    <header class="mb-6">
      <h1 class="text-2xl font-display font-semibold">{{ announcement.title }}</h1>
      <div class="text-xs">
        <time :title="D(announcement.createdAt).format('LLLL')">{{ D(announcement.createdAt).fromNow() }}</time>
      </div>
    </header>
    <div class="break-modern CommonBody" v-html="announcement.html"></div>
  </article>
  <main v-else>Loading...</main>
</template>
