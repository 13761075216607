<script setup lang="ts">
import Editor from "@/components/announcements/Editor.vue"
import { useRoute } from "vue-router"
import { ref, onMounted } from "vue"
import API from "@/api/api"

const Route = useRoute()

const slug = Route.params.slug

const initialLoadComplete = ref(false)
const announcement = ref<any>({})

onMounted(async () => {
  await getAnnouncement()
  initialLoadComplete.value = true
})

async function getAnnouncement() {
  try {
    const response = await API().get(`/announcements/${slug}`)
    announcement.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <Editor :announcement="announcement" v-if="initialLoadComplete" />
  <p v-else>Loading...</p>
</template>
