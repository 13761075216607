<script setup lang="ts">
import RadioGroup from "@/components/input/RadioGroup.vue"
import { ref, computed, onMounted, reactive } from "vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const UserStore = useUserStore()
const { roles } = storeToRefs(UserStore)

const schema = zod.object({
  name: zod.string()
    .min(3, { message: "This is required." }),
  restrictions: zod.string()
    .optional(),
  summary: zod.string()
    .min(5, { message: "This is required." } )
    .max(256, { message: "Limit is 256 characters." }),
  desc: zod.string()
    .min(5, { message: "This is required." } ),
  type: zod.string()
    .min(1, { message: "This is required." } )
})

const stage = ref(1)
const adminSkip = ref(false)
const initialLoadComplete = ref(false)
const reservations = ref<any[]>([])
const selectedReservation = ref("")
const reservation = ref<any>(null)
const working = ref(false)
const error = ref(false)
const errorCode = ref("general")
const errorMessage = ref("There was an error creating your group, please try again.")
const debug = ref(false)
const type = ref("")
const initialValues = reactive({
  name: "",
  summary: "",
  desc: "",
  type: "",
  restrictions: ""
})

const newGroupName = ref("")
const newGroupSlug = ref("")
const newGroupShort = ref("")

const approvedReservations = computed(() => {
  return reservations.value.filter((r) => r.accepted)
})

function setReservation() {
  reservation.value = reservations.value.find((r) => r._id === selectedReservation.value)
  initialValues.name = reservation.value.name
  initialValues.desc = `<p>${reservation.value.description}</p>`
  initialValues.type = reservation.value.type
  type.value = reservation.value.type
  stage.value = 2
}

const coreRadioOptions = [
  {
    value: "open",
    label: "Open",
    description: "Anyone can see the group and its content, anyone can join the group, group membership list is viewable by anyone."
  },
  {
    value: "closed",
    label: "Closed",
    description: "Anyone can see the group and its content, joining the group requires moderator approval, group membership list is viewable by anyone."
  },
  {
    value: "private",
    label: "Private",
    description: "Anyone can see the group exists, but not its content, joining the group requires moderator approval, group membership list is only viewable by its members."
  }
]

const radioOptions = computed(() => {
  if (roles.value.includes("admin")) {
    return [
      ...coreRadioOptions,
      {
        value: "official",
        label: "Official",
        description: "An official Submit group, moderated and managed by Submit's moderation team."
      }
    ]
  } else {
    return coreRadioOptions
  }
})

onMounted(async () => {
  await getReservations()
  initialLoadComplete.value = true
})

function skipReservation() {
  stage.value = 2
  adminSkip.value = true
}

async function getReservations() {
  let path = `/groups/reservations`

  try {
    const response = await API().get(path)
    reservations.value = response.data.data
  } catch (err) {
    error.value = true
  }
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let groupData: any = {
    name: values.name,
    restrictions: values.restrictions,
    summary: values.summary,
    desc: values.desc,
    type: values.type,
  }

  if (!adminSkip.value) {
    groupData.reservation = reservation.value._id
  }

  try {
    const response = await API().post(`/groups`, groupData)
    newGroupSlug.value = response.data.data.slug
    newGroupShort.value = response.data.data.short
    newGroupName.value = values.name

    stage.value = 3
    working.value = false
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      errorMessage.value = "There was an error creating your group, please try again."
      errorCode.value = "NEC"
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <section class="space-y-6">
    <header>
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Create a Group</h2>
    </header>
    <section v-if="initialLoadComplete && stage === 1" class="space-y-6">
      <p>Creating a group currently requires an accepted group <a href="/reservations/groups" class="underline hover:decoration-2 hover:decoration-gold-700 dark:hover:decoration-gold-500 ">reservation</a>.</p>
      <p v-if="reservations.length === 0">You have no reservations, yet.</p>
      <p v-if="approvedReservations.length > 0">You have {{ approvedReservations.length }} accepted reservations.</p>
      <p v-if="reservations.length > 0 && approvedReservations.length === 0">You have no accepted reservations, yet.</p>
      <div v-if="approvedReservations.length > 0">
        <label class="block text-sm font-bold mb-1">Choose a Reservation</label>
        <select v-model="selectedReservation" class="w-full text-sm rounded-md bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50">
          <option v-for="reservation in approvedReservations" :key="reservation._id" :value="reservation._id">{{ reservation.name }}</option>
        </select>
      </div>
      <div class="flex gap-4">
        <button v-if="approvedReservations.length > 0" type="button" class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md text-black bg-gold-700 dark:bg-gold-500 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30" @click="setReservation">Next</button>
        <button class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30" @click="skipReservation" v-if="roles.includes('admin')">Create Group (Admin)</button>
      </div>
    </section>
    <section v-if="initialLoadComplete && stage === 2" class="space-y-6">
      <Form @on-submit="handleSubmit" :initial-values="initialValues" :schema="schema" v-slot="{fields, errors}" class="space-y-6">
        <div>
          <Label field="name">Group Name</Label>
          <Field name="name" help-text="Group names are permanent, make sure it's what you want and is spelt correctly!" />
        </div>
        <div>
          <Label field="restrictions">Group Restricted to</Label>
          <Field name="restrictions" help-text="Leave this blank if it's open to anyone/everyone." />
        </div>
        <div>
          <Label field="summary" >Summary</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed in the public group listing. Limited to 256 characters, doesn't support formatting. Visible to all Submit users.</p>
          <Field name="summary" type="textarea" rows="4" />
        </div>
        <div>
          <Label field="desc">Description</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed in the About section of the Group, visible to all Submit users.</p>
          <Editor name="desc"/>
        </div>
        <div>
          <Label field="type">
            Group Type
          </Label>
          <RadioGroup name="type" :options="radioOptions" />
        </div>
        <div>
          <button type="submit" class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md text-black bg-gold-700 dark:bg-gold-500 focus:outline-indigo-950" :disabled="working">{{ working ? 'Creating...' : 'Create Group' }}</button>
          <div v-if="error" class="text-red-500 text-sm font-semibold mt-3">
            <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
          </div>
        </div>
        <section v-if="debug">
          <pre class="text-sm">
{{ fields }}
          </pre>
          <pre class="text-sm">
{{ errors }}
          </pre>
        </section>
      </Form>
    </section>
    <section v-if="initialLoadComplete && stage === 3" class="space-y-6">
      <p>{{ newGroupName }} was created!</p>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <router-link :to="`/groups/${newGroupSlug}`" class="dark:bg-submit-900 rounded-md bg-white p-4 text-center">
          <h2>Visit your Group</h2>
        </router-link>
        <router-link :to="`/groups/${newGroupSlug}/settings/group`" class="dark:bg-submit-900 rounded-md bg-white p-4 text-center">
          <h2>Adjust your Group's settings</h2>
        </router-link>
      </div>
    </section>
    <section v-if="!initialLoadComplete" class="space-y-6">Loading...</section>
  </section>
</template>
