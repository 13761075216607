<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import RelationshipsNav from "@/components/core/Nav.vue"
import { storeToRefs } from "pinia"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)

const navItems = [
  {
    name: "Overview",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>`,
    to: "/relationships",
    category: "relationships",
    subsections: []
  },
  {
    name: "Types",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/></svg>`,
    to: "/relationships/types",
    category: "types",
    subsections: []
  }
]
</script>

<template>
  <main v-if="!loading">
    <PageHeader>Relationships</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page Groups">
        <aside>
          <RelationshipsNav :nav-items="navItems" :slice="1" />
        </aside>
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
    </div>
  </main>
</template>
