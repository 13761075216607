<script setup lang="ts">
import { ref, computed, reactive, inject, onBeforeMount, onUnmounted, type Ref } from "vue"
import ReactionList from "@/components/reactions/List.vue"
import Comments from "@/components/comments/Comments.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from "@headlessui/vue"

const Platform = usePlatformStore()
const User = useUserStore()

const Route = useRoute()
const Router = useRouter()

const Group = inject("Group") as Ref<any>
const slug = inject("slug") as Ref<string>
const short = Route.params.short as string
const { id, roles, username } = storeToRefs(User)

const initialLoadComplete = ref(false)
const post = ref<any>(null)

const modalOpen = ref(false)
const modalType = ref("")

const reason = ref("")
const working = ref(false)
const deleteButtonLabel = ref("Delete Post")
const lockButtonLabel = ref("Lock Post")
const error = ref(false)

const note = ref("")
const notes = ref<any[]>([])
const showNotes = ref(false)

function toggleModal(type: string) {
  modalType.value = type
  modalOpen.value = !modalOpen.value
}

const content = reactive({
  contentAuthor: "",
  contentId: "",
  contentType: "",
  contentAudience: ""
})

onBeforeMount(async () => {
  await getPost()
  initialLoadComplete.value = true
  document.title = `${post.value.title} - ${Group.value.name} - Submit`
  if (post.value.type === "personal") {
    Platform.setCategoryOverride("personals")
  } else {
    Platform.setCategoryOverride("discussions")
  }
  content.contentAuthor = post.value.user._id
  content.contentId = post.value._id
  content.contentType = "post"
  content.contentAudience = "public"
  notes.value = post.value.notes || []
})

onUnmounted(() => {
  Platform.removeCategoryOverride()
})

async function getPost() {
  try {
    const response = await API().get(`/groups/g/${slug.value}/posts/${short}`)
    post.value = response.data.data.post
  } catch (err) {
    console.error(err)
  }
}

const typeLabel = computed(() => {
  if (!post.value) return "Loading..."
  switch (post.value.type) {
    case "text":
      return "Text Post"
    case "image":
      return "Media Post"
    case "poll":
      return "Poll Post"
    case "personal":
      return "Personals Post"
    default:
      return "Unknown Post"
  }
})

async function lockPost() {
  if (working.value) return
  working.value = true
  lockButtonLabel.value = "Locking..."
  const path = `/groups/g/${Group.value.slug}/posts/${post.value.short}/lock`
  try {
    await API().post(path, { reason: reason.value })
    post.value.commentsLocked = true
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    lockButtonLabel.value = "Error"
    setTimeout(() => {
      error.value = false
      lockButtonLabel.value = "Lock Post"
    }, 3000)
    return
  }
}

async function deletePost() {
  if (working.value) return
  working.value = true
  deleteButtonLabel.value = "Deleting..."
  const path = `/groups/g/${Group.value.slug}/posts/${post.value.short}`
  try {
    if (post.value.isAuthor) {
      await API().delete(path)
    } else {
      await API().delete(path, { data: {reason: reason.value} })
    }
    Router.push(`/groups/${Group.value.slug}`)
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    deleteButtonLabel.value = "Error"
    setTimeout(() => {
      error.value = false
      deleteButtonLabel.value = "Delete Post"
    }, 3000)
    return
  }
}

async function saveNote() {
  if (working.value) return
  working.value = true
  const path = `/groups/g/${Group.value.slug}/posts/${post.value.short}/notes`
  try {
    await API().post(path, { note: note.value })
    notes.value.push({
      user: id.value,
      username: username.value,
      note: note.value,
      createdAt: new Date()
    })

    note.value = ""
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
    return
  }
}

async function removeNote(noteId: string) {
  if (working.value) return
  working.value = true
  const path = `/groups/g/${Group.value.slug}/posts/${post.value.short}/notes/${noteId}`
  try {
    await API().delete(path)
    notes.value = notes.value.filter((note) => note._id !== noteId)
    working.value = false
  } catch (err) {
    console.error(err)
    return
  }
}

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: post.value.user._id,
    contentId: post.value._id,
    contentType: "group:post",
    group: Group.value._id,
    url: `/groups/${Group.value.slug}/${post.value.short}`
  })
}
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <article v-if="initialLoadComplete && post">
      <header class="flex items-center gap-4 mb-2">
        <a :href="`/${post.user.username}`" class="flex flex-none items-center justify-center bg-white dark:bg-submit-900 rounded-full bg-cover w-12 h-12" :style="post.user.pfp ? `background-image: url('${post.user.pfp}')` : ''">
          <span v-if="!post.user.pfp" class="font-semibold text-black dark:text-gray-400 dark:hover:text-white">{{ post.user.firstLetter }}</span>
        </a>
        <div>
          <h2 class="font-display font-semibold text-lg">
            <a :href="`/${post.user.username}`">{{ post.user.username }}</a>
          </h2>
          <div class="text-xs text-gray-400">
            <time :title="D(post.createdAt).format('LLLL')">{{ D(post.createdAt).fromNow() }}</time>
            &nbsp;&middot;&nbsp;
            <span>{{ typeLabel }}</span>
          </div>
        </div>
      </header>
      <main class="p-4 bg-white rounded-md dark:bg-submit-900 space-y-4">
        <h2 class="bg-gold-500/30 inline-block px-4 p-0.5 rounded-xl font-semibold">{{ post.title }}</h2>
        <div v-html="post.html" id="post" class="CommonBody"></div>
        <div v-if="post.tags && post.tags.length > 0">
          <ul class="inline text-sm">
            <li class="inline font-bold">Tags: </li>
            <li v-for="(tag, index) in post.tags" class="inline">
              {{tag}}<span v-if="index < post.tags.length - 1">, </span>
            </li>
          </ul>
        </div>
      </main>
      <footer class="flex justify-between items-center mt-1 px-4">
        <div v-if="post.type !== 'personal'">
          <ReactV2 :self="post.isAuthor" :reacted="post.hasReacted ? post.hasReacted : false" :reactions="post.reactionsCount" :reaction-data="post.reactionData ? post.reactionData : undefined" :content="content" />
        </div>
        <ul class="flex gap-x-4 items-center">
          <li class="items-center flex" v-if="!post.isAuthor">
            <svg tabindex="0" @click="openReportDialog" class="flex-none w-4 h-4 dark:fill-gray-400 cursor-pointer hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
          </li>
          <li class="items-center flex relative" v-if="Group.isModerator || Group.isOwner || roles.includes('admin') || post.isAuthor">
            <Menu as="template">
              <MenuButton>
                <svg class="flex-none w-4 h-4 dark:fill-gray-400 hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 128 512">
                  <title>Post Menu</title>
                  <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/>
                </svg>
              </MenuButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-6 right-0 w-32 rounded-md shadow-lg bg-neutral-125 dark:bg-submit-900 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
                  <div class="p-1">
                    <MenuItem v-slot="{ active }" v-if="Group.isModerator">
                      <router-link
                      :to="`/groups/${Group.slug}/moderation/members/${post.user._id}`"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                      ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z"/></svg>
                        Profile
                      </router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="post.isAuthor">
                      <router-link
                      :to="`/groups/${Group.slug}/${post.short}/edit`"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                      ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                        Edit
                      </router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="(Group.isModerator || roles.includes('admin')) && (!post.commentsLocked)">
                      <button
                      type="button"
                      @click="toggleModal('lock')"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                      ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
                        Lock
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="post.isAuthor || Group.isModerator || roles.includes('admin')">
                      <button
                      type="button"
                      @click="toggleModal('delete')"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                      ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                        Delete
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
            <TransitionRoot appear :show="modalOpen" as="template">
              <Dialog as="div" @close="toggleModal('close')" class="relative">
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="fixed inset-0 bg-black bg-opacity-25 z-20" />
                </TransitionChild>
                <div class="fixed inset-0 overflow-y-auto z-20">
                  <div
                    class="flex min-h-full items-center justify-center p-4 text-center z-20"
                  >
                    <TransitionChild
                      as="template"
                      enter="duration-300 ease-out"
                      enter-from="opacity-0 scale-95"
                      enter-to="opacity-100 scale-100"
                      leave="duration-200 ease-in"
                      leave-from="opacity-100 scale-100"
                      leave-to="opacity-0 scale-95"
                    >
                      <DialogPanel
                        class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all z-20"
                      >
                        <DialogTitle
                          as="h3"
                          class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                        >
                          <template v-if="modalType === 'delete'">
                            Delete Post
                          </template>
                          <template v-if="modalType === 'lock'">
                            Lock Post
                          </template>
                        </DialogTitle>
                        <template v-if="modalType === 'delete'">
                          <div class="flex flex-col mt-2 space-y-4">
                            <p class="text-sm">Are you sure you want to delete this post? Deletion is permanent, the post and comments cannot be recovered.</p>
                            <div v-if="!post.isAuthor">
                              <label for="reason" class="text-sm block font-bold mb-1">Reason</label>
                              <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                              <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> Reason is shared with the author of the post.</p>
                            </div>
                            <div class="flex self-end">
                              <button
                                type="button"
                                @click="toggleModal('close')"
                                class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                :disabled="working"
                                @click="deletePost"
                              >
                                {{ deleteButtonLabel }}
                              </button>
                            </div>
                            <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                              <p>There was an error deleting this post, please try again.</p>
                            </div>
                          </div>
                        </template>
                        <template v-if="modalType === 'lock'">
                          <div class="flex flex-col mt-2 space-y-4">
                            <p class="text-sm">Are you sure you want to lock this post?</p>
                            <div>
                              <label for="reason" class="text-sm block font-bold mb-1">Reason</label>
                              <textarea v-model="reason" class="flex text-sm w-full p-2 flex-auto rounded-md border dark:border-transparent bg-white dark:bg-submit-600 dark:text-gray-300 focus:ring-0 focus:border-neutral-900" name="reason" rows="3" placeholder="Reason" />
                              <p class="text-xs mt-2 text-gray-500 dark:text-gray-400"><strong>Note:</strong> Reason is shared with the author of the post.</p>
                            </div>
                            <div class="flex self-end">
                              <button
                                type="button"
                                @click="toggleModal('close')"
                                class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                class="inline-flex justify-center rounded-md border border-transparent dark:bg-gold-500/90 bg-gold-700 px-4 py-2 text-sm font-medium text-black hover:bg-gold-700 dark:hover:bg-gold-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                :disabled="working"
                                @click="lockPost"
                              >
                                {{ lockButtonLabel }}
                              </button>
                            </div>
                            <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                              <p>There was an error locking this post, please try again.</p>
                            </div>
                          </div>
                        </template>
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </Dialog>
            </TransitionRoot>
          </li>
        </ul>
      </footer>
      <div class="mt-6" v-if="Group.isModerator">
        <div class="flex gap-x-2 items-center text-sm cursor-pointer select-none dark:text-gray-400 text-gray-500" @click="showNotes = !showNotes">
          Moderation Notes
          <svg
            :class="[
              showNotes ? 'rotate-0' : '',
              'fill-gray-500 h-3 rotate-180 transition-all'
            ]"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
          </svg>
        </div>
        <div v-if="showNotes">
          <div class="mt-2">
            <label class="block text-sm font-bold mb-1">Note</label>
            <textarea v-model="note" class="space-y-4 w-full sm:w-1/2 dark:bg-submit-950 border rounded-md border-gray-400 dark:border-gray-700" />
            <button @click="saveNote" class="block text-sm font-bold">Save</button>
          </div>
          <ul class="mt-6 text-sm space-y-4">
            <li v-if="notes.length > 0" v-for="note in notes" :key="note._id">
              <p>{{ note.note.length > 0 ? note.note : "Note content not found." }}</p>
              <span class="text-xs dark:text-gray-400 text-gray-500">from {{ note.username }} - <time :title="D(note.createdAt).format('LLLL')">{{ D(note.createdAt).fromNow() }}</time> (<span class="hover:underline cursor-pointer" @click="removeNote(note._id)">Remove</span>)</span>
            </li>
            <li class="text-gray-500 dark:text-gray-400" v-if="notes.length === 0">No notes found.</li>
          </ul>
        </div>
      </div>
      <div class="mt-6">
        <Comments v-if="post.type !== 'personal'" size="medium" type="post" :group="Group" :short="post.short" :content="post._id" :forAuthor="post.user._id" forAudience="public" :locked="post.commentsLocked ? post.commentsLocked : false" />
      </div>
    </article>
    <div v-if="!initialLoadComplete">Loading...</div>
  </div>
</template>
