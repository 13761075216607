<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { storeToRefs } from "pinia"
import { ref } from "vue"
import API from "@/api/api"
import * as zod from "zod"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)

const schema = zod.object({
  name: zod.string()
    .min(3, { message: "This is required." }),
  handle: zod.string()
    .min(3, { message: "This is required." }),
  type: zod.string()
    .min(3, { message: "This is required." } ),
  about: zod.string()
    .min(3, { message: "This is required." }),
  summary: zod.string()
    .min(3, { message: "This is required." }),
  website: zod.string().optional()
})

const working = ref(false)
const debug = ref(true)
const error = ref(false)
const errorCode = ref("NEC")
const errorMessage = ref("There was an error creating your group, please try again.")

async function createGroup(values: any) {
  if (working.value) return
  working.value = true

  const payload = {
    name: values.name,
    handle: values.handle,
    type: values.type,
    meta: {
      about: values.about,
      summary: values.summary
    },
    website: values.website
  }

  try {
    await API().post("/organizations", payload)
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
  }
}
</script>

<template>
  <main v-if="!loading">
    <PageHeader>Organizations</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-full space-y-6">
          <h2>Create Organization</h2>
          <Form :schema="schema" @on-submit="createGroup" v-slot="{ fields, errors }" class="space-y-3">
            <div>
              <Label field="name">Name</Label>
              <Field name="name" />
            </div>
            <div>
              <Label field="handle">Handle</Label>
              <Field name="handle" />
            </div>
            <div>
              <Label field="type">Type</Label>
              <Field name="type" type="select">
                <option value="creator">Content Creator</option>
                <option value="maker">Maker</option>
                <option value="socialnetwork">Social Network</option>
              </Field>
            </div>
            <div>
              <Label field="about">About</Label>
              <Editor name="about" />
            </div>
            <div>
              <Label field="summary">Summary</Label>
              <Field name="summary" type="textarea" rows="3" />
            </div>
            <div>
              <Label field="website">Website</Label>
              <Field name="website" />
            </div>
            <div>
              <button type="submit" class="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-bold rounded-md text-black bg-gold-700 dark:bg-gold-500 focus:outline-indigo-950" :disabled="working">{{ working ? 'Creating...' : 'Create Organization' }}</button>
              <div v-if="error" class="text-red-500 text-sm font-semibold mt-3">
                <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
              </div>
            </div>
            <section v-if="debug">
              <pre class="text-sm">
{{ fields }}
              </pre>
              <pre class="text-sm">
{{ errors }}
              </pre>
            </section>
          </Form>
        </div>
      </div>
    </div>
  </main>
</template>
