<script setup lang="ts">
import { inject, onBeforeMount, type Ref } from "vue"
import { useRouter, useRoute } from "vue-router"

const Router = useRouter()
const Route = useRoute()

const isOwner = inject<any>("isOwner") as Ref<boolean>
const isOrganizer = inject<any>("isOrganizer") as Ref<boolean>

onBeforeMount(() => {
  if (!isOwner.value && !isOrganizer.value) {
    return Router.push({ name: "Event.Details", params: { slug: Route.params.event }})
  }
})
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Inbox</h2>
      <p>Event inboxes will be available soon.</p>
    </section>
  </main>
</template>
