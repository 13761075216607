<script setup lang="ts">
import setupCommons from "@/composables/Common"
import { inject, onBeforeMount } from "vue"

const { actionHandler } = setupCommons()

const setTitle = inject("setTitle") as (title: string) => void

onBeforeMount(() => {
  setTitle("Submit")
})
</script>

<template>
  <div class="content-start space-y-6">
    <p>Explore and learn more about Submit.</p>
    <ul @click="actionHandler" class="grid sm:grid-cols-2 gap-4 ItemList">
      <li data-to="/announcements" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/announcements`">Announcements</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">Major news and updates from Submit.</p>
      </li>
      <li data-to="/changelog" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/changelog`">Changelog</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">All the changes to Submit, in real-time.</p>
      </li>
      <li data-to="/roadmap" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/roadmap`">Roadmap</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">The future of Submit, and how we're getting there.</p>
      </li>
      <li data-to="/goals" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/goals`">Goals</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">The backer goals we have set for Submit.</p>
      </li>
      <li data-to="/council" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/council`">Council</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">Empowering the community to guide Submit.</p>
      </li>
      <li data-to="/about" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/about`">About</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">Learn more about Submit.</p>
      </li>
      <li data-to="/backer" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/backer`">Backer</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">Support Submit.</p>
      </li>
      <li data-to="/transparency" class="ListItem rounded-lg dark:bg-submit-900 bg-white p-4 cursor-pointer hover:dark:bg-submit-700">
        <router-link :to="`/transparency`">Transparency</router-link>
        <p class="text-sm dark:text-gray-400 text-gray-500">Monthly, quarterly and yearly transparency reports.</p>
      </li>
    </ul>
  </div>
</template>
