<script setup lang="ts">
import ListingEvent from "@/components/events/ListingEvent.vue"
import { inject, reactive, ref, onMounted } from "vue"
import setupCommons from "@/composables/Common"
import API from "@/api/api"

const { actionHandler } = setupCommons()

const profileData: any = inject("profileData")

const filter = reactive({
  type: "attending"
})

const initialLoadComplete = ref(false)
const events = ref<any[]>([])

onMounted(async () => {
  const { data } = await API().get(`/users/${profileData.value.username}/events`, { params: filter })
  events.value = data.data
  initialLoadComplete.value = true
})
</script>

<template>
  <section class="text-sm">
    <div v-if="!initialLoadComplete">
      Loading...
    </div>
    <div v-else-if="events.length === 0">
      {{ profileData.username }} is not yet attending any events.
    </div>
    <div v-else>
      <ul @click="actionHandler" class="space-y-3">
        <ListingEvent v-for="event in events" :key="event.id" :event="event" />
      </ul>
    </div>
  </section>
</template>
