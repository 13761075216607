<script setup lang="ts">
import Interests from "@/components/profile/Interests.vue"
import Details from "@/components/profile/Details.vue"
import { inject, onBeforeMount, type Ref } from "vue"
import Groups from "@/components/profile/Groups.vue"
import Links from "@/components/profile/Links.vue"
import Tools from "@/components/profile/Tools.vue"
import Marked from "@/components/misc/Marked.vue"

const profileData = inject("profileData") as any
const profileContext = inject("profileContext") as Ref<string>

onBeforeMount(() => {
  document.title = `${profileData.value.username} - Submit`
})
</script>

<template>
  <section class="px-2">
    <div class="container mx-auto Profile" v-if="profileContext === 'user'">
      <div class="row-start-2 row-end-2 md:row-start-auto md:row-end-auto md:col-span-9 lg:col-span-8 space-y-12">
        <Marked :markdown="profileData.meta.about" :selectable="true" />
        <Interests :profileData="profileData" />
        <Groups :profileData="profileData" />
        <Links :profileData="profileData" />
      </div>
      <Details :profileData="profileData" />
      <Tools />
    </div>
    <div class="container mx-auto Profile" v-if="profileContext === 'organization'">
      <div class="row-start-2 row-end-2 md:row-start-auto md:row-end-auto md:col-span-9 lg:col-span-8 space-y-12">
        <div v-html="profileData.meta.about"></div>
      </div>
    </div>
  </section>
</template>
