<script setup lang="ts">
import ModerationMembers from '@/components/groups/ModerationMembers.vue'
import { inject, onBeforeMount, type Ref } from "vue"
import { useRouter, useRoute } from "vue-router"

const Router = useRouter()
const Route = useRoute()

const { isModerator } = inject('isModerator') as { isModerator: Ref<boolean>, setIsModerator: (value: boolean) => void }

onBeforeMount(() => {
  if (!isModerator.value) {
    return Router.push({ name: "Group.Posts", params: { slug: Route.params.group }})
  }
})
</script>

<template>
  <ModerationMembers v-if="isModerator" />
  <div class="text-sm font-bold" v-else>
    <p>You do not have permission for this.</p>
  </div>
</template>
