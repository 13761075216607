<script setup lang="ts">
import { inject, onBeforeMount } from "vue"

const setTitle = inject("setTitle") as (title: string) => void

onBeforeMount(() => {
  setTitle("Roadmap")
})
</script>

<template>
  <div class="grid grid-cols-1 xl:grid-cols-3 xl:divide-x divide-gray-700 gap-x-4 gap-y-12">
    <section class="space-y-6">
      <header>
        <h2 class="text-xl font-display font-bold">Under Consideration</h2>
        <p class="text-[0.9375rem] leading-5">The top features and functionality that we're planning to build next. Your feedback and votes help determine how we prioritize these features.</p>
      </header>
      <p class="text-sm font-bold">Coming Soon</p>
    </section>
    <section class="xl:pl-4 space-y-6">
      <header>
        <h2 class="text-xl font-display font-bold">Under Development</h2>
        <p class="text-[0.9375rem] leading-5">Here's what we're building <strong>right now</strong>.</p>
      </header>
      <div class="space-y-2">
        <h3 class="text-sm font-bold">Events</h3>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Multi-Day Events</h4>
          <p class="text-sm">Support for creating multi-day events.</p>
        </div>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Recurring Events</h4>
          <p class="text-sm">Support for creating recurring events.</p>
        </div>
      </div>
      <div class="space-y-2">
        <h3 class="text-sm font-bold">Relationships</h3>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Multi-User Support</h4>
          <p class="text-sm">Allow for relationships to have more than 2 users.</p>
        </div>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Dynamics Support</h4>
          <p class="text-sm">Enable the ability for a user to add a dynamic as a relationship.</p>
        </div>
      </div>
      <div class="space-y-2">
        <h3 class="text-sm font-bold">Roadmap</h3>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Linear Integration</h4>
          <p class="text-sm mb-2">Support creating new tickets from feature/function listings created on Submit.</p>
        </div>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Groups Integration</h4>
          <p class="text-sm">Allow official groups to support feature/function backlogs boards and bug boards.</p>
        </div>
      </div>
    </section>
    <section class="xl:pl-4 space-y-6">
      <header>
        <h2 class="text-xl font-display font-bold">Recently Shipped</h2>
        <p class="text-[0.9375rem] leading-5">Major features or functionality we've released recently. Check the <router-link class="underline" to="/changelog">changelog</router-link> for more details.</p>
      </header>
      <div class="space-y-2">
        <h3 class="text-sm font-bold">Events</h3>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Events Core</h4>
          <p class="text-sm">Support for creating events, viewing event details, and RSVPing to events.</p>
        </div>
      </div>
      <div class="space-y-2">
        <div class="text-sm font-bold">Reactions</div>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Multiple Reaction Types</h4>
          <p class="text-sm">Enable the ability to react to content with multiple types of reactions.</p>
          <a class="text-sm dark:text-gold-500 text-gold-700 font-bold hover:underline" href="/groups/submit-development/8wjt7">Learn More</a>
        </div>
      </div>
      <div class="space-y-2">
        <div class="text-sm font-bold">Circles</div>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Circles Core</h4>
          <p class="text-sm">Enable the ability to create circles, filter your feed by circles, and share content to circles.</p>
          <a class="text-sm dark:text-gold-500 text-gold-700 font-bold hover:underline" href="/groups/submit-development/usfgk">Learn More</a>
        </div>
      </div>
      <div class="space-y-2">
        <div class="text-sm font-bold">Relationships</div>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Relationships Core</h4>
          <p class="text-sm">Enable the ability to configure & display relationships and send relationship requests.</p>
          <a class="text-sm dark:text-gold-500 text-gold-700 font-bold hover:underline" href="/groups/submit-development/vwint">Learn More</a>
        </div>
      </div>
      <div class="space-y-2">
        <div class="text-sm font-bold">Explore</div>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Explore Core</h4>
          <p class="text-sm">Enable the ability to explore random user content.</p>
          <a class="text-sm dark:text-gold-500 text-gold-700 font-bold hover:underline" href="/groups/submit-development/9wol9">Learn More</a>
        </div>
      </div>
      <div class="space-y-2">
        <h3 class="text-sm font-bold">Frontend</h3>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">The SPA</h4>
          <p class="text-sm mb-2">The entire Submit frontend re-built from the ground-up as a Single Page Application.</p>
          <a class="text-sm dark:text-gold-500 text-gold-700 font-bold hover:underline" href="/groups/submit-development/kxcdg">Learn More</a>
        </div>
      </div>
      <div class="space-y-2">
        <h3 class="text-sm font-bold">Writings</h3>
        <div class="border border-gray-700 p-4 rounded-md dark:bg-submit-925 bg-white">
          <h4 class="font-bold">Media Attachments</h4>
          <p class="text-sm">Enable the ability to share images in a writing.</p>
          <a class="text-sm dark:text-gold-500 text-gold-700 font-bold hover:underline" href="/groups/submit-development/tgr9k">Learn More</a>
        </div>
      </div>
    </section>
  </div>
</template>
