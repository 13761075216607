<script setup lang="ts">
import Comments from "@/components/comments/Comments.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import Follow from "@/components/actions/Follow.vue"
import Users from "@/components/events/Users.vue"
import { inject, computed, type Ref } from "vue"

const Event = inject<Ref<any>>("Event") as Ref<any>
const short = inject<Ref<string>>("short") as Ref<string>
const type = inject<Ref<string>>("type") as Ref<string>
const status = inject<Ref<string>>("status") as Ref<string>
const isOwner = inject<Ref<boolean>>("isOwner") as Ref<boolean>
const isAttending = inject<Ref<boolean>>("isAttending") as Ref<boolean>
const isConsidering = inject<Ref<boolean>>("isConsidering") as Ref<boolean>
const isRequested = inject<Ref<boolean>>("isRequested") as Ref<boolean>
const commentsLocked = inject<Ref<boolean>>("commentsLocked") as Ref<boolean>
const isRequestOnly = inject<Ref<boolean>>("isRequestOnly") as Ref<boolean>
const isRejected = inject<Ref<boolean>>("isRejected") as Ref<boolean>
const full = inject<Ref<boolean>>("full") as Ref<boolean>
const tags = inject<Ref<string[]>>("tags") as Ref<string[]>
const hideVenue = inject<Ref<boolean>>("hideVenue") as Ref<boolean>
const FormatTime = inject<Function>("FormatTime") as (date: string) => string
const RSVPtoEvent = inject<Function>("RSVPtoEvent") as () => void
const withdrawRSVP = inject<Function>("withdrawRSVP") as () => void
const considerEvent = inject<Function>("considerEvent") as () => void
const withdrawConsideration = inject<Function>("withdrawConsideration") as () => void
const withdrawRequest = inject<Function>("withdrawRequest") as () => void

const content = computed(() => {
  return {
    contentAuthor: Event.value.organizer._id,
    contentId: Event.value._id,
    contentType: "event",
    contentAudience: "public"
  }
})

const followData = computed(() => {
  return {
    _id: Event.value.organizer._id,
    username: Event.value.organizer.username || Event.value.organizer.handle,
    following: Event.value.organizer.following,
    followsMe: false,
    privacy: Event.value.organizer.privacy || {},
    private: Event.value.organizer.private || false,
    requested: Event.value.organizer.requested || false,
    self: Event.value.organizer.self || false
  }
})

function createFollowData(user: any) {
  return {
    _id: user._id,
    username: user.username || user.handle,
    following: user.following,
    followsMe: false,
    privacy: user.privacy || {},
    private: user.private || false,
    requested: user.requested || false,
    self: user.self || false
  }
}

const formatTimezone = (timezone: string) => {
  try {
    const date = new Date()
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'long',
    })
    const parts = formatter.formatToParts(date)
    const timeZonePart = parts.find(part => part.type === 'timeZoneName')
    const city = timezone.split('/').pop()?.replace(/_/g, ' ')
    return `${city} (${timeZonePart?.value || timezone})`
  } catch (error) {
    console.error('Error formatting timezone:', error)
    return timezone
  }
}

const virtualFormat = computed(() => {
  switch (Event.value.virtual.format) {
    case 'zoom':
      return 'Zoom'
    case 'jitsi':
      return 'Jitsi'
    case 'google-meet':
      return 'Google Meet'
    case 'discord':
      return 'Discord'
    case 'skype':
      return 'Skype'
    default:
      return Event.value.virtual.format
  }
})

const generateICS = () => {
  const formattedStart = Event.value.actualStartDateTime.replace(/[-:]/g, '').split('.')[0] + 'Z'
  const formattedEnd = Event.value.actualEndDateTime.replace(/[-:]/g, '').split('.')[0] + 'Z'
  const uid = `${Event.value._id}@events.submit.gg`
  const url = `https://submit.gg/e/${Event.value.short}`

  return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Events//Submit//EN
BEGIN:VEVENT
UID:${uid}
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z'}
SUMMARY:${Event.value.name}
DTSTART:${formattedStart}
DTEND:${formattedEnd}
URL:${url}
END:VEVENT
END:VCALENDAR`
}

const downloadICS = () => {
  const icsContent = generateICS()

  const blob = new Blob([icsContent], { type: 'text/calendar' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${Event.value.name}.ics`
  a.click()
  URL.revokeObjectURL(url)
}

const isValidUrl = (url: string): boolean => {
  // Add http:// if no protocol is specified
  const urlToCheck = url.match(/^https?:\/\//) ? url : `http://${url}`
  try {
    new URL(urlToCheck)
    return true
  } catch (error) {
    return false
  }
}

const getFormattedUrl = (url: string): string => {
  return url.match(/^https?:\/\//) ? url : `http://${url}`
}

const virtualUrl = computed(() => {
  if (Event.value.virtual && Event.value.virtual.url) {
    return Event.value.virtual.url.trim()
  }
  return ''
})

const isUrlValid = computed(() => isValidUrl(virtualUrl.value))
</script>

<template>
  <main class="grid gap-6">
    <section v-if="Event.coverPhotoURL">
      <img :src="Event.coverPhotoURL" :alt="Event.name" class="w-full h-64 object-cover rounded-md" />
    </section>
    <section>
      <h2 class="text-sm font-bold mb-1">About this event</h2>
      <div class="CommonBody" v-html="Event.description"></div>
      <div class="mt-3 flex items-center gap-2" v-if="Event.website">
        <svg class="dark:fill-gray-400 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5l0 39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9l0 39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7l0-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1L257 256c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
        <a target="_blank" class="underline decoration-2 dark:hover:decoration-gold-500 hover:decoration-gold-700" :href="Event.website">External Website</a>
      </div>
    </section>
    <section>
      <h2 class="text-sm font-bold mb-1">Organized by</h2>
      <div class="dark:bg-submit-600 bg-white p-4 rounded-md" v-if="Event.organizer && Event.organizers && Event.organizers.length === 0">
        <div class="flex justify-between items-center">
          <div>
            <div class="flex items-center gap-2">
              <router-link :to="`/${Event.organizer.username}`">
                <figure class="flex w-12 h-12 bg-submit-950 text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="Event.organizer.pfp ? `background-image: url('${Event.organizer.pfp}')` : ''">
                  {{ Event.organizer.pfp ? '' : Event.organizer.firstLetter }}
                </figure>
              </router-link>
              <router-link :to="`/${Event.organizer.username}`" class="flex flex-col">
                <h4 class="text-sm font-bold">{{ Event.organizer.username }}</h4>
                <span class="text-xs">{{ Event.organizer.meta }}</span>
              </router-link>
            </div>
          </div>
          <div>
            <Follow
              v-if="!Event.organizer.blocked"
              button-style="dark:bg-submit-950 bg-neutral-125 dark:text-gray-300 w-full py-2.5 px-2 xs:px-4 sm:px-8 rounded-[10px] text-xs font-bold dark:hover:text-gold-500 hover:text-gold-700 transition-all"
              :user="followData"
            />
          </div>
        </div>
      </div>
      <ul class="dark:bg-submit-600 bg-white p-4 rounded-md grid lg:grid-cols-2 gap-4" v-if="Event.organizers && Event.organizers.length > 0">
        <li class="flex justify-between items-center">
          <div>
            <div class="flex items-center gap-2">
              <router-link :to="`/${Event.organizer.username}`">
                <figure class="flex w-12 h-12 bg-submit-950 text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="Event.organizer.pfp ? `background-image: url('${Event.organizer.pfp}')` : ''">
                  {{ Event.organizer.pfp ? '' : Event.organizer.firstLetter }}
                </figure>
              </router-link>
              <router-link :to="`/${Event.organizer.username}`" class="flex flex-col">
                <h4 class="text-sm font-bold">{{ Event.organizer.username }}</h4>
                <span class="text-xs">{{ Event.organizer.meta }}</span>
              </router-link>
            </div>
          </div>
          <div>
            <Follow
              v-if="!Event.organizer.blocked"
              button-style="dark:bg-submit-950 bg-neutral-125 dark:text-gray-300 w-full py-2.5 px-2 xs:px-4 sm:px-8 rounded-[10px] text-xs font-bold dark:hover:text-gold-500 hover:text-gold-700 transition-all"
              :user="followData"
            />
          </div>
        </li>
        <li class="flex justify-between items-center" v-for="(organizer, idx) in Event.organizers" :key="idx">
          <div>
            <div class="flex items-center gap-2">
              <router-link :to="`/${organizer.username}`">
                <figure class="flex w-12 h-12 bg-submit-950 text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="organizer.pfp ? `background-image: url('${organizer.pfp}')` : ''">
                  {{ organizer.pfp ? '' : organizer.firstLetter }}
                </figure>
              </router-link>
              <router-link :to="`/${organizer.username}`" class="flex flex-col">
                <h4 class="text-sm font-bold">{{ organizer.username }}</h4>
                <span class="text-xs">{{ organizer.meta }}</span>
              </router-link>
            </div>
          </div>
          <div>
            <Follow
              v-if="!organizer.blocked"
              button-style="dark:bg-submit-950 bg-neutral-125 dark:text-gray-300 w-full py-2.5 px-2 xs:px-4 sm:px-8 rounded-[10px] text-xs font-bold dark:hover:text-gold-500 hover:text-gold-700 transition-all"
              :user="createFollowData(organizer)"
            />
          </div>
        </li>
      </ul>
    </section>
    <section class="space-y-3">
      <h2 class="text-sm font-bold mb-1">Date &amp; Time</h2>
      <p v-if="!Event.multiDay">{{ FormatTime(Event.actualStartDateTime) }} - {{ FormatTime(Event.actualEndDateTime) }}</p>
      <p v-if="!Event.multiDay && Event.late" class="text-xs text-gray-500 dark:text-gray-400">This event goes late and ends after midnight in its original timezone!</p>
      <ul v-if="Event.multiDay" class="space-y-2">
        <li v-for="(day, idx) in Event.days" :key="idx">
          <h3 class="text-sm font-bold">Day {{ idx + 1 }}</h3>
          <p>{{ FormatTime(day.actualStartDateTime) }} - {{ FormatTime(day.actualEndDateTime) }}</p>
          <p v-if="day.late" class="text-xs text-gray-500 dark:text-gray-400">This day goes late and ends after midnight in its original timezone!</p>
        </li>
      </ul>
      <p class="text-xs text-gray-500 dark:text-gray-400"><strong>Event Timezone:</strong> {{ formatTimezone(Event.timezone) }}</p>
      <button type="button" @click="downloadICS" class="text-xs">Add to Calendar</button>
    </section>
    <section>
      <h2 class="text-sm font-bold mb-1">Cost</h2>
      <div class="CommonBody" v-html="Event.cost"></div>
    </section>
    <section v-if="Event.dressCode && Event.dressCode !== undefined && (Event.dressCode.length > 0 && Event.dressCode !== '<p></p>')">
      <h2 class="text-sm font-bold mb-1">Dress Code</h2>
      <div class="CommonBody" v-html="Event.dressCode"></div>
    </section>
    <section v-if="Event.location && (type === 'virtual' || (type === 'in-person' && hideVenue && !isAttending))">
      <h2 class="text-sm font-bold mb-1">Location</h2>
      <ul class="flex">
        <li class="inline" v-for="(location, idx) in Event.location.display" :key="idx">
          <router-link class="hover:underline" :to="`/l${location.path}`">{{ location.name }}</router-link>
          <span v-if="idx < Event.location.display.length - 1">,&thinsp;</span>
        </li>
      </ul>
    </section>
    <section>
      <h2 class="text-sm font-bold mb-1">{{ type === 'in-person' ? 'Venue' : 'Virtual Details' }}</h2>
      <div v-if="hideVenue && !isAttending">
        <p v-if="type === 'in-person'">Venue details are only visible to attendees.</p>
        <p v-if="type === 'virtual'">Virtual details are only visible to attendees.</p>
      </div>
      <div class="grid gap-3" v-if="!hideVenue && type === 'in-person' || (hideVenue && isAttending && type === 'in-person')">
        <div v-if="Event.venue">
          <div>{{ Event.venue.name }}</div>
          <div>{{ Event.venue.address_1 }}</div>
          <div v-if="Event.venue.address_2">{{ Event.venue.address_2 }}</div>
          <div>{{ Event.location.display[0].name }}, {{ Event.location.display[1] ? Event.location.display[1].name : '' }} {{ Event.venue.zip }}</div>
        </div>
        <div class="text-sm" v-if="Event.venue && Event.venue.details">
          <h3 class="font-bold mb-1 text-gray-500 dark:text-gray-400">Additional Venue Details</h3>
          <div class="CommonBody" v-html="Event.venue.details"></div>
        </div>
        <div class="grid sm:grid-cols-2 gap-x-3" v-if="Event.venue">
          <div class="text-sm">
            <div :class="[Event.venue.wheelchairAccessible ? 'text-green-600 dark:text-green-400' : 'text-gray-300 dark:text-gray-700']">Wheelchair Accessible</div>
            <div :class="[Event.venue.parking ? 'text-green-600 dark:text-green-400' : 'text-gray-300 dark:text-gray-700']">Has Parking</div>
          </div>
          <div class="text-sm">
            <div :class="[Event.venue.publicTransit ? 'text-green-600 dark:text-green-400' : 'text-gray-300 dark:text-gray-700']">Public Transit Accessible</div>
            <div :class="[Event.venue.ageRestriction ? 'text-green-600 dark:text-green-400' : 'text-gray-300 dark:text-gray-700']">ID Required for Entry</div>
          </div>
        </div>
        <div v-if="!Event.venue">
          <p class="text-sm text-gray-500 dark:text-gray-400">Please refresh the page to see venue details.</p>
        </div>
      </div>
      <div class="grid gap-3" v-if="!hideVenue && type === 'virtual' || (hideVenue && isAttending && type === 'virtual')">
        <div v-if="Event.virtual">
          <h3 class="text-sm font-bold mb-1 text-gray-500 dark:text-gray-400">Virtual Meeting Format</h3>
          <div>{{ Event.virtual.format !== 'other' ? virtualFormat : Event.virtual.other ? Event.virtual.other : 'Other' }}</div>
        </div>
        <div v-if="Event.virtual">
          <h3 class="text-sm font-bold mb-1 text-gray-500 dark:text-gray-400">Virtual Meeting URL</h3>
          <div>
            <a
              v-if="isUrlValid"
              :href="getFormattedUrl(virtualUrl)"
              target="_blank"
              rel="noopener noreferrer"
              class="decoration-2 underline dark:hover:decoration-gold-500 hover:decoration-gold-700"
            >
              {{ virtualUrl }}
            </a>
            <span v-else>{{ virtualUrl }}</span>
          </div>
        </div>
        <div v-if="Event.virtual && Event.virtual.details">
          <h3 class="text-sm font-bold mb-1 text-gray-500 dark:text-gray-400">Virtual Meeting Details</h3>
          <div v-html="Event.virtual.details"></div>
        </div>
        <div v-if="!Event.virtual">
          <p class="text-sm text-gray-500 dark:text-gray-400">Please refresh the page to see virtual details.</p>
        </div>
      </div>
    </section>
    <section>
      <div class="grid grid-cols-2 gap-2 text-sm bg-white dark:bg-submit-600 p-4 rounded-md">
        <div class="col-span-2" v-if="status === 'cancelled'">
          <span>This event has been cancelled.</span>
        </div>
        <div class="col-span-2" v-if="isOwner && status === 'active'">
          <span>You're the organizer of this fine event.</span>
        </div>
        <div class="col-span-2" v-if="isRejected && status === 'active'">
          <span>You have no actions available for this event.</span>
        </div>
        <div class="col-span-2 flex gap-2" v-if="!isOwner && !isRejected && status === 'active'">
          <span v-if="!isAttending && !isRequested && !isConsidering && full">This event is full!</span>
          <button
            v-if="!isAttending && !isRequested && !full"
            :class="[
              'font-bold dark:text-gray-300 dark:bg-submit-950 bg-neutral-125 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm flex-1 sm:w-auto text-center transition-all'
            ]"
            @click="RSVPtoEvent"
          >
          {{ isRequestOnly ? 'Request to Attend' : 'RSVP' }}
          </button>
          <button
            v-if="!isAttending && !isConsidering && !isRequested && !isRequestOnly && !full"
            :class="[
              'font-bold dark:text-gray-300 dark:bg-submit-950 bg-neutral-125 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm flex-1 sm:w-auto text-center transition-all'
            ]"
            @click="considerEvent"
          >
            Considering
          </button>
          <button
            v-if="isAttending && !isConsidering && !isRequested"
            :class="[
              'font-bold dark:text-gray-300 dark:bg-submit-950 bg-neutral-125 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm flex-1 sm:w-auto text-center transition-all'
            ]"
            @click="withdrawRSVP"
          >
            Withdraw RSVP
          </button>
          <button
            v-if="isConsidering && !isAttending && !isRequested && !isRequestOnly"
            :class="[
              'font-bold dark:text-gray-300 dark:bg-submit-950 bg-neutral-125 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm flex-1 sm:w-auto text-center'
            ]"
            @click="withdrawConsideration"
          >
            Withdraw Consideration
          </button>
          <button
            v-if="isRequested && !isAttending && !isConsidering"
            :class="[
              'font-bold dark:text-gray-300 dark:bg-submit-950 bg-neutral-125 hover:text-gold-700 dark:hover:text-gold-500 py-2 px-4 rounded-md text-sm flex-1 sm:w-auto text-center transition-all'
            ]"
            @click="withdrawRequest"
          >
            Withdraw Request
          </button>
        </div>
      </div>
      <div class="mt-2">
        <ReactV2 :reacted="Event.hasReacted" :reactionData="Event.reactionData" :self="isOwner" :disabled="status === 'cancelled'" :reactions="Event.reactionsCount" :content="content" small :lm="false" />
      </div>
    </section>
    <section v-if="tags && tags.length > 0">
      <h2 class="text-sm font-bold mb-1">Tags</h2>
      <div>{{ tags.map((tag: any) => tag).join(", ") }}</div>
    </section>
    <Users type="rsvp" v-if="status === 'active' || status === 'completed'" />
    <Users type="considering" v-if="!isRequestOnly && (status === 'active' || status === 'completed')" />
    <section v-if="!isRejected">
      <Comments type="event" :event="Event._id" :short="short" :for-author="Event.organizer._id" for-audience="public" :content="Event._id" :locked="commentsLocked" />
    </section>
  </main>
</template>
