<script setup lang="ts">
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Profile Theme</h2>
        <p>Change your profile theme.</p>
        <p class="dark:text-gray-400 text-gray-500 text-sm font-bold">This feature is coming soon, check the <router-link class="underline" to="/roadmap">roadmap</router-link> and/or <router-link class="underline" to="/groups/submit-development">Submit Development</router-link> for updates and more details.</p>
      </div>
    </section>
  </main>
</template>
