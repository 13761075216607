<script setup lang="ts">
import { inject, onBeforeMount, onUnmounted, nextTick } from "vue"
import All from "@/components/interests/All.vue"

const setTitle = inject("setTitle") as (value: string) => void

onBeforeMount(async () => {
  await nextTick()
  setTitle("Interests")
})

onUnmounted(() => {
  setTitle("Kinks")
})
</script>

<template>
  <All filter="interest" />
</template>
