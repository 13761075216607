<script setup lang="ts">
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <section class="space-y-6">
      <p>The Community Council is evolving to better serve the Submit community. Work on the first draft of the Council's charter is well underway, with discussions happening now in the <router-link to="/groups/community-council" class="underline decoration-2 hover:decoration-gold-700 dark:hover:decoration-gold-500">Community Council group</router-link>. This charter will define the Council's structure, responsibilities, and operations, creating a clear framework for how it will function and grow.</p>
      <p>To stay informed and be part of the conversation, we encourage you to join the <router-link to="/groups/community-council" class="underline decoration-2 hover:decoration-gold-700 dark:hover:decoration-gold-500">Community Council group</router-link>. This is where updates are shared, feedback is gathered, and the groundwork is being laid for a Council that reflects the values and needs of our community.</p>
      <p class="font-semibold">Last Updated: November 17th, 2024.</p>
    </section>
  </div>
</template>
