<script setup lang="ts">
import SectionNav from "@/components/events/SectionNav.vue"
import PageHeader from "@/components/core/PageHeader.vue"
import SubLoader from "@/components/misc/SubLoader.vue"
import { usePlatformStore } from "@/stores/Platform"
import { storeToRefs } from "pinia"
import { ref, provide } from "vue"

const PlatformStore = usePlatformStore()
const { loading, subLoading } = storeToRefs(PlatformStore)

const title = ref("Events")
function setTitle(value: string) {
  title.value = value
}
provide("setTitle", setTitle)
</script>

<template>
  <main v-if="!loading">
    <PageHeader>{{ title }}</PageHeader>
    <SectionNav />
    <router-view v-if="!subLoading" />
    <SubLoader v-if="subLoading" />
  </main>
</template>
