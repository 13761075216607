<script setup lang="ts">
import ModerationMemberQueue from '@/components/groups/ModerationMemberQueue.vue'
import { inject, onBeforeMount, type Ref } from "vue"
import { useRouter, useRoute } from "vue-router"

const Router = useRouter()
const Route = useRoute()

const { isModerator } = inject('isModerator') as { isModerator: Ref<boolean>, setIsModerator: (value: boolean) => void }
const { hasMemberQueue } = inject('hasMemberQueue') as { hasMemberQueue: Ref<boolean>, setHasMemberQueue: (value: boolean) => void }

onBeforeMount(() => {
  if (!isModerator.value && !hasMemberQueue.value) {
    return Router.push({ name: "Group.Posts", params: { slug: Route.params.group }})
  }
})
</script>

<template>
  <ModerationMemberQueue v-if="isModerator && hasMemberQueue" />
  <div class="text-sm font-bold" v-else>
    <p v-if="!isModerator">You do not have permission for this.</p>
    <p v-else>Member queue is not enabled for this group.</p>
  </div>
</template>
