<script setup lang="ts">
</script>

<template>
  <div class="content-start">
    <div class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Overview</h2>
      <p>Relationships and relationshits.</p>
    </div>
  </div>
</template>
