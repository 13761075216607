<script setup lang="ts">
import Editor from "@/components/writing/WritingEditor.vue"
import { ref, onBeforeMount, onMounted, inject, type Ref } from "vue"
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const Router = useRouter()
const Route = useRoute()
const User = useUserStore()

const { id } = storeToRefs(User)

const profileData: any = inject("profileData")
const slug = Route.params.slug

const initialLoadComplete = ref(false)
const writing = ref<any>({})

onBeforeMount(async () => {
  if (profileData.value._id !== id.value) {
    Router.push(`/${profileData.value.username}/writing/${slug}`)
  }
})

onMounted(async () => {
  await getWriting()
  initialLoadComplete.value = true
})

async function getWriting() {
  try {
    const response = await API().get(`/users/${profileData.value._id}/writing/${slug}`)
    writing.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <Editor :writing="writing" v-if="initialLoadComplete" />
  <p v-else>Loading...</p>
</template>
