<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import SubmitNav from "@/components/core/Nav.vue"
import { storeToRefs } from "pinia"
import { ref, provide } from "vue"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)

const navItems = [
  {
    name: "Overview",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>`,
    to: "/platform",
    category: "platform",
    subsections: []
  },
  {
    name: "Announcements",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192v32 32 96 32 32h8.7c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32z"/><path class="fa-primary" d="M0 192c0-35.3 28.7-64 64-64H192V320v32V480c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32l0-128c-35.3 0-64-28.7-64-64V192zm512 48c0 27.9-13.4 51.6-32 60.4V179.6c18.6 8.8 32 32.5 32 60.4z"/></svg>`,
    to: "/announcements",
    category: "announcements",
    subsections: []
  },
  {
    name: "Changelog",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M80 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm32.4 97.2c28-12.4 47.6-40.5 47.6-73.2c0-44.2-35.8-80-80-80S0 35.8 0 80c0 32.8 19.7 61 48 73.3V358.7C19.7 371 0 399.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-32.8-19.7-61-48-73.3V272c26.7 20.1 60 32 96 32h86.7c12.3 28.3 40.5 48 73.3 48c44.2 0 80-35.8 80-80s-35.8-80-80-80c-32.8 0-61 19.7-73.3 48H208c-49.9 0-91-38.1-95.6-86.8zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48zM344 272a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"/></svg>`,
    to: "/changelog",
    category: "changelog",
    subsections: []
  },
  {
    name: "Roadmap",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M256 32H181.2c-27.1 0-51.3 17.1-60.3 42.6L3.1 407.2C1.1 413 0 419.2 0 425.4C0 455.5 24.5 480 54.6 480H256V416c0-17.7 14.3-32 32-32s32 14.3 32 32v64H521.4c30.2 0 54.6-24.5 54.6-54.6c0-6.2-1.1-12.4-3.1-18.2L455.1 74.6C446 49.1 421.9 32 394.8 32H320V96c0 17.7-14.3 32-32 32s-32-14.3-32-32V32zm64 192v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32s32 14.3 32 32z"/></svg>`,
    to: "/roadmap",
    category: "roadmap",
    subsections: []
  },
  {
    name: "Goals",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>`,
    to: "/goals",
    category: "goals",
    subsections: []
  }
]

const title = ref("Submit")
function setTitle(value: string) {
  title.value = value
}
provide("setTitle", setTitle)
</script>

<template>
  <main v-if="!loading">
    <PageHeader>{{ title }}</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page Groups">
        <aside>
          <SubmitNav :nav-items="navItems" :slice="1" />
        </aside>
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
    </div>
  </main>
</template>
