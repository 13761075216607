<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import MessagingNav from "@/components/core/Nav.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"

const PlatformStore = usePlatformStore()
const UserStore = useUserStore()
const { loading } = storeToRefs(PlatformStore)
const { roles } = storeToRefs(UserStore)

const navItems = [
  {
    name: "Inbox",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 336h81.2l20.9 41.9c6.8 13.6 20.6 22.1 35.8 22.1H326.1c15.1 0 29-8.6 35.8-22.1L382.8 336H464v80c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V336zm406.5-48H377.9c-15.1 0-29 8.6-35.8 22.1L321.2 352H190.8l-20.9-41.9c-6.8-13.6-20.6-22.1-35.8-22.1H57.5l49-195.9C108.2 85 114.6 80 122 80H390c7.3 0 13.7 5 15.5 12.1l49 195.9zM0 327.9V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V327.9c0-5.2-.6-10.4-1.9-15.5l-58-231.9C445 52 419.4 32 390 32H122C92.6 32 67 52 59.9 80.5L1.9 312.4C.6 317.4 0 322.6 0 327.9z"/></svg>`,
    to: "/messages",
    category: "messages",
    subsections: []
  },
  {
    name: "Sent",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M280 288c0 13.3-10.7 24-24 24s-24-10.7-24-24V81.9l-63 63c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 7c9.4-9.4 24.6-9.4 33.9 0L377 111c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-63-63V288zM149.1 405.5L130.3 368H48v96H464V368H381.7l-18.7 37.5C354.8 421.7 338.2 432 320 432H192c-18.2 0-34.8-10.3-42.9-26.5zm24.2-58.9L192 384H320l18.7-37.5c8.1-16.3 24.8-26.5 42.9-26.5H464c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h82.3c18.2 0 34.8 10.3 42.9 26.5z"/></svg>`,
    to: "/messages/sent",
    category: "sent",
    subsections: []
  },
  {
    name: "Filtered",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM256 128c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>`,
    to: "/messages/filtered",
    category: "filtered",
    subsections: []
  },
  {
    name: "Archives",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 80v48H464V80H48zM32 32H480c17.7 0 32 14.3 32 32v80c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V64C0 46.3 14.3 32 32 32zM160 248c0-13.3 10.7-24 24-24H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24zM32 416V208H80V416c0 8.8 7.2 16 16 16H416c8.8 0 16-7.2 16-16V208h48V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64z"/></svg>`,
    to: "/messages/archives",
    category: "archives",
    subsections: []
  },
  {
    name: "Notices",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-13.3 0-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>`,
    to: "/messages/notices",
    category: "notices",
    subsections: []
  }
]
</script>

<template>
  <main v-if="!loading">
    <PageHeader>Messages</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page Messages">
        <aside>
          <MessagingNav :nav-items="navItems" :slice="1" />
        </aside>
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
    </div>
  </main>
</template>
