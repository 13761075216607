<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"

const Route = useRoute()
const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)
</script>

<template>
  <main v-if="!loading">
    <PageHeader>Explore</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-full">
          <div class="dark:bg-submit-900 p-4 rounded-md mb-3">
            <p class="text-sm"><strong>Note:</strong> 100 New Explore items are loaded every day. If you don't want to show up in explore, update your <router-link class="underline hover:decoration-2" to="/settings/privacy/settings">Privacy Settings</router-link>.</p>
          </div>
          <ul class="grid grid-cols-4 mb-3 gap-4">
            <li>
              <router-link :to="`/explore`" :class="`flex justify-center py-2 items-center gap-2 rounded-md ${ $route.path === '/explore' ? 'dark:bg-submit-900 bg-white dark:text-gold-500 text-gold-700' : 'hover:dark:bg-submit-900 hover:bg-white'}`">
                <svg :class="`h-4 ${ $route.path === '/explore' ? 'dark:fill-gold-500 fill-gold-700' : 'dark:fill-gray-200'}`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M256 0H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 128h96V384v32c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V384H512v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z"/></svg>
                <span class="hidden text-sm sm:inline">Media</span>
              </router-link>
            </li>
            <li>
              <router-link :to="`/explore/writings`" :class="`flex justify-center py-2 items-center gap-2 rounded-md ${ $route.path === '/explore/writings' ? 'dark:bg-submit-900 bg-white dark:text-gold-500' : 'hover:dark:bg-submit-900 hover:bg-white'}`">
                <svg :class="`h-4 ${ $route.path === '/explore/writings' ? 'dark:fill-gold-500' : 'dark:fill-gray-200'}`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"/></svg>
                <span class="hidden text-sm sm:inline">Writings</span>
              </router-link>
            </li>
            <li>
              <router-link :to="`/explore/new`" :class="`flex justify-center py-2 items-center gap-2 rounded-md ${ $route.path === '/explore/new' ? 'dark:bg-submit-900 bg-white dark:text-gold-500' : 'hover:dark:bg-submit-900 hover:bg-white'}`">
                <svg :class="`h-4 ${ $route.path === '/explore/new' ? 'dark:fill-gold-500' : 'dark:fill-gray-200'}`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5L26.3 277l8.1 3.7 .6 .3 88.3 40.8L164.1 410l.3 .6 3.7 8.1 7.9 17.1c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l7.9-17.1 3.7-8.1 .3-.6 40.8-88.3L346 281l.6-.3 8.1-3.7 17.1-7.9c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5l-17.1-7.9-8.1-3.7-.6-.3-88.3-40.8L217 99.1l-.3-.6L213 90.3l-7.9-17.1c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3l-7.9 17.1-3.7 8.1-.3 .6-40.8 88.3L35.1 228.1l-.6 .3-8.1 3.7L9.3 240zm83 14.5l51.2-23.6c10.4-4.8 18.7-13.1 23.5-23.5l23.6-51.2 23.6 51.2c4.8 10.4 13.1 18.7 23.5 23.5l51.2 23.6-51.2 23.6c-10.4 4.8-18.7 13.1-23.5 23.5l-23.6 51.2-23.6-51.2c-4.8-10.4-13.1-18.7-23.5-23.5L92.3 254.6zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"/></svg>
                <span class="hidden text-sm sm:inline">New Arrivals</span>
              </router-link>
            </li>
            <li>
              <router-link :to="`/explore/trending`" :class="`flex justify-center py-2 items-center gap-2 rounded-md ${ $route.path === '/explore/trending' ? 'dark:bg-submit-900 bg-white dark:text-gold-500' : 'hover:dark:bg-submit-900 hover:bg-white'}`">
                <svg :class="`h-4 ${ $route.path === '/explore/trending' ? 'dark:fill-gold-500' : 'dark:fill-gray-200'}`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M384 160c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32V288c0 17.7-14.3 32-32 32s-32-14.3-32-32V205.3L342.6 374.6c-12.5 12.5-32.8 12.5-45.3 0L192 269.3 54.6 406.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0L320 306.7 466.7 160H384z"/></svg>
                <span class="hidden text-sm sm:inline">Trending</span>
              </router-link>
            </li>
          </ul>
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </div>
      </div>
    </div>
  </main>
</template>
