<script setup lang="ts">
import List from "@/components/input/starterpacks/List.vue"
import TypeAhead from "@/components/input/TypeaheadV2.vue"
import PageHeader from "@/components/core/PageHeader.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { reactive, ref } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const userStore = useUserStore()
const { username, id, roles } = storeToRefs(userStore)
const Router = useRouter()

const schema = zod.object({
  name: zod.string().min(1, { message: "Please enter a name" }),
  description: zod.string().min(5, { message: "Please enter a description" }).max(512, { message: "Limited to 512 characters." }),
  interest: zod.object({
    name: zod.string().optional()
  }, { invalid_type_error: ""}).nullable(),
  users: zod.array(zod.object({
    _id: zod.string(),
    username: zod.string()
  })).min(roles.value.includes("admin") ? 1 : 2, { message: "Please select at least 2 users" }),
  groups: zod.array(zod.object({
    _id: zod.string(),
    name: zod.string()
  })).optional()
})

const initialValues = reactive({
  name: "",
  description: "",
  interest: null,
  users: [{
    _id: id.value,
    username: username.value
  }],
  groups: []
})

const debug = ref(false)
const working = ref(false)

const error = ref(false)
const errorMessage = ref("")
const errorArray = ref<any[]>([])
const errorCode = ref("")

async function handleSubmit(values: any) {
  try {
    const result = await savePack(values)
    const data = result.data
    console.log(data)
    working.value = false
    Router.push(`/starter-packs/${data.short}`)
  } catch (err: any) {
    error.value = true

    // Handle axios error with response
    if (err.response && err.response.data) {
      const { message, errors, code } = err.response.data
      errorMessage.value = message || 'An unknown error occurred'
      errorCode.value = code || 'UNKNOWN_ERROR'
      errorArray.value = errors || []
    }
    // Handle other types of errors
    else {
      errorMessage.value = err.message || 'An unexpected error occurred'
      errorCode.value = 'INTERNAL_ERROR'
      errorArray.value = []
    }
  }
}

async function savePack(values: any) {
  if (working.value) return
  working.value = true

  try {
    const path = `/starterpacks`
    let data: any = {
      name: values.name,
      description: values.description,
      users: values.users,
      groups: values.groups?.map((group: any) => group._id)
    }
    if (values.interest) {
      data.interest = values.interest._id
      data.interestType = values.interest.type
      data.interestName = values.interest.name
      data.interestSlug = values.interest.slug
    }
    const result = await API().post(path, data)
    return Promise.resolve(result.data)
  } catch (err) {
    working.value = false
    return Promise.reject(err)
  }
}
</script>

<template>
  <main>
    <PageHeader>Create Starter Pack</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-2">
          <Form :schema="schema" :initial-values="initialValues" @on-submit="handleSubmit" class="space-y-3" v-slot="{ fields, errors, tools }">
            <div>
              <Label field="name">Name</Label>
              <Field name="name" />
            </div>
            <div>
              <Label field="description">Description</Label>
              <Field name="description" type="textarea" rows="3" max-length="512" help-text="Limited to 512 characters." />
            </div>
            <div>
              <TypeAhead name="interest" type="interests" help-text="Optional. Limited to 1 kink or interest." />
            </div>
            <div class="grid md:grid-cols-2 gap-3">
              <div>
                <List name="users" type="users" />
              </div>
              <div>
                <List name="groups" type="groups" />
              </div>
            </div>
            <div class="flex justify-end gap-3">
              <button class="px-4 py-2 font-bold text-sm dark:bg-submit-500 bg-white dark:text-white rounded-lg" type="submit" :disabled="working">{{ working ? "Creating..." : "Create Pack" }}</button>
            </div>
            <div class="dark:bg-red-800 bg-red-200 rounded-lg p-4 space-y-3" v-if="error">
              <div>
                <strong>Error:</strong> {{ errorMessage }}{{ errorArray && errorArray.length > 0 ? ":" : "" }}
              </div>
              <ul class="list-disc list-inside text-sm" v-if="errorArray && errorArray.length > 0">
                <li v-for="errorElement in errorArray" :key="errorElement._id">
                  <router-link class="underline" :to="`/${errorElement.username}`">{{ errorElement.username }}</router-link>: {{ errorElement.reason }}
                </li>
              </ul>
              <div class="text-xs font-bold">
                {{ errorCode }}
              </div>
            </div>
            <div v-if="debug">
              <pre>
{{  fields }}
              </pre>
              <pre>
{{ errors }}
              </pre>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </main>
</template>
