<script setup lang="ts">
import SettingsGroup from '@/components/groups/SettingsGroup.vue'
import { inject, onBeforeMount, type Ref } from "vue"
import { useRouter, useRoute } from "vue-router"

const Router = useRouter()
const Route = useRoute()

const { isOwner } = inject('isOwner') as { isOwner: Ref<boolean>, setIsOwner: (value: boolean) => void }

onBeforeMount(() => {
  if (!isOwner.value) {
    return Router.push({ name: "Group.Posts", params: { slug: Route.params.group }})
  }
})
</script>

<template>
  <SettingsGroup v-if="isOwner" />
  <div class="text-sm font-bold" v-else>
    <p>You do not have permission for this.</p>
  </div>
</template>
