<script setup lang="ts">
import { useRoute } from "vue-router"

const route = useRoute()

const year = route.params.year
const quarter = route.params.quarter
</script>

<template>
  <main class="px-2">
    <article class="my-24 max-w-[672px] lg:max-w-[656px] w-full mx-auto flex flex-col flex-grow space-y-16">
      <header class="text-center space-y-3">
        <small class="text-gray-500 dark:text-gray-400">{{ year }}</small>
        <h1 class="font-display text-3xl sm:text-5xl font-bold dark:text-gold-500 text-gold-700 capitalize">{{ quarter }} Report</h1>
      </header>
      <section class="text-center">
        <p>The requested report does not exist or is not available.</p>
      </section>
    </article>
  </main>
</template>
