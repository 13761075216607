<script setup lang="ts">
import { ref, inject, onBeforeMount, onMounted, onBeforeUnmount, type Ref, type ComputedRef } from "vue"
import { useRouter } from "vue-router"
import API from "@/api/api"

const Router = useRouter()
const slug = inject("slug") as Ref<string>
const Group = inject("Group") as Ref<any>
const { memberCount } = inject("memberCount") as { memberCount: Ref<number>, setMemberCount: (memberCount: number) => void }
const canView = inject("canView") as ComputedRef<boolean>

const initialLoadComplete = ref(false)
const members = ref<any[]>([])
const working = ref(false)
const error = ref(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

onBeforeMount(() => {
  if (!canView.value) {
    Router.push(`/groups/${slug.value}`)
  }
})

onMounted(async () => {
  await getMembers()
  initialLoadComplete.value = true
  document.title = `Members - ${Group.value.name} - Submit`
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

onBeforeUnmount(() => {
  observer.disconnect()
})

async function getMembers(setWorking: boolean = true) {
  if (working.value) return
  if (setWorking) working.value = true

  let path = `/groups/g/${slug.value}/members`
  let params: any = {}

  if (pageInfo.value.endCursor) {
    params = {
      ...params,
      cursor: pageInfo.value.endCursor
    }
  }

  path = `${path}?${new URLSearchParams(params).toString()}`

  try {
    const response = await API().get(path)
    const data = response.data
    members.value.push(...data.data)
    pageInfo.value = data.pageInfo
    if (setWorking) working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getMembers()
      }
    }
  })
}, options)
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <header class="flex justify-between items-center text-sm font-bold col-span-2">
      {{ initialLoadComplete ? memberCount : 'Loading' }} Member{{ memberCount === 1 ? '' : 's' }}
    </header>
    <main class="mt-4" >
      <ul class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <li v-if="initialLoadComplete && members.length === 0" class="text-sm">No members found (this should be impossible).</li>
        <li v-if="!initialLoadComplete" class="text-sm">Loading...</li>
        <li v-if="initialLoadComplete && members.length > 0" class="flex items-center p-4 bg-white dark:bg-submit-900 rounded-md" v-for="member in members" :key="member._id">
          <router-link :to="`/${member.user.username}`">
            <figure class="flex flex-none w-12 h-12 bg-slate-300 dark:bg-[#707482] text-indigo-950 rounded-full mr-2 items-center text-2xl justify-center font-semibold bg-cover" :style="member.user.pfp ? `background-image: url('${member.user.pfp}')` : ''">
              {{ member.user.pfp ? '' : member.user.firstLetter }}
            </figure>
          </router-link>
          <div>
            <h2 class="font-display font-semibold">
              <router-link :to="`/${member.user.username}`">{{ member.user.username }}</router-link>
            </h2>
            <div class="text-sm">{{ member.user.meta }}</div>
          </div>
        </li>
      </ul>
      <div id="loadMore"></div>
    </main>
  </div>
</template>
