<script setup lang="ts">
import Listing from "@/components/events/Listing.vue"
import { reactive } from "vue"

const filter = reactive({
  type: "attending"
})
</script>

<template>
  <div class="px-2">
    <div class="container mx-auto EventList">
      <div class="space-y-3 text-sm font-semibold">
        <div class="space-y-3">
          <button @click="filter.type = 'attending'" class="block w-full px-4 py-2 rounded-md hover:dark:text-white" :class="filter.type === 'attending' ? 'bg-white dark:bg-submit-900 text-gold-700 dark:text-gold-500' : 'text-gray-700 dark:text-gray-400'">Attending</button>
          <button @click="filter.type = 'interested'" class="block w-full px-4 py-2 rounded-md hover:dark:text-white" :class="filter.type === 'interested' ? 'bg-white dark:bg-submit-900 text-gold-700 dark:text-gold-500' : 'text-gray-700 dark:text-gray-400'">Interested</button>
          <button @click="filter.type = 'created'" class="block w-full px-4 py-2 rounded-md hover:dark:text-white" :class="filter.type === 'created' ? 'bg-white dark:bg-submit-900 text-gold-700 dark:text-gold-500' : 'text-gray-700 dark:text-gray-400'">Created</button>
          <button @click="filter.type = 'past'" class="block w-full px-4 py-2 rounded-md hover:dark:text-white" :class="filter.type === 'past' ? 'bg-white dark:bg-submit-900 text-gold-700 dark:text-gold-500' : 'text-gray-700 dark:text-gray-400'">Past</button>
          <button @click="filter.type = 'cancelled'" class="block w-full px-4 py-2 rounded-md hover:dark:text-white" :class="filter.type === 'cancelled' ? 'bg-white dark:bg-submit-900 text-gold-700 dark:text-gold-500' : 'text-gray-700 dark:text-gray-400'">Cancelled</button>
        </div>
      </div>
      <Listing :filters="filter" type="yours" />
    </div>
  </div>
</template>
