<script setup lang="ts">
import { ref, onBeforeMount, reactive, inject, nextTick } from "vue"
import Comments from "@/components/comments/Comments.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { usePlatformStore } from "@/stores/Platform"
import React from "@/components/reactions/React.vue"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const Platform = usePlatformStore()
const User = useUserStore()

const { username } = storeToRefs(User)

const Router = useRouter()
const route = useRoute()
const working = ref(false)
const profileData: any = inject("profileData")
const initialLoadComplete = ref(false)
const writing = ref<any>(undefined)
const readingTime = ref(0)
const error = ref(false)
const content = reactive({
  contentAuthor: "",
  contentId: "",
  contentType: "",
  contentAudience: ""
})

async function getWriting() {
  try {
    const response = await API().get(`/users/${profileData.value._id}/writing/${route.params.slug}`)
    writing.value = response.data.data
    content.contentAuthor = writing.value.user
    content.contentId = writing.value._id
    content.contentType = "writing"
    content.contentAudience = writing.value.visibility
    initialLoadComplete.value = true
    await nextTick()
    readingTime.value = setReadingTime()
    document.title = `${writing.value.title} by ${profileData.value.username} - Submit`
  } catch (err: any) {
    if (err.response && err.response.status === 403) {
      return Router.push(`/${profileData.value.username}/writing`)
    } else {
      console.error(err)
    }
    error.value = true
    initialLoadComplete.value = true
  }
}

onBeforeMount(async () => {
  await getWriting()
})

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: writing.value.user,
    contentId: writing.value._id,
    contentType: "writing"
  })
}

function setReadingTime() {
  const text = document.getElementById("writing")!.innerText
  const wpm = 225
  const words = text.trim().split(/\s+/).length
  const time = Math.ceil(words / wpm)
  return time
}

async function deleteWriting() {
  if (!writing.value) return
  if (working.value) return
  working.value = true
  // confirm
  const confirm = window.confirm("Are you sure you want to delete this writing?")
  if (!confirm) return
  try {
    const response = await API().delete(`/writing/${writing.value._id}`)
    Router.push(`/${username.value}/writing`)
  } catch (err) {
    console.error(err)
    working.value = false
  }
}
</script>

<template>
  <div class="Writing" v-if="initialLoadComplete && writing">
    <header>
      <h1 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">{{ writing.title }}</h1>
      <ul class="text-sm flex gap-2">
        <li>
          Published <time :title="D(writing.createdAt).format('LLLL')">{{ D(writing.createdAt).fromNow() }}</time>
        </li>
        <li>·</li>
        <li><span id="minutes">{{ readingTime }}</span> min read</li>
      </ul>
    </header>
    <aside class="hidden lg:block"></aside>
    <div>
      <article
        id="writing"
        class="CommonBody"
        v-html="writing.html"
      >
      </article>
      <footer class="text-sm mt-4">
        <ul class="inline">
          <li class="inline font-bold">Tags: </li>
          <li v-for="(tag, i) in writing.tags" :key="tag" class="inline" v-if="writing.tags && writing.tags.length > 0">
            {{ tag }}{{ i < writing.tags.length - 1 ? ', ' : '' }}
          </li>
          <li class="inline" v-else>
            None
          </li>
        </ul>
        <ul class="mt-4">
          <li>
            <ReactV2 :self="writing.self" :reacted="writing.hasReacted ? writing.hasReacted : false" :reactions="writing.reactionsCount" :reaction-data="writing.reactionData ? writing.reactionData : undefined" :content="content" />
          </li>
        </ul>
      </footer>
    </div>
    <aside>
      <ul class="flex flex-wrap items-center gap-2 lg:block lg:gap-0 text-sm lg:space-y-2">
        <li v-if="writing.collection">
          <router-link class="flex gap-2 items-center group dark:hover:text-white" :to="`/${profileData.username}/writing/collections/${writing.collection.slug}`">
            <figure
              :class="[
                'flex flex-none w-3 h-3 justify-center items-center'
              ]"
            >
              <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
            </figure>
            {{ writing.collection.title }}
          </router-link>
        </li>
        <li v-if="writing.series">
          Series Tools
        </li>
        <li v-if="writing.self">
          <router-link class="flex gap-2 items-center group dark:hover:text-white" :to="`/${profileData.username}/writing/${writing.slug}/edit`">
            <figure
              :class="[
                'flex flex-none w-3 h-3 justify-center items-center'
              ]"
            >
              <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c10.4-3 20.1-8 28.6-14.5l.3 .2 .5-.8c1.4-1.1 2.7-2.2 4-3.3c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-2.5 2.5-4.9 5.2-7.1 8l-.8 .5 .2 .3c-6.5 8.5-11.4 18.2-14.5 28.6zM383 191L197.4 376.6l-49.6-12.4-12.4-49.6L321 129 383 191zM97 358.9l7.7 31c2.1 8.6 8.9 15.3 17.5 17.5l31 7.7-7.4 11.2c-2.6 1.4-5.3 2.6-8.1 3.4l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4c.8-2.8 2-5.6 3.4-8.1L97 358.9zM315.3 218.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96z"/></svg>
            </figure>
            Edit Writing
          </router-link>
        </li>
        <li v-if="writing.self">
          <button @click="deleteWriting" class="flex gap-2 items-center group dark:hover:text-white" :disabled="working">
            <figure
              :class="[
                'flex flex-none w-3 h-3 justify-center items-center'
              ]"
            >
            <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
            </figure>
            {{ working ? 'Deleting...' : 'Delete Writing'}}
          </button>
        </li>
        <li v-if="!writing.self">
          <button class="flex gap-2 items-center group dark:hover:text-white" @click="openReportDialog" type="button">
            <figure
              :class="[
                'flex flex-none w-3 h-3 justify-center items-center'
              ]"
            >
              <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
            </figure>
            Report
          </button>
        </li>
      </ul>
    </aside>
    <Comments type="writing" :forAuthor="writing.user" :forAudience="writing.visibility" :content="writing._id" :locked="writing.commentsLocked ? writing.commentsLocked : false" />
  </div>
  <div  v-else-if="!initialLoadComplete">
    Loading...
  </div>
  <div v-else-if="initialLoadComplete && error">
    There was an error loading this status update, please try again.
  </div>
</template>
