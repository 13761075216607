<script setup lang="ts">
import { useHead } from "@unhead/vue"

useHead({
  title: "About - Submit",
  meta: [
    { name: "description", content: "Learn about Submit, our mission, vision, values, and commitment to the greater good. Discover how we're building a platform that prioritizes privacy, safety, and inclusivity for the BDSM, fetish, and kink communities." },
    { name: "robots", content: "index,follow" },
  ]
})
</script>

<template>
  <main class="px-2">
    <section class="my-24 max-w-[672px] lg:max-w-[656px] w-full mx-auto flex flex-col flex-grow">
      <header class="text-center space-y-3 mb-16">
        <h1 class="font-display text-5xl font-bold dark:text-gold-500 text-gold-700">About</h1>
      </header>
      <article class="space-y-16">
        <section class="space-y-6">
          <p>At Submit, we believe in creating a space where privacy, respect, and inclusivity form the foundation of everything we do. As a community-driven platform, we are dedicated to fostering a safer and more welcoming future for the BDSM, fetish, and kink communities. Whether you're here to connect with others, share your passions, or explore new interests, Submit is designed to empower you with the tools and technology to take control of your experience.</p>
          <p>Our mission is to prioritize your safety, celebrate diversity, and provide a haven free from hate and discrimination. Guided by our values&mdash;privacy first, safety and consent, inclusivity, empowerment, and more&mdash;we strive to build a space that reflects the authenticity and trust our members bring to the community.</p>
          <p>At its core, Submit is about people: authentic connections, meaningful conversations, and collaboration with our members to continuously grow and improve. Together, we're building more than a platform; we're shaping a vibrant and inclusive community for all.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl">Our Mission</h2>
          <p>Our mission is to create a space that prioritizes privacy, respect, and freedom from hate, while fostering a safer, more inclusive future for the BDSM, fetish, and kink communities.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl">Our Vision</h2>
          <p>Our vision is to become the go-to social platform for the BDSM, fetish, and kink communities—one where authenticity, education, and empowerment thrive, and members can connect, share, and grow in a safe, welcoming, and vibrant environment.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl">Our Values</h2>
          <p>At Submit, our values define who we are and guide every decision we make. They reflect our commitment to building a space where everyone in the BDSM, fetish, and kink communities can feel safe, respected, and empowered. These principles are the foundation of our platform, shaping the way we interact with our members and fostering the kind of community we envision for the future. Below are the values that we uphold and strive to embody in everything we do:</p>
          <ol class="list-decimal list-inside space-y-2 px-4">
            <li><strong class="text-gold-700 dark:text-gold-500">Privacy First:</strong> We respect and protect the personal data of our members, ensuring they have control over their information and connections.</li>
            <li><strong class="text-gold-700 dark:text-gold-500">Safety and Consent:</strong> We prioritize the safety of our community, creating a space where interactions are rooted in trust and the principles of consent.</li>
            <li><strong class="text-gold-700 dark:text-gold-500">Inclusivity:</strong> We strive to create a space that welcomes all, celebrating diversity across identities, experiences, and interests.</li>
            <li><strong class="text-gold-700 dark:text-gold-500">Integrity and Accountability:</strong> We are committed to being transparent and accountable in our actions, fostering trust within our community.</li>
            <li><strong class="text-gold-700 dark:text-gold-500">Freedom from Hate:</strong> We actively stand against discrimination, harassment, and hate, fostering a supportive and accepting environment.</li>
            <li><strong class="text-gold-700 dark:text-gold-500">Empowerment:</strong> We provide the tools, technology, and resources our community needs to own and control their content and experience, fostering autonomy and self-expression.</li>
            <li><strong class="text-gold-700 dark:text-gold-500">Authenticity:</strong> We value genuine expression and authentic relationships, allowing members to engage in ways that feel true to themselves.</li>
            <li><strong class="text-gold-700 dark:text-gold-500">Community-Driven:</strong> We listen to and collaborate with our members, continuously improving Submit based on their needs and feedback.</li>
          </ol>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl">A Commitment to the Greater Good</h2>
          <p>Submit is in the process of becoming a Public Benefit Corporation (PBC), with the conversion set to be complete by February 2025. This transformation reflects our deep commitment to serving the BDSM, fetish, and kink communities in a way that aligns with our mission and values.</p>
          <p>As a PBC, we will prioritize our mission and community values alongside financial goals, ensuring that every decision we make supports the best interests of our members. This status reinforces our dedication to building a platform rooted in inclusivity, safety, and privacy while holding ourselves accountable to the community we serve.</p>
          <p>Becoming a Public Benefit Corporation means Submit isn't just a business—it's a mission-driven platform working toward a safer, more inclusive future for all.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl">Building Trust Together</h2>
          <p>At Submit, we are committed to fostering trust by being as open as possible with our community. Our transparency is rooted in sharing not just how we operate, but why&mdash;ensuring you're informed every step of the way.</p>
          <p>We maintain a live changelog to keep you updated on platform improvements as they happen. Beyond that, our Transparency Portal offers a deeper look, providing monthly reports on our processes, quarterly analytics, and annual reports on our mission as a Public Benefit Corporation.</p>
          <p>This commitment to transparency isn’t just about accountability&mdash;it's about inviting you to be an active participant in shaping Submit's future.</p>
          <div>
            <router-link to="/transparency" class="text-gold-700 dark:text-gold-500 underline">Transparency Portal</router-link>
          </div>
        </section>
      </article>
    </section>
  </main>
</template>
