<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import { storeToRefs } from "pinia"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)
</script>

<template>
  <main v-if="!loading">
    <PageHeader>Reporting</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-full">
          Stay tuned to our Roadmap, the Reporting Portal will be going live in the near future.
        </div>
      </div>
    </div>
  </main>
</template>
