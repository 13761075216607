<script setup lang="ts">
</script>

<template>
  <div class="px-2 w-full self-center justify-self-center">
    <main class="container mx-auto flex justify-center my-4">
      <section class="w-full md:w-4/5 lg:w-3/5">
        <h1 class="font-display font-bold text-gold-700 dark:text-gold-500 text-xl mb-2">Not Found (404)</h1>
        <div class="flex flex-col bg-white dark:bg-submit-800 p-6 rounded-md w-full space-y-6">
          <p>Sorry, but we couldn't find what you were looking for! This can happen for a few reasons:</p>
          <ul class="space-y-2 list-disc list-inside">
            <li>The URL entered contains a typo.</li>
            <li>Whatever you've requested does not exist.</li>
            <li>The server was hungry and ate the page you were looking for.</li>
            <li>We're punishing you in a new and exciting way.</li>
          </ul>
          <p>If you think you are receiving this page in error, please let us know: <router-link class="underline decoration-2 hover:decoration-gold-700 hover:dark:decoration-gold-500" to="/contact">Contact</router-link>.</p>
        </div>
      </section>
    </main>
  </div>
</template>
