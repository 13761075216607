<script setup lang="ts">
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router"
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import ProfileHeader from "@/components/profile/Header.vue"
import { onBeforeMount, ref, provide, computed } from "vue"
import ProfileNav from "@/components/profile/Nav.vue"
import { usePlatformStore } from "@/stores/Platform"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const PlatformStore = usePlatformStore()
const { loading, subLoading } = storeToRefs(PlatformStore)

const route = useRoute()
const router = useRouter()

const profileContext = ref<string>("user")
const profileData = ref<any>(null)
const noPermission = ref(false)
const noPermissionData = ref<any>(null)
const followerCount = ref(0)
const followingCount = ref(0)
const isFollowing = ref(false)
const followsMe = ref(false)

function handleFollow() {
  followerCount.value++
  isFollowing.value = true
  return
}

function handleUnfollow() {
  if (followerCount.value > 0) {
    followerCount.value--
  }
  return isFollowing.value = false
}

function handleAddFollower() {
  return followingCount.value++
}

function handleRemoveFollower() {
  if (followingCount.value > 0) {
    return followingCount.value--
  }
}

provide("profileData", profileData)
provide("profileContext", profileContext)
provide("followerCount", followerCount)
provide("followingCount", followingCount)
provide("isFollowing", isFollowing)
provide("followsMe", followsMe)
provide("handleFollow", handleFollow)
provide("handleUnfollow", handleUnfollow)
provide("handleAddFollower", handleAddFollower)
provide("handleRemoveFollower", handleRemoveFollower)

const currentRoute = computed(() => route.name as string)

const noNavRoute = computed(() => {
  return [
    "Profile.Media.Media",
    "Profile.Status",
  ].includes(currentRoute.value)
})

onBeforeMount(async () => {
  PlatformStore.setLoading(true)
  let requestedUser = route.params.username as string
  if (requestedUser.startsWith("@")) requestedUser = requestedUser.slice(1)

  try {
    const response = await API().get(`/users/${requestedUser}`)
    profileContext.value = response.data.context
    profileData.value = response.data.data
    document.title = `${profileContext.value === 'user' ? profileData.value.username : profileData.value.name} - Submit`
    followerCount.value = response.data.data.followerCount || 0
    followingCount.value = response.data.data.followingCount || 0
    isFollowing.value = response.data.data.following || false
    followsMe.value = response.data.data.followsMe || false
    PlatformStore.setLoading(false)
  } catch (err: any) {
    if (err.response && err.response.status === 403) {
      noPermission.value = true
      noPermissionData.value = err.response.data
      PlatformStore.setLoading(false)
    } else {
      return router.replace({ name: "NotFound.Page" })
    }
    console.error(err)
  }
})

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.username !== from.params.username) {
    PlatformStore.setLoading(true)
    noPermission.value = false
    let requestedUser = to.params.username as string
    if (requestedUser.startsWith("@")) requestedUser = requestedUser.slice(1)

    try {
      const response = await API().get(`/users/${requestedUser}`)
      profileContext.value = response.data.context
      profileData.value = response.data.data
      document.title = `${profileData.value.username} - Submit`
      followerCount.value = response.data.data.followerCount || 0
      followingCount.value = response.data.data.followingCount || 0
      PlatformStore.setLoading(false)
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        noPermission.value = true
      } else {
        return router.replace({ name: "NotFound.Page" })
      }
      console.error(err)
    }
  }
})
</script>

<template>
  <main v-if="!loading && !noPermission && profileContext === 'user'">
    <ProfileHeader :profileData="profileData" :profileContext="profileContext" v-if="!noPermission" />
    <ProfileNav :profileData="profileData" :profileContext="profileContext" v-if="!noPermission && !noNavRoute" />
    <router-view v-slot="{ Component }" v-if="!noPermission && !subLoading">
      <component :is="Component" />
    </router-view>
    <div class="container flex w-full mx-auto justify-center Profile" v-if="subLoading">
      <LoadIndicator />
    </div>
  </main>
  <main v-if="!loading && !noPermission && profileContext === 'organization'">
    <ProfileHeader :profileData="profileData" :profileContext="profileContext" v-if="!noPermission" />
    <ProfileNav :profileData="profileData" :profileContext="profileContext" v-if="!noPermission && !noNavRoute" />
    <router-view v-slot="{ Component }" v-if="!noPermission && !subLoading">
      <component :is="Component" />
    </router-view>
    <div class="container flex w-full mx-auto justify-center Profile" v-if="subLoading">
      <LoadIndicator />
    </div>
  </main>
  <main class="flex justify-center items-center" v-else-if="!loading && noPermission">
    <div class="max-w-md w-full bg-submit-900 p-4 rounded-md space-y-3">
      <h1 class="text-xl font-semibold">{{ noPermissionData.data.username }}</h1>
      <p>This profile is unavailable, here's why that could be:</p>
      <ul class="list-disc list-inside">
        <li>They've blocked you or you've blocked them.</li>
        <li>They've been placed on a timeout.</li>
        <li>They've deactivated their account.</li>
        <li>There's a problem with the platform.</li>
      </ul>
    </div>
  </main>
</template>
