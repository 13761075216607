<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRoute } from "vue-router"
import { useHead } from "@unhead/vue"
import API from "@/api/api"

const route = useRoute()

const year = ref(parseInt(route.params.year as string))
const month = ref(parseInt(route.params.month as string))

const initialLoadComplete = ref(false)
const report = ref<any>(null)
const error = ref<boolean>(false)

const monthName = computed(() => {
  return new Date(year.value, month.value, 0).toLocaleString('default', { month: 'long' })
})

onMounted(async () => {
  try {
    const path = `/transparency/monthly/${year.value}/${month.value}`
    const response = await API().get(path)
    report.value = response.data.data
    initialLoadComplete.value = true
  } catch (err: any) {
    error.value = true
    initialLoadComplete.value = true
  }
})

const formatNumber = (num: number) => {
  return new Intl.NumberFormat().format(num)
}

useHead({
  title: `${monthName.value} ${year.value} Report - Transparency - Submit`,
  meta: [
    { name: "description", content: `Submit's monthly transparency reports provide insights into platform accountability, including user reports, content and account removals, CSAM-related actions, law enforcement inquiries, and copyright requests. Stay informed about our commitment to safety and ethical standards.` },
    { name: "robots", content: "index,follow" },
  ]
})
</script>

<template>
  <main class="px-2">
    <article class="my-24 max-w-[672px] lg:max-w-[656px] w-full mx-auto flex flex-col flex-grow space-y-16">
      <header class="text-center space-y-3">
        <small class="text-gray-500 dark:text-gray-400">{{ year }}</small>
        <h1 class="font-display text-3xl sm:text-5xl font-bold dark:text-gold-500 text-gold-700 capitalize">{{ monthName }} Report</h1>
      </header>
      <div class="text-center" v-if="!initialLoadComplete && !error">
        Loading...
      </div>
      <div class="text-center" v-if="initialLoadComplete && error">
        <p>The requested report does not exist or is not available.</p>
      </div>
      <div class="space-y-16" v-if="initialLoadComplete && !error">
        <section class="space-y-6">
          <p>As part of our commitment to openness and accountability, Submit publishes monthly transparency reports to share insights into how we manage and maintain our community. These reports provide a clear picture of our efforts to uphold the platform’s safety, privacy, and inclusivity while addressing challenges with integrity.</p>
          <p>This month’s report includes detailed data on waitlist approvals, user reports of content or account violations, content and account removals, and measures taken to combat harmful or illegal activity, including suspected CSAM. Additionally, we provide insights into copyright requests, law enforcement inquiries, and our collaboration with the National Center for Missing & Exploited Children (NCMEC) through tipline and hash reports.</p>
          <p>By sharing this information, we aim to keep our community informed and engaged in shaping a safer, more inclusive future for Submit.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Waitlist</h2>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.waitlist.received) }}</h3>
              <span>Total Waitlist Requests</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.waitlist.approved) }}</h3>
              <span>Waitlist Approvals</span>
            </div>
          </figure>
          <p>The Submit waitlist is an essential tool for fostering a safer, more authentic community. By carefully managing our platform’s growth, we can focus on enhancing anti-bot and evasion detection technologies while scaling our infrastructure to ensure a smooth, secure experience for all members.</p>
          <p>Every waitlist request undergoes rigorous analysis to identify patterns related to spam, bots, and known bad actors. We also review requests to prevent individuals attempting to circumvent existing bans or timeouts from rejoining the community. This deliberate process has allowed us to build a stronger, more trustworthy platform, where safety and inclusivity are prioritized from the start.</p>
          <p>While the waitlist has been a cornerstone of our approach to community building, we anticipate phasing it out by Q1 of 2025. As we continue to strengthen our systems and scale our platform, Submit will remain committed to balancing growth with the safety and integrity of our community.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">User Reports</h2>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.userReports.content) }}</h3>
              <span>Total Reports on Content</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.userReports.accounts) }}</h3>
              <span>Total Reports on Accounts</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ report.userReports.average }} hours</h3>
              <span>Average time to resolve a report</span>
            </div>
          </figure>
          <p>Safety is a collective effort, and your reports are vital in helping us uphold the standards of our community. By flagging content or behavior that doesn't align with our Terms of Service or Community Guidelines, you contribute directly to making Submit a safer and more welcoming space for everyone.</p>
          <p>Our moderation team is dedicated to responding swiftly, with a goal of addressing every report within 24 hours. This commitment reflects our belief that timely action is essential to maintaining trust and fostering a positive environment. Your efforts and engagement ensure that Submit continues to be a space where authenticity, inclusivity, and safety thrive.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Content Removal</h2>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.created) }}</h3>
              <span>Total Content Posted</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.removed) }}</h3>
              <span>Total Content Removed for Violations</span>
            </div>
          </figure>
          <p>Ensuring a safe and respectful community requires both proactive measures and thorough review processes. In addition to user reports, Submit employs automated systems that scan every piece of content shared on our platform. These systems are designed to identify potential violations of our Terms of Service or Community Guidelines.</p>
          <p>When content is flagged by these systems, it is sent to our human moderation team for careful review. If the content is determined to be in violation, it is promptly removed. This combination of advanced technology and human oversight allows us to maintain a balance between efficiency and fairness, ensuring that our community remains a safe and welcoming space for all.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Account Removal</h2>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.accountsRemoved.guideline) }}</h3>
              <span>Accounts removed for TOS/Guideline violations</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.accountsRemoved.spam) }}</h3>
              <span>Accounts removed for spam/fraud</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.accountsRemoved.other) }}</h3>
              <span>Accounts removed for other reasons</span>
            </div>
          </figure>
          <p>Maintaining a safe and inclusive community means addressing behavior that violates our Terms of Service or Community Guidelines. While most violations are resolved through warnings or temporary timeouts, account removal is sometimes necessary as a last resort to protect the integrity and safety of Submit.</p>
          <p>Our moderation process carefully evaluates accounts flagged through user reports or automated systems. When repeated or severe violations occur, removal becomes essential to ensure our community remains a space free from harm and abuse.</p>
          <p>By enforcing these measures thoughtfully and transparently, we aim to uphold a supportive and welcoming environment for everyone.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Fighting CSAM</h2>
          <p>At Submit, we are unwavering in our commitment to combatting the creation and distribution of Child Sexual Abuse Material (CSAM). CSAM is defined as any image, video, or other content depicting sexually explicit conduct involving a person under the age of 18. Such material is not only illegal but also represents severe child abuse and exploitation.</p>
          <p>We have a zero-tolerance policy for CSAM on our platform. The creation, distribution, or facilitation of CSAM is strictly prohibited by our Terms of Service and Acceptable Use Policy. We actively invest in advanced technology and human review processes to detect, deter, and remove CSAM swiftly and effectively.</p>
          <p>Our efforts include proactive scanning of all uploaded content using industry-leading detection tools, such as hash-matching technology, and reporting identified materials to the National Center for Missing & Exploited Children (NCMEC). We also cooperate fully with law enforcement to support investigations and ensure accountability.</p>
          <p>By taking these actions, we aim to protect vulnerable individuals and uphold the safety and integrity of our community. Fighting CSAM is not just a responsibility—it is a foundational principle of our platform.</p>
          <section class="space-y-6">
            <h3 class="text-xl font-bold">Instances of Suspected CSAM</h3>
            <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
              <div>
                <h3 class="text-4xl font-bold">{{ formatNumber(report.csam.instances) }}</h3>
                <span>Total instances of suspected CSAM</span>
              </div>
            </figure>
            <p>Submit takes every instance of suspected Child Sexual Abuse Material (CSAM) with the utmost seriousness. When content is flagged as potentially involving CSAM, whether through automated systems, user reports, or external notifications, it is immediately reviewed by our trained moderation team. Any confirmed instances are swiftly removed from the platform, and appropriate actions are taken to ensure the material is reported to the National Center for Missing & Exploited Children (NCMEC).</p>
            <p>Our approach prioritizes speed and accuracy, leveraging advanced detection tools and maintaining close collaboration with relevant organizations and law enforcement. Each case is treated with the urgency it deserves, as protecting children from exploitation is a responsibility we hold paramount. These efforts are part of our broader mission to create a safe and accountable platform for our community.</p>
          </section>
          <section class="space-y-6">
            <h3 class="text-xl font-bold">Tipline Reports to NCMEC</h3>
            <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
              <div>
                <h3 class="text-4xl font-bold">{{ formatNumber(report.csam.tips) }}</h3>
                <span>Total tipline reports to NCMEC</span>
              </div>
            </figure>
            <p>When we send a report to the National Center for Missing & Exploited Children (NCMEC), it may include identifying information about the user, the suspected minor victim, and other relevant details to assist in protecting and safeguarding minors. In some cases, multiple reports may be submitted for a single user or piece of content, particularly if the content is flagged by different sources or linked to multiple accounts. In accordance with NCMEC guidelines and standard operating procedures, we ensure that each incident is reported once, even if it is associated with multiple accounts. These reports are then forwarded to law enforcement agencies globally, helping to coordinate efforts in the fight against child sexual exploitation.</p>
          </section>
          <section class="space-y-6">
            <h3 class="text-xl font-bold">Hash Reports to NCMEC</h3>
            <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
              <div>
                <h3 class="text-4xl font-bold">{{ formatNumber(report.csam.hashes) }}</h3>
                <span>Total hash reports to NCMEC</span>
              </div>
            </figure>
            <p>To help combat the spread of CSAM, Submit employs advanced hashing technology to identify previously flagged content. As part of our safety and compliance efforts, all content shared on our platform is compared against several trusted hash lists, including those provided by third-party organizations such as Microsoft DNA, Thorn, the Internet Watch Foundation, CyberTip Canada, and NCMEC.</p>
            <p>When new CSAM is identified on Submit, we create a unique hash of the content and add it to our internal repository. If necessary, we report these hashes to NCMEC and other relevant organizations to support the identification and removal of CSAM across the internet. Additionally, we share these hash values with other platforms to enhance the collective fight against child sexual abuse material. Contributing to NCMEC’s and other industry-wide hash databases is an essential part of our commitment to safeguarding children and maintaining a secure online environment.</p>
          </section>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Law Enforcement</h2>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.leo) }}</h3>
              <span>Total requests from Law Enforcement</span>
            </div>
          </figure>
          <p>As a global platform, Submit monitors developments in the legal and regulatory areas which impact our community. We continue to make any necessary changes to our guidelines and procedures to ensure we comply with all applicable laws and regulations.</p>
          <p>When Submit receives a request for assistance from Law Enforcement or NGOs, we carefully review each request to make sure it satisfies laws of the relevant jurisdiction. Submit handles requests from government agencies in administrative and criminal cases, as well as requests related to civil law matters. In all cases our legal teams require the agencies to comply with formal legal processes such as obtaining a subpoena, production order, search warrant, or court order to compel disclosure of user information.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Copyright</h2>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.copyright) }}</h3>
              <span>Total copyright requests</span>
            </div>
          </figure>
          <p>Submit respects the intellectual property rights of third parties and prohibits the unauthorized use of images, videos, or other creations on our platform. We are committed to protecting the rights of content creators and ensuring that all materials shared on Submit comply with copyright laws.</p>
          <p>If you believe your copyrighted work has been used without permission, we encourage you to contact us with a detailed notice, and we will take appropriate action, including removing the content if necessary. We cooperate fully with copyright holders and relevant authorities to address any infringement concerns promptly and transparently. By fostering a culture of respect for intellectual property, we strive to maintain a safe and lawful environment for all members of our community.</p>
        </section>
      </div>
    </article>
  </main>
</template>
