<script setup lang="ts">
import Activity from "@/components/profile/Activity.vue"
import { inject, onBeforeMount } from "vue"

const profileData: any = inject("profileData")

onBeforeMount(() => {
  document.title = `${profileData.value.username}'s Activity - Submit`
})
</script>

<template>
  <section class="px-2">
    <Activity />
  </section>
</template>
