<script setup lang="ts">
import SettingsNotifications from '@/components/groups/SettingsNotifications.vue'
import { inject, onBeforeMount, type Ref } from "vue"
import { useRouter, useRoute } from "vue-router"

const Router = useRouter()
const Route = useRoute()

const { isMember } = inject('isMember') as { isMember: Ref<boolean>, setIsMember: (value: boolean) => void }

onBeforeMount(() => {
  if (!isMember.value) {
    return Router.push({ name: "Group.Posts", params: { slug: Route.params.group }})
  }
})
</script>

<template>
  <SettingsNotifications v-if="isMember" />
  <div class="text-sm font-bold" v-else>
    <p>You do not have permission for this.</p>
  </div>
</template>
