<script setup lang="ts">
import Collection from "@/components/writing/Collection.vue"
import { useRoute } from "vue-router"
import { ref, watchEffect } from "vue"

const Route = useRoute()

const collection = ref(Route.params.slug ? Route.params.slug as string : "default")

watchEffect(() => {
  if (Route.params.slug) {
    collection.value = Route.params.slug as string
  } else {
    collection.value = "default"
  }
})
</script>

<template>
  <Collection :collection="collection" />
</template>
