<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { ref, onMounted } from "vue"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue"

const schema = zod.object({
  name: zod.string()
    .min(1, { message: "Required." }),
  conjunction: zod.string()
    .min(1, { message: "Required." }),
  description: zod.string().optional(),
  slug: zod.string()
    .min(1, { message: "Required." }),
  type: zod.string()
    .min(1, { message: "Required." }),
  mono: zod.boolean(),
  solo: zod.boolean(),
  fun: zod.boolean(),
  poly: zod.boolean(),
  polyLimit: zod.number().optional(),
  stackable: zod.boolean(),
  stackLimit: zod.number().optional(),
  limit: zod.number()
    .min(1, { message: "Required." }),
})

const initialLoadComplete = ref(false)
const working = ref(false)
const relationshipTypes = ref<any>([])
const dynamicTypes = ref<any>([])
const addOpen = ref(false)
function toggleOpen() {
  addOpen.value = !addOpen.value
}

onMounted(() => {
  fetchRelationshipTypes()
  initialLoadComplete.value = true
})

async function fetchRelationshipTypes(initial: boolean = false) {
  if (working.value) return
  if (!initial) working.value = true

  try {
    const [ relationships, dynamics ] = await Promise.all([
      API().get("/relationships/types?type=romantic"),
      API().get("/relationships/types?type=dynamic")
    ])

    relationshipTypes.value = relationships.data.data
    dynamicTypes.value = dynamics.data.data

    if (!initial) working.value = false
  } catch (err) {
    console.error(err)
    if (!initial) working.value = false
  }
}
</script>

<template>
  <div class="content-start">
    <div class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Types</h2>
      <p>Manage the relationship types that users can leverage on the platform.</p>
    </div>
    <div class="grid grid-cols-2 mt-6 gap-3">
      <div class="col-span-2 flex justify-between">
        <div>
          <button @click="toggleOpen" type="button" class="flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group">
            Add Type
          </button>
        </div>
        <div>
          <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group" @click="fetchRelationshipTypes()">
            <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
            Refresh
          </button>
        </div>
        <TransitionRoot appear :show="addOpen" as="template">
          <Dialog as="div" class="relative z-[100]" @close="toggleOpen">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
              <div
                class="flex min-h-full items-center justify-center p-4 text-center"
              >
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                  <DialogPanel
                    class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <DialogTitle
                      as="h3"
                      class="text-xl font-medium mb-3"
                    >
                      Add Type
                    </DialogTitle>
                    <Form :schema="schema" class="grid grid-cols-2 gap-3">
                      <div class="col-span-2">
                        <Label field="name">Name</Label>
                        <Field name="name" :opignore="true" alt/>
                      </div>
                      <div>
                        <Label field="conjunction">Conjunction</Label>
                        <Field name="conjunction" type="select" alt>
                          <option value="of">of</option>
                          <option value="with">with</option>
                          <option value="to">to</option>
                          <option value="by">by</option>
                          <option value="none">None</option>
                        </Field>
                      </div>
                      <div>
                        <Label field="slug">Slug</Label>
                        <Field name="slug" alt/>
                      </div>
                      <div class="col-span-2">
                        <Label field="description">Description</Label>
                        <Field name="description" type="textarea" rows="2" alt/>
                      </div>
                      <div>
                        <Label field="type">Type</Label>
                        <Field name="type" type="select" alt>
                          <option value="relationship">Relationship</option>
                          <option value="dynamic">Dynamic</option>
                        </Field>
                      </div>
                      <div>
                        <Label field="limit">Limit</Label>
                        <Field name="limit" alt/>
                      </div>
                      <div>
                        <Checkbox name="mono" alt>Mono</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="solo" alt>Solo</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="fun" alt>Fun</Checkbox>
                      </div>
                      <div>
                        <Checkbox name="poly">Poly</Checkbox>
                      </div>
                      <div class="col-span-2">
                        <Checkbox name="stackable" alt>Stackable</Checkbox>
                      </div>
                      <div>
                        <Label field="polyLimit">Poly Limit</Label>
                        <Field name="polyLimit" alt/>
                      </div>
                      <div>
                        <Label field="stackLimit">Stack Limit</Label>
                        <Field name="stackLimit" alt/>
                      </div>
                    </Form>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
      <div>
        <h3 class="text-sm font-bold mb-3">Romantic ({{ relationshipTypes.length }})</h3>
        <ul>
          <li v-if="!initialLoadComplete || working">Loading...</li>
          <li v-if="initialLoadComplete && !working && relationshipTypes.length === 0">No romantic types found.</li>
          <li v-for="type in relationshipTypes" v-if="initialLoadComplete && !working && relationshipTypes.length > 0">
            {{ type.name }}
          </li>
        </ul>
      </div>
      <div>
        <h3 class="text-sm font-bold mb-3">Dynamic ({{ dynamicTypes.length }})</h3>
        <ul>
          <li v-if="!initialLoadComplete || working">Loading...</li>
          <li v-if="initialLoadComplete && !working && dynamicTypes.length === 0">No dynamic types found.</li>
          <li v-for="type in dynamicTypes" v-if="initialLoadComplete && !working && dynamicTypes.length > 0">
            {{ type.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
