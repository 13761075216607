<script setup lang="ts">
import { ref, computed, provide, onBeforeMount, onMounted } from "vue"
import EventHeader from "@/components/events/Header.vue"
import SubLoader from "@/components/misc/SubLoader.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useRoute, useRouter } from "vue-router"
import EventNav from "@/components/core/Nav.vue"
import { useUserStore } from "@/stores/User"
import { format, parseISO } from "date-fns"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const PlatformStore = usePlatformStore()
const UserStore = useUserStore()
const { loading, subLoading } = storeToRefs(PlatformStore)
const Route = useRoute()
const Router = useRouter()

onBeforeMount(async () => {
  PlatformStore.setLoading(true)
})

onMounted(async () => {
  await getEvent()
  eventId.value = Event.value._id
  status.value = Event.value.status
  short.value = Event.value.short
  type.value = Event.value.type
  tags.value = Event.value.tags || []
  hideVenue.value = Event.value.privacy.hideVenue || false
  recurringEvent.value = Event.value.recurringEvent || false
  isRequestOnly.value = Event.value.isRequestOnly || false
  isOwner.value = Event.value.isOwner
  isOrganizer.value = Event.value.isOrganizer
  isAttending.value = Event.value.isGoing
  isConsidering.value = Event.value.isInterested
  isRequested.value = Event.value.isRequested
  isRejected.value = Event.value.isRejected
  RSVPCount.value = Event.value.rsvpCount
  participantLimit.value = Event.value.participantLimit ? Number(Event.value.participantLimit) : 0
  full.value = Event.value.full ? Event.value.full : false
  RSVPUsers.value = []
  consideringCount.value = Event.value.interestedCount
  consideringUsers.value = []
  commentsLocked.value = Event.value.commentsLocked
  document.title = `${Event.value.name} - Events - Submit`
  PlatformStore.setLoading(false)
})

const eventFetchedAndSet = ref<boolean>(false)
const working = ref<boolean>(false)

const Event = ref<any>({})
provide("Event", Event)

const eventId = ref<string>("")
provide("eventId", eventId)

const status = ref<string>("")
provide("status", status)
const setStatus = (value: string) => {
  status.value = value
}
provide("setStatus", setStatus)

const short = ref<string>(Route.params.event as string)
provide("short", short)

const type = ref<string>("")
provide("type", type)

const tags = ref<string[]>([])
provide("tags", tags)

const hideVenue = ref<boolean>(false)
provide("hideVenue", hideVenue)

const isOwner = ref<boolean>(false)
provide("isOwner", isOwner)

const isOrganizer = ref<boolean>(false)
provide("isOrganizer", isOrganizer)

const RSVPCount = ref<number>(0)
provide("RSVPCount", RSVPCount)

const participantLimit = ref<number>(0)
provide("participantLimit", participantLimit)

const full = ref<boolean>(false)
provide("full", full)

const RSVPUsers = ref<any[]>([])
provide("RSVPUsers", RSVPUsers)

const commentsLocked = ref<boolean>(false)
provide("commentsLocked", commentsLocked)
const setCommentsLocked = (value: boolean) => {
  commentsLocked.value = value
}
provide("setCommentsLocked", setCommentsLocked)

const isRequestOnly = ref<boolean>(false)
provide("isRequestOnly", isRequestOnly)

const setRSVPUsers = (users: any[]) => {
  RSVPUsers.value = users
  RSVPUsersFetchedAndSet.value = true
}
provide("setRSVPUsers", setRSVPUsers)

const RSVPUsersFetchedAndSet = ref<boolean>(false)
provide("RSVPUsersFetchedAndSet", RSVPUsersFetchedAndSet)

const addRSVPUser = (user: any) => {
  RSVPUsers.value.push(user)
}
provide("addRSVPUser", addRSVPUser)

const removeRSVPUser = (user: string) => {
  RSVPUsers.value = RSVPUsers.value.filter(u => u._id !== user)
}
provide("removeRSVPUser", removeRSVPUser)

const consideringCount = ref<number>(0)
provide("consideringCount", consideringCount)

const setConsideringUsers = (users: any[]) => {
  consideringUsers.value = users
  ConsideringUsersFetchedAndSet.value = true
}
provide("setConsideringUsers", setConsideringUsers)

const consideringUsers = ref<any[]>([])
provide("consideringUsers", consideringUsers)

const ConsideringUsersFetchedAndSet = ref<boolean>(false)
provide("ConsideringUsersFetchedAndSet", ConsideringUsersFetchedAndSet)

const addConsideringUser = (user: any) => {
  consideringUsers.value.push(user)
}
provide("addConsideringUser", addConsideringUser)

const removeConsideringUser = (user: string) => {
  consideringUsers.value = consideringUsers.value.filter(u => u._id !== user)
}
provide("removeConsideringUser", removeConsideringUser)

const isAttending = ref<boolean>(false)
provide("isAttending", isAttending)
function updateIsAttending(value: boolean) {
  isAttending.value = value
}
provide("updateIsAttending", updateIsAttending)

const isConsidering = ref<boolean>(false)
provide("isConsidering", isConsidering)
function updateIsConsidering(value: boolean) {
  isConsidering.value = value
}
provide("updateIsConsidering", updateIsConsidering)

const isRequested = ref<boolean>(false)
provide("isRequested", isRequested)
function updateIsRequested(value: boolean) {
  isRequested.value = value
}
provide("updateIsRequested", updateIsRequested)

const isRejected = ref<boolean>(false)
provide("isRejected", isRejected)

const recurringEvent = ref<boolean>(false)
provide("recurringEvent", recurringEvent)

const categoryLabel = computed(() => {
  switch (Event.value.category) {
    case "submit-event":
      return "Submit Event"
    case "educational":
      return "Educational"
    case "social":
      return "Social"
    case "kink":
      return "Kink Party"
    case "bdsm":
      return "BDSM Party"
    case "sex":
      return "Sex Party"
    case "conference":
      return "Conference or Festival"
    default:
      return "Event"
  }
})
provide("categoryLabel", categoryLabel)

const eventType = computed(() => {
  switch (type.value) {
    case "in-person":
      return "In-Person"
    case "virtual":
    case "virtual-limited":
    case "virtual-global":
      return "Virtual"
  }
  return type.value
})
provide("eventType", eventType)

const FormatTime = (date: string) => format(parseISO(date), "MMM d, yyyy 'at' h:mm a")
provide("FormatTime", FormatTime)

async function getEvent() {
  try {
    const response = await API().get(`/events/${Route.params.event}`)
    Event.value = response.data.data
    eventFetchedAndSet.value = true
  } catch (err) {
    return Router.push("/notfound")
  }
}

const navItems = computed(() => {
  return [
    {
      name: "Details",
      icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>`,
      to: `/e/${Route.params.event}`,
      category: "details",
      subsections: []
    },
    {
      name: "Updates",
      icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M544 32c17.7 0 32 14.3 32 32l0 384c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-384c0-17.7 14.3-32 32-32zM64 190.3L480 64l0 384L348.9 408.2C338.2 449.5 300.7 480 256 480c-53 0-96-43-96-96c0-11 1.9-21.7 5.3-31.5L64 321.7C63.1 338.6 49.1 352 32 352c-17.7 0-32-14.3-32-32L0 192c0-17.7 14.3-32 32-32c17.1 0 31.1 13.4 32 30.3zm239 203.9l-91.6-27.8c-2.1 5.4-3.3 11.4-3.3 17.6c0 26.5 21.5 48 48 48c23 0 42.2-16.2 46.9-37.8z"/></svg>`,
      to: `/e/${Route.params.event}/updates`,
      category: "updates",
      subsections: []
    },
    ... (recurringEvent.value ? [
      {
        name: "Related Events",
        icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>`,
        to: `/e/${Route.params.event}/related`,
        category: "events",
        subsections: []
      }
    ] : []),
    ... (isOwner.value || isOrganizer.value ? [
      {
        name: "Management",
        icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>`,
        category: "management",
        subsections: [
          {
            name: "Users",
            subsection: "users",
            to: `/e/${Route.params.event}/management/users`
          },
          ... (Event.value.options.inbox ? [
            {
              name: "Inbox",
              subsection: "inbox",
              to: `/e/${Route.params.event}/management/inbox`
            }
          ] : []),
          ... (isRequestOnly.value ? [
            {
              name: "Requests",
              subsection: "requests",
              to: `/e/${Route.params.event}/management/requests`,
            }
          ] : []),
          {
            name: "Settings",
            subsection: "settings",
            to: `/e/${Route.params.event}/management/settings`
          }
        ]
      }
    ] : [])
  ]
})

async function RSVPtoEvent() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${short.value}/rsvp`
    const response = await API().post(path)
    const data = response.data
    if (data.message === "Requested") {
      isRequested.value = true
    } else if (data.message === "Attending") {
      if (isConsidering.value) {
        isConsidering.value = false
        removeConsideringUser(UserStore.id)
        consideringCount.value --
      }
      isAttending.value = true
      addRSVPUser({
        _id: UserStore.id,
        username: UserStore.username,
        firstLetter: UserStore.firstLetter,
        pfp: UserStore.pfp ? UserStore.pfp : false
      })
      RSVPCount.value ++
    }
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}
provide("RSVPtoEvent", RSVPtoEvent)

async function withdrawRSVP() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${short.value}/rsvp`
    await API().delete(path)
    isAttending.value = false
    RSVPCount.value --
    removeRSVPUser(UserStore.id)
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}
provide("withdrawRSVP", withdrawRSVP)

async function considerEvent() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${short.value}/interested`
    await API().post(path)
    isConsidering.value = true
    consideringCount.value ++
    addConsideringUser({
      _id: UserStore.id,
      username: UserStore.username,
      firstLetter: UserStore.firstLetter,
      pfp: UserStore.pfp ? UserStore.pfp : false
    })
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}
provide("considerEvent", considerEvent)

async function withdrawConsideration() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${short.value}/interested`
    await API().delete(path)
    isConsidering.value = false
    consideringCount.value --
    removeConsideringUser(UserStore.id)
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}
provide("withdrawConsideration", withdrawConsideration)

async function withdrawRequest() {
  if (working.value) return
  working.value = true

  try {
    const path = `/events/${short.value}/request`
    await API().delete(path)
    isRequested.value = false
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}
provide("withdrawRequest", withdrawRequest)
</script>

<template>
  <main v-if="!loading">
    <EventHeader />
    <div class="px-2">
      <div class="container mx-auto EventList">
        <aside>
          <EventNav :nav-items="navItems" :slice="3" v-if="eventFetchedAndSet" />
        </aside>
        <router-view v-if="!subLoading" />
        <SubLoader v-if="subLoading" />
      </div>
    </div>
  </main>
</template>
