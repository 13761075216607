<script setup lang="ts">
import { inject, onBeforeMount, onMounted, ref, type Ref } from "vue"
import RequestItem from "@/components/events/RequestItem.vue"
import { useRouter, useRoute } from "vue-router"
import API from "@/api/api"

const Router = useRouter()
const Route = useRoute()

const short = inject<Ref<string>>("short") as Ref<string>
const isOwner = inject<any>("isOwner") as Ref<boolean>
const isOrganizer = inject<any>("isOrganizer") as Ref<boolean>
const isRequestOnly = inject<Ref<boolean>>("isRequestOnly") as Ref<boolean>

const working = ref<boolean>(false)
const initialLoadComplete = ref<boolean>(false)
const error = ref<boolean>(false)
const requests = ref<any[]>([])

onBeforeMount(() => {
  if (!isOwner.value && !isOrganizer.value && !isRequestOnly.value) {
    return Router.push({ name: "Event.Details", params: { slug: Route.params.event }})
  }
})

onMounted(async () => {
  await getRequests()
  initialLoadComplete.value = true
})

async function getRequests() {
  if (working.value) return
  working.value = true

  try {
    const response = await API().get(`/events/${Route.params.event}/requests`)
    requests.value = response.data.data
    working.value = false
  } catch (err) {
    console.error(error)
    error.value = true
    working.value = false
  }
}

function handleRequest(id: string) {
  requests.value = requests.value.filter(r => r._id !== id)
}
</script>

<template>
  <main class="grid gap-4 content-start">
    <header class="flex justify-between items-center text-sm">
      <h2 class="font-bold mb-1">RSVP Requests</h2>
    </header>
    <ul class="grid gap-2">
      <RequestItem
        v-for="request in requests"
        :key="request._id"
        :request="request"
        v-if="initialLoadComplete && requests.length > 0"
        @handled="handleRequest"
      />
      <li v-if="initialLoadComplete && requests.length === 0">No requests, yet.</li>
      <li v-if="!initialLoadComplete">Loading...</li>
    </ul>
  </main>
</template>
