<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import CouncilNav from "@/components/core/Nav.vue"
import { storeToRefs } from "pinia"
import { ref, provide } from "vue"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)

const navItems = [
  {
    name: "Overview",
    icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>`,
    to: "/council",
    category: "council",
    subsections: []
  }
]

const title = ref("Council")
function setTitle(value: string) {
  title.value = value
}
provide("setTitle", setTitle)
</script>

<template>
  <main v-if="!loading">
    <PageHeader>{{ title }}</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page Groups">
        <aside>
          <CouncilNav :nav-items="navItems" :slice="1" />
        </aside>
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
    </div>
  </main>
</template>
