<script setup lang="ts">
import { ref, onMounted, nextTick, watch, onUnmounted } from "vue"
import NotificationItem from "@/components/notifications/NotificationItem.vue"
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const Platform = usePlatformStore()
const { unread } = storeToRefs(UserStore)
const { loading } = storeToRefs(Platform)

const filters = [
  {
    title: "All",
    filter: "all",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M0 241.1C0 161 65 96 145.1 96c38.5 0 75.4 15.3 102.6 42.5L320 210.7l72.2-72.2C419.5 111.3 456.4 96 494.9 96C575 96 640 161 640 241.1v29.7C640 351 575 416 494.9 416c-38.5 0-75.4-15.3-102.6-42.5L320 301.3l-72.2 72.2C220.5 400.7 183.6 416 145.1 416C65 416 0 351 0 270.9V241.1zM274.7 256l-72.2-72.2c-15.2-15.2-35.9-23.8-57.4-23.8C100.3 160 64 196.3 64 241.1v29.7c0 44.8 36.3 81.1 81.1 81.1c21.5 0 42.2-8.5 57.4-23.8L274.7 256zm90.5 0l72.2 72.2c15.2 15.2 35.9 23.8 57.4 23.8c44.8 0 81.1-36.3 81.1-81.1V241.1c0-44.8-36.3-81.1-81.1-81.1c-21.5 0-42.2 8.5-57.4 23.8L365.3 256z"/></svg>`
  },
  {
    title: "Mentions",
    filter: "mention",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>`
  },
  {
    title: "Comments",
    filter: "comment",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z"/></svg>`
  },
  {
    title: "Reactions",
    filter: "reaction",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>`
  },
  {
    title: "Follows",
    filter: "follow",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>`
  },
  {
    title: "Requests",
    filter: "request",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zM256 80c-13.3 0-24 10.7-24 24V216c0 13.3 10.7 24 24 24s24-10.7 24-24V104c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>`
  },
  {
    title: "Events",
    filter: "event",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>`,
  },
  {
    title: "System",
    filter: "system",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>`
  },
  {
    title: "Ignored",
    filter: "ignored",
    icon: `<svg class="h-3 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm175 63c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>`
  }
]

const currentFilter = ref<any>(filters[0])
const notificationList = ref<any[]>([])
const initialLoadComplete = ref(false)
const working = ref<boolean>(false)
const pageInfo = ref<any>({
  endCursor: undefined
})

onMounted(async () => {
  await getNotifications()
  initialLoadComplete.value = true
  await nextTick()
  if (notificationList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

onUnmounted(() => {
  UserStore.updateUnread()
  observer.disconnect()
})

watch(currentFilter, async () => {
  initialLoadComplete.value = false
  notificationList.value = []
  pageInfo.value = {
    endCursor: undefined
  }
  await getNotifications()
  initialLoadComplete.value = true
  await nextTick()
  if (notificationList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

async function getNotifications() {
  if (working.value) return
  working.value = true

  let path = `/notifications`

  if (pageInfo.value.endCursor) {
    path += `?${new URLSearchParams({
      cursor: pageInfo.value.endCursor,
      type: currentFilter.value.filter
    })}`
  } else {
    path += `?${new URLSearchParams({
      type: currentFilter.value.filter
    })}`
  }

  try {
    const response = await API().get(path)
    const { data, pageInfo: pgInfo } = response.data

    notificationList.value.push(...data)
    pageInfo.value = pgInfo

    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

function handleDelete(id: string) {
  notificationList.value = notificationList.value.filter(notification => notification._id !== id)
  UserStore.incrementUnread(-1)
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getNotifications()
      }
    }
  })
}, options)
</script>

<template>
  <div v-if="!loading">
    <PageHeader>Notifications</PageHeader>
    <div class="px-2">
      <main class="Page Notifications container mx-auto">
        <aside>
          <select class="block md:hidden bg-white dark:bg-submit-900 w-full rounded-md text-sm outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30" v-model="currentFilter">
            <option :value="filter" v-for="filter, idx in filters" :key="filter.filter">
              {{ filter.title }}
            </option>
          </select>
          <ul class="PageControls space-y-6">
            <li class="inline-flex text-sm text-gray-500 dark:text-gray-400" v-for="filter, idx in filters">
              <a class="flex items-center gap-2 group" @click.prevent="currentFilter = filters[idx]" href="#">
                <figure class="flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white" :class="currentFilter.filter === filter.filter ? '*:fill-gold-700 *:dark:fill-gold-500' : '*:fill-gray-500 *:dark:fill-gray-400'" v-html="filter.icon"></figure>
                <span :class="currentFilter.filter === filter.filter ? 'text-gold-700 dark:text-gold-500' : ''" class="dark:group-hover:text-white group-hover:text-black">{{ filter.title }}</span>
              </a>
            </li>
          </ul>
        </aside>
        <section v-if="initialLoadComplete && notificationList.length > 0">
          <ul class="space-y-4">
            <NotificationItem @remove="handleDelete" :item="item" v-for="item in notificationList" :key="item._id" />
          </ul>
          <div v-if="notificationList.length > 0" id="loadMore"></div>
        </section>
        <section v-if="!initialLoadComplete">
          Loading...
        </section>
        <section v-if="initialLoadComplete && notificationList.length === 0">
          No notifications found.
        </section>
      </main>
    </div>
  </div>
</template>
