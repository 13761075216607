<script setup lang="ts">
import SelfSubmit from "@/components/input/SelfSubmit.vue"
import Label from "@/components/input/Label.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import { ref, onMounted} from "vue"
import API from "@/api/api"

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

const baseRoute = `/users/${id.value}`

const enabledOptions = [
  {text: "Enabled", value: "enabled"},
  {text: "Disabled", value: "disabled"}
]

const privacyData = ref<any>({})
const initialLoadComplete = ref(false)
const starterPacks = ref("enabled")
const disabled = ref(false)
const packs = ref<any[]>([])

async function getMetaData() {
  try {
    const response = await API().get(`/meta`)
    privacyData.value = response.data.data.privacy
  } catch (err) {
    console.error(err)
  }
}

async function getStarterPacks() {
  try {
    const response = await API().get(`/starterpacks/in`)
    packs.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}

async function removeFromPack(packId: string) {
  try {
    const response = await API().delete(`/starterpacks/in/${packId}`)
    packs.value = packs.value.filter((pack: any) => pack._id !== packId)
  } catch (err) {
    console.error(err)
  }
}

onMounted(async () => {
  await getMetaData()

  if (privacyData.value.profileVisibility === "private") {
    disabled.value = true
  }
  if (privacyData.value.following === "disabled") {
    disabled.value = true
  }
  if (privacyData.value.followRequests === "enabled") {
    disabled.value = true
  }
  if (privacyData.value.hasOwnProperty("starterPacks")) {
    starterPacks.value = privacyData.value.starterPacks
  } else {
    starterPacks.value = "enabled"
  }
  if (disabled.value) {
    starterPacks.value = "disabled"
  }

  if (starterPacks.value === "enabled") {
    await getStarterPacks()
  }
  initialLoadComplete.value = true
})
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Starter Packs</h2>
        <p>Starter packs are a collection of users, groups, and content that are user-curated to help you get started on Submit. Control whether or not you can be included in starter packs, see which starter packs you're in, and remove yourself from them if you'd like.</p>
        <div v-if="initialLoadComplete">
          <Label field="privacy.profileVisibility">Starter Packs</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Enabling starter packs will allow you to create starter packs as well as be added to them. Disabling starter packs will remove you from any packs that you have been added to, prevent you from being added to any, and prevent you from creating new starter packs.</p>
          <SelfSubmit v-model="starterPacks" name="privacy.starterPacks" :apiEndpoint="baseRoute" :options="enabledOptions" :disabled="disabled" :helpText="disabled ? 'You cannot enable starter packs while your profile is private, following is disabled, or follow requests are enabled.' : ''" />
        </div>
        <div v-if="initialLoadComplete && !disabled && starterPacks === 'enabled'">
          <div class="text-sm font-bold mb-1">Starter Packs You're In</div>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-6">Here you can see which starter packs you're in, and remove yourself from them if you'd like.</p>
          <ul class="mt-3 text-sm space-y-3">
            <li v-if="packs.length === 0">You are not in any starter packs, yet!</li>
            <li class="flex justify-between" v-if="packs.length > 0" v-for="pack in packs" :key="pack._id">
              <router-link :to="`/starter-packs/${pack.slug}`">{{ pack.name }}</router-link>
              <button class="dark:text-red-500 dark:hover:text-white" type="button" @click="removeFromPack(pack._id)" v-if="pack.user !== id">Remove Yourself</button>
              <div v-if="pack.user === id">Your Pack</div>
            </li>
          </ul>
        </div>
        <div v-if="!initialLoadComplete">
          Loading...
        </div>
      </div>
    </section>
  </main>
</template>
