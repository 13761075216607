<script setup lang="ts">
import { ref, onMounted, inject, type Ref } from "vue"
import { useRoute } from "vue-router"
import PostEditor from "@/components/groups/PostEditor.vue"
import API from "@/api/api"

const Route = useRoute()
const slug = inject("slug") as Ref<string>
const short = Route.params.short

const initialLoadComplete = ref(false)
const post = ref<any>({})

onMounted(async () => {
  await getPost()
  initialLoadComplete.value = true
})

async function getPost() {
  try {
    const response = await API().get(`/groups/g/${slug.value}/posts/${short}`)
    post.value = response.data.data.post
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <div v-if="initialLoadComplete">
    <PostEditor :post="post" />
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>
