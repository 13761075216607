<script setup lang="ts">
import { ref, inject, onBeforeMount, onUnmounted, onMounted, nextTick } from "vue"
import KinkItem from "@/components/interests/InterestItem.vue"
import setupCommons from "@/composables/Common"
import API from "@/api/api"

const { actionHandler, scrollToTop } = setupCommons()

const setTitle = inject("setTitle") as (value: string) => void

const working = ref(false)
const error = ref(false)
const randomInterests = ref<any[]>([])
const initialLoadComplete = ref(false)

onBeforeMount(async () => {
  await nextTick()
  setTitle("Interests")
})

onMounted(async () => {
  await getRandomInterests()
  initialLoadComplete.value = true
})

onUnmounted(() => {
  setTitle("Kinks")
})

async function getRandomInterests(setWorking: boolean = true) {
  if (working.value) return
  if (setWorking) working.value = true

  let path = `/interests/random?type=interest`

  try {
    const response = await API().get(path)
    randomInterests.value = response.data.data
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    return
  }
}
</script>

<template>
  <div class="content-start">
    <main class="space-y-6" v-if="initialLoadComplete">
      <section class="grid">
        <header class="col-span-full flex justify-between text-sm font-bold items-center mb-4">
          <div>Random Interests (<router-link class="underline decoration-dotted hover:decoration-solid" to="/interests/all">View All</router-link>)</div>
          <div>
            <button class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group" @click="getRandomInterests()">
              <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
            </button>
          </div>
        </header>
        <ul @click="actionHandler" class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-sm ItemList">
          <KinkItem :interest="kink" v-for="kink in randomInterests" :key="kink._id" v-if="randomInterests.length > 0 && !working" />
          <li v-if="randomInterests.length === 0 && !working">No interests found.</li>
        </ul>
      </section>
      <section class="grid">
        <header class="col-span-full text-sm font-bold mb-4">
          Popular Interests
        </header>
        <section class="text-sm">Coming Soon</section>
      </section>
    </main>
    <main v-else>
      Loading...
    </main>
  </div>
</template>
