<script setup lang="ts">
import { inject, onBeforeMount, onMounted, ref, provide, type Ref } from "vue"
import UserItem from "@/components/events/UserItem.vue"
import { useRouter, useRoute } from "vue-router"
import API from "@/api/api"

const Router = useRouter()
const Route = useRoute()

const initialLoadComplete = ref<boolean>(false)
const working = ref<boolean>(false)
const error = ref<boolean>(false)

const short = inject<any>("short") as Ref<string>
const isOwner = inject<any>("isOwner") as Ref<boolean>
const isOrganizer = inject<any>("isOrganizer") as Ref<boolean>
const isRequestOnly = inject<any>("isRequestOnly") as Ref<boolean>

const type = ref<string>("rsvp")
provide("filterType", type)

const users = ref<any[]>([])

async function fetchUsers(type: string) {
  if (working.value) return
  working.value = true
  try {
    const queryString = new URLSearchParams({
      type: type
    })
    const response = await API().get(`/events/${short.value}/users?${queryString.toString()}`)
    users.value = response.data.data
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

async function changeType(newType: string) {
  type.value = newType
  users.value = []
  initialLoadComplete.value = false
  await fetchUsers(type.value)
  initialLoadComplete.value = true
}

onBeforeMount(() => {
  if (!isOwner.value && !isOrganizer.value) {
    return Router.push({ name: "Event.Details", params: { slug: Route.params.event }})
  }
})

onMounted(async () => {
  await fetchUsers(type.value)
  initialLoadComplete.value = true
})

const handleRemoved = (userId: string) => {
  users.value = users.value.filter((user) => user._id !== userId)
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Users</h2>
      <p>Manage attendees and interested &amp; banned users for this event.</p>
      <ul class="flex gap-6 items-center text-sm font-semibold">
        <li>
          <button
            :class="[
              type === 'rsvp' ? 'text-gold-700 dark:text-gold-500 font-bold' : 'text-gray-600 dark:text-gray-400',
              'dark:hover:text-white hover:text-black'
            ]"
            @click="changeType('rsvp')"
          >Attendees</button>
        </li>
        <li v-if="!isRequestOnly">
          <button
            :class="[
              type === 'considering' ? 'text-gold-700 dark:text-gold-500 font-bold' : 'text-gray-600 dark:text-gray-400',
              'dark:hover:text-white hover:text-black'
            ]"
            @click="changeType('considering')"
          >Interested</button>
        </li>
        <li>
          <button
            :class="[
              type === 'banned' ? 'text-gold-700 dark:text-gold-500 font-bold' : 'text-gray-600 dark:text-gray-400',
              'dark:hover:text-white hover:text-black'
            ]"
            @click="changeType('banned')"
          >Banned</button>
        </li>
        <li class="ml-auto">
          <button class="text-gray-600 dark:text-gray-400 dark:hover:text-white hover:text-black" @click="fetchUsers(type)">Refresh</button>
        </li>
      </ul>
      <table class="table w-full">
        <thead class="text-sm font-bold dark:text-white">
          <tr>
            <th class="text-left border-b border-gray-200 dark:border-gray-600">Username</th>
            <th class="text-left border-b border-gray-200 dark:border-gray-600" v-if="type === 'banned'">Reason</th>
            <th class="text-left border-b border-gray-200 dark:border-gray-600" v-if="type === 'banned'">Notes</th>
            <th class="text-right border-b border-gray-200 dark:border-gray-600">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!initialLoadComplete">
            <td class="py-2 text-center text-sm font-semibold" :colspan="type === 'banned' ? 4 : 2">Loading...</td>
          </tr>
          <tr v-if="users.length === 0 && initialLoadComplete">
            <td class="py-2 text-center text-sm font-semibold" :colspan="type === 'banned' ? 4 : 2">No users found.</td>
          </tr>
          <UserItem v-for="user in users" :key="user._id" :user="user" @removed="handleRemoved" />
        </tbody>
      </table>
    </section>
  </main>
</template>
