<script setup lang="ts">
import Location from "@/components/input/LocationRaw.vue"
import Listing from "@/components/events/Listing.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import { ref, reactive } from "vue"

const theme = ref(localStorage.getItem("theme") || "dark")
const UserStore = useUserStore()
const { location } = storeToRefs(UserStore)

const filter = reactive({
  type: "in-person",
  date: new Date(new Date()),
  category: ["submit-event","educational", "social", "bdsm", "kink", "sex", "conference"],
  distance: 10,
  distanceUnit: "km",
  location: location.value ? location.value : { place_name_en: undefined },
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
})
</script>

<template>
  <div class="px-2">
    <div class="container mx-auto EventList">
      <div class="space-y-3 text-sm">
        <div class="space-y-3 font-semibold">
          <button @click="filter.type = 'in-person'" class="block w-full px-4 py-2 rounded-md hover:dark:text-white" :class="filter.type === 'in-person' ? 'bg-white dark:bg-submit-900 text-gold-700 dark:text-gold-500' : 'text-gray-700 dark:text-gray-400'">In-Person Events</button>
          <button @click="filter.type = 'virtual'" class="block w-full px-4 py-2 rounded-md hover:dark:text-white" :class="filter.type === 'virtual' ? 'bg-white dark:bg-submit-900 text-gold-700 dark:text-gold-500' : 'text-gray-700 dark:text-gray-400'">Virtual Events</button>
        </div>
        <div v-if="filter.type === 'in-person'">
          <Location v-model="filter.location" />
        </div>
        <div class="flex gap-2" v-if="filter.type === 'in-person'">
          <select v-model="filter.distance" class="flex-1 text-sm rounded-md bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
          <select v-model="filter.distanceUnit" class="text-sm rounded-md bg-white dark:bg-submit-900 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50">
            <option value="km">km</option>
            <option value="mi">mi</option>
          </select>
        </div>
        <div>
          <VueDatePicker
            v-model="filter.date"
            :dark="theme === 'dark'"
            :enable-time-picker="false"
            :hide-input-icon="true"
            input-class-name="text-sm"
            auto-apply
            :clearable="false"
          />
        </div>
        <div class="space-y-1">
          <h3 class="text-sm font-semibold text-gray-500">Category</h3>
          <fieldset class="grid text-sm gap-1">
            <label class="flex items-center gap-1 dark:text-gray-300 text-gray-700">
              <input v-model="filter.category" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0" name="category" value="educational" />
              <div class="px-2 py-0.5 dark:bg-submit-900 bg-white text-pink-450 font-bold">E</div>
              <span>Educational</span>
            </label>
            <label class="flex items-center gap-1 dark:text-gray-300 text-gray-700">
              <input v-model="filter.category" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0" name="category" value="social" />
              <div class="px-2 py-0.5 dark:bg-submit-900 bg-white text-pink-450 font-bold">S</div>
              <span>Social</span>
            </label>
            <label class="flex items-center gap-1 dark:text-gray-300 text-gray-700">
              <input v-model="filter.category" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0" name="category" value="bdsm" />
              <div class="px-2 py-0.5 dark:bg-submit-900 bg-white text-pink-450 font-bold">B</div>
              <span>BDSM Party</span>
            </label>
            <label class="flex items-center gap-1 dark:text-gray-300 text-gray-700">
              <input v-model="filter.category" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0" name="category" value="kink" />
              <div class="px-2 py-0.5 dark:bg-submit-900 bg-white text-pink-450 font-bold">K</div>
              <span>Kink Party</span>
            </label>
            <label class="flex items-center gap-1 dark:text-gray-300 text-gray-700">
              <input v-model="filter.category" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0" name="category" value="sex" />
              <div class="px-2 py-0.5 dark:bg-submit-900 bg-white text-pink-450 font-bold">X</div>
              <span>Sex Party</span>
            </label>
            <label class="flex items-center gap-1 dark:text-gray-300 text-gray-700">
              <input v-model="filter.category" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0" name="category" value="conference" />
              <div class="px-2 py-0.5 dark:bg-submit-900 bg-white  text-pink-450 font-bold">F</div>
              <span>Conference or Festival</span>
            </label>
            <label class="flex items-center gap-1 dark:text-gray-300 text-gray-700">
              <input v-model="filter.category" type="checkbox" class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-700/70 dark:bg-submit-900 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30 focus:border-0 focus:ring-0 focus:ring-offset-0" name="category" value="submit-event" />
              <div class="px-2 py-0.5 dark:bg-submit-900 bg-white text-gold-700 dark:text-gold-500 font-bold">O</div>
              <span>Submit Event</span>
            </label>
          </fieldset>
        </div>
      </div>
      <Listing :filters="filter" type="listing" />
    </div>
  </div>
</template>

<style>
.dp__theme_light {
  --dp-font-family: @apply font-sans;
  --dp-font-size: 0.875rem;
  --dp-border-radius: 6px;
  --dp-input-padding: 8px 12px;
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: rgb(188 140 78 / 1);
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: rgb(107 114 128 / 1);
  --dp-menu-border-color: rgb(107 114 128 / 1);
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

.dp__theme_dark {
  --dp-font-family: @apply font-sans;
  --dp-font-size: 0.875rem;
  --dp-border-radius: 6px;
  --dp-input-padding: 8px 12px;
  --dp-background-color: #161524;
  --dp-text-color: rgb(212 212 212 / 1);
  --dp-hover-color: hsl(46, 21%, 23%);
  --dp-hover-text-color: #fff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: rgb(221 190 118 / 1);
  --dp-primary-disabled-color: #61a8ea;
  --dp-primary-text-color: rgb(41 38 59 / 1);
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: transparent;
  --dp-menu-border-color: rgb(131 137 150 / 0.3);
  --dp-border-color-hover: transparent;
  --dp-disabled-color: #737373;
  --dp-disabled-color-text: #d0d0d0;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-marker-color: #e53935;
  --dp-tooltip-color: #3e3e3e;
  --dp-highlight-color: rgb(0 92 178 / 20%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
  --dp-range-between-border-color: var(--dp-hover-color, #fff);
}
</style>
