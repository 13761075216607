<script setup lang="ts">
import NavItem from "@/components/core/NavItem.vue"
import { inject } from "vue"

const profileData: any = inject("profileData")
</script>

<template>
  <section class="px-2">
    <div class="container mx-auto Profile Content">
      <aside>
        <ul class="grid grid-rows-1 grid-cols-2 mb-6 md:mb-0 md:grid-rows-2 md:grid-cols-1 gap-4">
          <NavItem :to="{ name: 'Profile.Media.Photos', params: { username: profileData.username }}">
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
            </template>
            <template #label>
              Photos
            </template>
          </NavItem>
          <NavItem :to="{ name: 'Profile.Media.Collections', params: { username: profileData.username }}">
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 480H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64zM176 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm120 0c-8 0-15.5 4-20 10.7l-56 84L202.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H392c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144C311.5 228 304 224 296 224z"/></svg>
            </template>
            <template #label>
              Collections
            </template>
          </NavItem>
          <NavItem :to="{ name: 'Settings.Profile.Photo' }" v-if="profileData.self">
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 32V320c0 17.7 14.3 32 32 32h16c0-35.3 28.7-64 64-64h96c35.3 0 64 28.7 64 64h16c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32zm96 64a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg>
            </template>
            <template #label>
              Profile Photo
            </template>
          </NavItem>
        </ul>
      </aside>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
  </section>
</template>
