<script setup lang="ts">
import { ref, inject, onBeforeMount, onUnmounted, onMounted, nextTick } from "vue"

const setTitle = inject("setTitle") as (value: string) => void

onBeforeMount(async () => {
  await nextTick()
  setTitle("Your Lists")
})

onUnmounted(() => {
  setTitle("Kinks")
})
</script>

<template>
  <p>This feature is coming soon, check the <router-link class="underline hover:decoration-2 dark:hover:decoration-gold-500" to="/roadmap">roadmap</router-link> and/or <router-link class="underline hover:decoration-2 dark:hover:decoration-gold-500" to="/groups/submit-development">Submit Development</router-link> for updates and more details.</p>
</template>
