<script setup lang="ts">
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router"
import LocationHeader from "@/components/locations/Header.vue"
import { usePlatformStore } from "@/stores/Platform"
import { ref, computed, onBeforeMount } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)

const route = useRoute()
const router = useRouter()

const locationData = ref<any>({})
const locationType = ref<string>("")

function getLocationType(location: string[]) {
  if (location.length === 1) return "country"
  if (location.length === 2) return "state"
  if (location.length === 3) return "city"
  return "country"
}

const subtitleData = computed(() => {
  let data: any[] = []
  if (locationData.value.country && locationData.value.state && locationData.value.city) {
    data.push(locationData.value.state)
    data.push(locationData.value.country)
    return data
  }
  if (locationData.value.country && locationData.value.state) {
    data.push(locationData.value.country)
    return data
  }
  if (locationData.value.country && locationData.value.city) {
    data.push(locationData.value.country)
    return data
  }
  return data
})

onBeforeMount(async () => {
  PlatformStore.setLoading(true)
  await fetchLocation()
  document.title = `${locationData.value[locationType.value].name} - Submit`
})

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.location.length > 0) {
    PlatformStore.setLoading(true)
    locationData.value = {}
    locationType.value = ""
    await fetchLocation(to.params.location as string[])
    document.title = `${locationData.value[locationType.value].name} - Submit`
  }
})

async function fetchLocation(location?: string[]) {
  const locationParams = location || route.params.location
  locationType.value = getLocationType(locationParams as string[])
  const lastParam = locationParams[locationParams.length - 1]
  try {
    const response = await API().get(`/locations/${locationType.value}/${lastParam}`)
    locationData.value = response.data.data
    if (locationType.value === "state" && !locationData.value.hasOwnProperty("state") && locationData.value.hasOwnProperty("city")) {
      locationType.value = "city"
    }
    PlatformStore.setLoading(false)
  } catch (err: any) {
    console.error(err)
    router.push("/404")
  }
}
</script>

<template>
  <main v-if="!loading">
    <LocationHeader>
      <template #title>{{ locationData[locationType].name }}</template>
      <template #subtitle>
        <ul class="flex">
          <li class="inline" v-for="(item, index) in subtitleData" :key="index">
            <router-link class="hover:underline" :to="`/l${item.path}`">{{ item.name }}</router-link>
            <span v-if="index < subtitleData.length - 1">,&thinsp;</span>
          </li>
        </ul>
      </template>
    </LocationHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-full">
          <div class="space-y-6" v-if="locationType === 'city' || (locationType === 'country' && locationData.country.isCityState)">
            <section class="space-y-3">
              <h4 class="font-bold text-sm">Events</h4>
              <ul class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <li class="text-sm">
                  Coming soon.
                </li>
              </ul>
            </section>
            <section class="space-y-3">
              <h4 class="font-bold text-sm">Groups</h4>
              <ul class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <li class="text-sm">
                  Coming soon.
                </li>
              </ul>
            </section>
            <section class="space-y-3">
              <h4 class="font-bold text-sm">Users</h4>
              <ul class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <li class="text-sm">
                  Coming soon.
                </li>
              </ul>
            </section>
          </div>
          <div class="space-y-6" v-if="locationType === 'state'">
            <section class="space-y-3">
              <h4 class="font-bold text-sm mb-3">Locations</h4>
              <ul class="inline">
                <li class="inline text-sm" v-for="(item, index) in locationData.state.locations" :key="index">
                  <router-link class="hover:underline" :to="`/l${item.path}`">{{ item.name }}</router-link>
                  <span v-if="index < locationData.state.locations.length - 1">,&thinsp;</span>
                </li>
                <li class="inline text-sm" v-if="locationData.state.locations.length === 0">
                  No locations yet.
                </li>
              </ul>
            </section>
            <section class="space-y-3">
              <h4 class="font-bold text-sm">Major Events</h4>
              <ul class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <li class="text-sm">
                  Coming soon.
                </li>
              </ul>
            </section>
            <section class="space-y-3">
              <h4 class="font-bold text-sm">Groups</h4>
              <ul class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <li class="text-sm">
                  Coming soon.
                </li>
              </ul>
            </section>
          </div>
          <div class="space-y-6" v-if="locationType === 'country' && !locationData.country.isCityState">
            <section class="space-y-3">
              <h4 class="font-bold text-sm mb-3">Locations in {{ locationData.country.name }}</h4>
              <ul class="inline">
                <li class="inline text-sm" v-for="(item, index) in locationData.country.locations" :key="index">
                  <router-link class="hover:underline" :to="`/l${item.path}`">{{ item.name }}</router-link>
                  <span v-if="index < locationData.country.locations.length - 1">,&thinsp;</span>
                </li>
                <li class="inline text-sm" v-if="locationData.country.locations.length === 0">
                  No locations in this country yet.
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
