<script setup lang="ts">
import ListPack from "@/components/starterpacks/ListPack.vue"
import PageHeader from "@/components/core/PageHeader.vue"
import setupCommons from "@/composables/Common"
import { ref, reactive, onMounted } from "vue"
import API from "@/api/api"

const { actionHandler } = setupCommons()

interface PageData {
  cursor: string | null
  hasMore: boolean
}

const pageData: PageData = reactive({
  cursor: null,
  hasMore: false
})
const packs = ref<any[]>([])
const working = ref(false)
const initialLoadComplete = ref(false)

async function getStarterPacks() {
  if (working.value) return
  working.value = true
  const path = "/starterpacks"

  try {
    const query = new URLSearchParams()
    if (pageData.hasMore && pageData.cursor) query.set("cursor", pageData.cursor)
    query.set("status", "published")
    const res = await API().get(path, { params: query })
    const data = res.data
    packs.value.push(...data.data)
  } catch (err) {
    console.error(err)
  }
}

onMounted(async () => {
  await getStarterPacks()
  initialLoadComplete.value = true
})
</script>

<template>
  <main>
    <PageHeader>Starter Packs</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-full space-y-6">
          <nav>
            <ul class="flex gap-4">
              <li class="font-bold text-gold-700 dark:text-gold-500">
                <router-link class="hover:text-black dark:hover:text-white" to="/starter-packs">All</router-link>
              </li>
              <li class="text-gray-400 dark:text-gray-500">
                <router-link class="hover:text-black dark:hover:text-white" to="/starter-packs/yours">Your Starter Packs</router-link>
              </li>
              <li class="ml-auto">
                <router-link class="hover:text-black dark:hover:text-white" to="/starter-packs/create">Create Starter Pack</router-link>
              </li>
            </ul>
          </nav>
          <div class="text-sm font-bold" v-if="!initialLoadComplete">
            Loading...
          </div>
          <div v-else>
            <div class="text-sm font-bold mb-1">{{ packs.length }} packs</div>
            <ul @click="actionHandler" class="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
              <ListPack v-for="pack in packs" :key="pack._id" :pack="pack" />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
