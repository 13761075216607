<script setup lang="ts">
import { usePlatformStore } from "@/stores/Platform"
import Feed from "@/components/explore/Feed.vue"
import { storeToRefs } from "pinia"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)
</script>

<template>
  <Feed />
</template>
