<script setup lang="ts">
import { computed, ref, watch, reactive } from "vue"
import Form from "@/components/input/Form.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Combo from "@/components/input/Combo.vue"
import Checkbox from "@/components/input/Checkbox.vue"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const schema = zod.object({
  name: zod.string()
    .min(3, { message: "Too short." })
    .max(128, { message: "Too long." }),
  type: zod.string()
    .min(1, { message: "You must select a type." }),
  interestTypes: zod.array(zod.string())
    .optional(),
  clarifyingTypes: zod.array(zod.string())
    .optional()
})

const clarificationTypes = [
  { value: "giving", label: "Giving" },
  { value: "receiving", label: "Receiving" },
  { value: "watching", label: "Watching" },
  { value: "wearing", label: "Wearing" },
  { value: "owearing", label: "Watching Others Wear" },
  { value: "everything", label: "Everything" }
]

const debug = ref(false)
const addOpen = ref(false)
const error = ref(false)
const working = ref(false)
const initialValues = reactive({
  name: "",
  type: "official",
  interestTypes: [],
  clarifyingTypes: [],
  canBeLimit: true,
  canHaveQuestions: true,
  canListPublicly: true
})

function shouldShow(type: string, tools: any): boolean {
  const currentType = tools.getFieldValue("type")
  return currentType === type
}

function toggleAddOpen() {
  addOpen.value = !addOpen.value
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  const newInterest: any = {
    name: values.name,
    type: values.type,
    canBeLimit: values.canBeLimit,
    canHaveQuestions: values.canHaveQuestions,
    canListPublicly: values.canListPublicly
  }

  if (values.type === 'official') {
    if (values.interestTypes.length > 0) {
      newInterest.interestTypes = values.interestTypes
    }
    if (values.clarifyingTypes.length > 0) {
      newInterest.clarifyingTypes = values.clarifyingTypes
    }
  }

  await API().post(`/interests`, newInterest)
  .catch((err: any) => {
    console.error(err)
    error.value = true
  })

  working.value = false
  toggleAddOpen()
}
</script>

<template>
  <div class="grid content-start">
    <section>
      <button @click="toggleAddOpen" type="button">Add Kink or Interest</button>
      <TransitionRoot as="template" :show="addOpen">
        <Dialog as="div" class="relative z-[100]" @close="toggleAddOpen">
          <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
              <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                    <Form @on-submit="handleSubmit" :initial-values="initialValues" :schema="schema" class="flex h-full flex-col overflow-y-scroll bg-neutral-125 dark:bg-submit-950 pb-6 shadow-xl" v-slot="{ fields, errors, tools }">
                      <div class="flex bg-white dark:bg-submit-925 py-6 px-4 sm:px-6">
                        <div class="flex flex-grow flex-col">
                          <DialogTitle class="text-base font-semibold leading-6 dark:text-white">Add Kink or Interest</DialogTitle>
                        </div>
                        <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-0" @click="toggleAddOpen">
                          <span class="sr-only">Close panel</span>
                          <svg class="dark:fill-white h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                        </button>
                      </div>
                      <div class="relative flex flex-col mt-10 flex-1 px-4 sm:px-6">
                        <div class="flex flex-1 flex-col justify-start space-y-3">
                          <div>
                            <Label field="name">Name</Label>
                            <Field name="name" />
                          </div>

                          <div>
                            <Label field="type">Type</Label>
                            <Field name="type" type="select">
                              <option value="official">Kink</option>
                              <option value="user" disabled>User-Kink</option>
                              <option value="interest">Interest</option>
                            </Field>
                          </div>

                          <div v-if="shouldShow('official', tools)">
                            <Label field="interestTypes">Interest Types</Label>
                            <Combo name="interestTypes" :options="['into', 'curious']" multiple />
                          </div>

                          <div v-if="shouldShow('official', tools)">
                            <Label field="clarifyingTypes">Clarifying Types</Label>
                            <Combo name="clarifyingTypes" :options="clarificationTypes" multiple />
                          </div>

                          <div>
                            <Checkbox name="canBeLimit">
                              Can be a Limit
                            </Checkbox>
                          </div>

                          <div>
                            <Checkbox name="canHaveQuestions">
                              Can have Questions
                            </Checkbox>
                          </div>

                          <div>
                            <Checkbox name="canListPublicly">
                              Can list Publicly
                            </Checkbox>
                          </div>

                          <div v-if="debug">
                            <pre>
{{ fields }}
                            </pre>
                          </div>

                          <div v-if="error" class="text-red-500 text-sm font-bold">
                            There was an error filing this report, please try again later.
                          </div>
                        </div>
                      </div>
                      <div class="flex border-t border-t-indigo-350 mt-6 pt-6 px-4 sm:px-6">
                        <div class="flex justify-end w-full gap-4">
                          <button type="button" @click="toggleAddOpen" class="py-2 text-sm px-4 border dark:border-indigo-250 rounded-md dark:text-indigo-250">Cancel</button>
                          <button type="submit" :class="`py-2 px-4 text-sm border border-transparent rounded-md font-semibold bg-gold-700 dark:bg-gold-500 dark:text-indigo-950`">Create</button>
                        </div>
                      </div>
                    </Form>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </section>
    <section>
      <h2>User-Created Kink Queue</h2>
    </section>
  </div>
</template>
