<script setup lang="ts">
import RecurringDays from "@/components/input/RecurringDays.vue"
import RadioGroup from "@/components/input/RadioGroup.vue"
import EventDays from "@/components/input/EventDays.vue"
import Location from "@/components/input/Location.vue"
import Checkbox from "@/components/input/Checkbox.vue"
import DatePicker from "@/components/input/Date.vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import Tags from "@/components/input/Tags.vue"
import File from "@/components/input/File.vue"
import { ref, reactive, computed } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const UserStore = useUserStore()
const { roles } = storeToRefs(UserStore)

const userTimezoneOffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
const minStartDateTime = new Date(Date.now() + 86400000 - userTimezoneOffset)

const schema = zod.object({
  type: zod.string()
    .min(1, { message: "Please select an event type" }),
  name: zod.string()
    .min(1, { message: "Please enter a name" }),
  description: zod.string()
    .min(1, { message: "Please enter a description" }),
  category: zod.string()
    .min(1, { message: "Please select a category" }),
  cost: zod.string()
    .min(1, { message: "Please enter a cost" }),
  website: zod.string()
    .min(1, { message: "Please enter a website" }).url({ message: "Please enter a valid URL" })
    .optional()
    .or(zod.literal('')),
  tags: zod.array(zod.string()),
  durationFrequency: zod.string()
    .min(1, { message: "Please select a duration and frequency" }),
  timezone: zod.string()
    .min(1, { message: "Please select a timezone" }),
  startDateTime: zod.date({
    invalid_type_error: "Start is required.",
  })
    .min(minStartDateTime, { message: "Start must be at least 24 hours in the future." }),
  endDateTime: zod.date({
    invalid_type_error: "End is required.",
  })
    .min(minStartDateTime, { message: "End must be at least 24 hours in the future." }),
  startTime: zod.object({
    hours: zod.number(),
    minutes: zod.number(),
    seconds: zod.number()
  }, {
    required_error: "Start time is required.",
    invalid_type_error: "Start time is required."
  }),
  endTime: zod.object({
    hours: zod.number(),
    minutes: zod.number(),
    seconds: zod.number()
  }, {
    required_error: "End time is required.",
    invalid_type_error: "End time is required."
  }),
  venueName: zod.string()
    .min(1, { message: "Please enter a venue name" }),
  "venueAddress.street_one": zod.string()
    .min(1, { message: "Please enter a street address" }),
  "venueAddress.location": zod.object({
    place_name_en: zod.string()
      .min(1, { message: "Please enter a location" })
  })
})

const initialValues = reactive({
  type: "",
  coverPhoto: null,
  name: "",
  description: "",
  category: "",
  cost: "",
  dress: "",
  website: "",
  tags: [],
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "",
  startDateTime: "",
  endDateTime: "",
  endsAfterMidnight: false,
  isRecurring: false,
  virtualLocation: {
    place_name_en: undefined
  },
  'venueAddress.location': {
    place_name_en: undefined
  },
  venueWheelchair: false,
  venuePublicTransportation: false,
  venueParking: false,
  venueAgeRestricted: false,
  requestOnly: false,
  hideVenue: false,
  addCreatorBlocklist: false,
  hideFromListings: false,
  eventInbox: true,
  faq: false,
  rsvpConfirmation: false,
  allowEventManagers: true,
})

const radioOptions = [
  {
    value: "in-person",
    label: "In-Person",
    description: "An event that takes place at a physical location in the real world, with real people."
  },
  {
    value: "virtual",
    label: "Virtual",
    description: "A virtual event that takes place online."
  }
]

const timezones = [
  { value: "Pacific/Midway", label: "(GMT-11:00) Midway Island, Samoa" },
  { value: "Pacific/Honolulu", label: "(GMT-10:00) Hawaii" },
  { value: "America/Anchorage", label: "(GMT-09:00) Alaska" },
  { value: "America/Los_Angeles", label: "(GMT-08:00) Pacific Time (US & Canada)" },
  { value: "America/Denver", label: "(GMT-07:00) Mountain Time (US & Canada)" },
  { value: "America/Chicago", label: "(GMT-06:00) Central Time (US & Canada)" },
  { value: "America/New_York", label: "(GMT-05:00) Eastern Time (US & Canada)" },
  { value: "America/Caracas", label: "(GMT-04:30) Caracas" },
  { value: "America/Halifax", label: "(GMT-04:00) Atlantic Time (Canada)" },
  { value: "America/St_Johns", label: "(GMT-03:30) Newfoundland" },
  { value: "America/Sao_Paulo", label: "(GMT-03:00) Brasilia" },
  { value: "Atlantic/South_Georgia", label: "(GMT-02:00) Mid-Atlantic" },
  { value: "Atlantic/Azores", label: "(GMT-01:00) Azores" },
  { value: "Europe/London", label: "(GMT+00:00) London, Lisbon" },
  { value: "Europe/Paris", label: "(GMT+01:00) Paris, Berlin, Rome, Madrid" },
  { value: "Europe/Helsinki", label: "(GMT+02:00) Helsinki, Kiev, Riga" },
  { value: "Europe/Moscow", label: "(GMT+03:00) Moscow, St. Petersburg" },
  { value: "Asia/Tehran", label: "(GMT+03:30) Tehran" },
  { value: "Asia/Dubai", label: "(GMT+04:00) Abu Dhabi, Muscat" },
  { value: "Asia/Kabul", label: "(GMT+04:30) Kabul" },
  { value: "Asia/Karachi", label: "(GMT+05:00) Islamabad, Karachi" },
  { value: "Asia/Kolkata", label: "(GMT+05:30) Mumbai, New Delhi" },
  { value: "Asia/Kathmandu", label: "(GMT+05:45) Kathmandu" },
  { value: "Asia/Dhaka", label: "(GMT+06:00) Dhaka" },
  { value: "Asia/Rangoon", label: "(GMT+06:30) Yangon (Rangoon)" },
  { value: "Asia/Bangkok", label: "(GMT+07:00) Bangkok, Hanoi" },
  { value: "Asia/Shanghai", label: "(GMT+08:00) Beijing, Hong Kong" },
  { value: "Asia/Tokyo", label: "(GMT+09:00) Tokyo, Seoul" },
  { value: "Australia/Adelaide", label: "(GMT+09:30) Adelaide" },
  { value: "Australia/Sydney", label: "(GMT+10:00) Sydney, Melbourne" },
  { value: "Asia/Magadan", label: "(GMT+11:00) Solomon Is., New Caledonia" },
  { value: "Pacific/Auckland", label: "(GMT+12:00) Auckland, Wellington" },
  { value: "Pacific/Tongatapu", label: "(GMT+13:00) Nuku'alofa" },
  { value: "Pacific/Kiritimati", label: "(GMT+14:00) Kiritimati" },
]

const recurringFrequencyOptions = [
  { value: "", text: "Select a frequency" },
  { value: "weekly", text: "weekly" },
  { value: "bi-weekly", text: "bi-weekly" },
  { value: "monthly", text: "monthly" },
  { value: "first", text: "the first day of the month" },
  { value: "second", text: "the second day of the month" },
  { value: "third", text: "the third day of the month" },
  { value: "fourth", text: "the fourth day of the month" },
  { value: "last", text: "the last day of the month" }
]

const debug = ref(false)
const working = ref(false)
const currentStep = ref(1)
const startDateTime = ref<Date | null>(null)
const endDateTime = ref<Date | null>(null)

const submitted = ref(false)
const submitValues = ref<any>(null)

const lateLocked = ref(false)

const newEventData = ref<any>(null)

const isAdmin = computed(() => {
  return roles.value.includes("admin")
})

const error = ref<boolean>(false)

function getEventType(tools: any) {
  return tools.getFieldValue("type")
}

function getFieldValue(tools: any, name: string) {
  return tools.getFieldValue(name)
}

const handleStepChanged = (step: number) => {
  currentStep.value = step
}

const handleStartDateTime = (date: Date) => {
  startDateTime.value = date
}

const handleEndDateTime = (date: Date) => {
  endDateTime.value = date
}

const handleDFChange = (e: Event, tools: any) => {
  const thisValue = (e.target as HTMLSelectElement).value
  switch (thisValue) {
    case 'one-time':
      tools.unregisterField('recurringDays')
      tools.unregisterField('eventDays')
      tools.unregisterField('firstOccurence')
      tools.unregisterField('recurringFrequency')
      tools.unregisterField('recurringCount')
      tools.unregisterField('endsAfterMidnight')
      break
    case 'recurring':
      tools.unregisterField('eventDays')
      tools.unregisterField('startDateTime')
      tools.unregisterField('endDateTime')
      break
    case 'multi-day':
      tools.unregisterField('recurringDays')
      tools.unregisterField('startTime')
      tools.unregisterField('endTime')
      tools.unregisterField('firstOccurence')
      tools.unregisterField('recurringFrequency')
      tools.unregisterField('recurringCount')
      tools.unregisterField('endsAfterMidnight')
      break
  }
}

const handleVirtualLocationChange = (v: boolean, tools: any) => {
  if (!v) {
    tools.unregisterField('virtualLocation')
  }
}

const handleVirtualFormatChange = (t: any, tools: any) => {
  if (t.target.value !== "other") {
    tools.unregisterField('virtualFormatOther')
  }
}

function imageSize(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight })
    }
    img.onerror = reject
    img.src = url
  })
}

function getImageData(file: File): Promise<any> {
  return new Promise((resolve) => {
    const blob = URL.createObjectURL(file)
    const reader = new FileReader()
    reader.onload = async () => {
      const imageDimensions = await imageSize(blob)
      resolve({
        name: file.name,
        size: file.size,
        src: blob,
        type: file.type,
        raw: file,
        width: imageDimensions.width,
        height: imageDimensions.height
      })
    }
    reader.readAsArrayBuffer(file)
  })
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let formData = new FormData()
  // add each field to the form data
  Object.entries(values).forEach(([key, value]) => {
    if (key !== 'coverPhoto') {
      if (typeof value === 'object' && value !== null) {
        // Stringify objects and arrays
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value as any)
      }
    }
  })

  if (values.coverPhoto) {
    try {
      const imageData = await getImageData(values.coverPhoto)
      formData.append('coverPhoto', imageData.raw)
      formData.append('coverPhotoWidth', imageData.width.toString())
      formData.append('coverPhotoHeight', imageData.height.toString())
    } catch (error) {
      console.error('Error processing cover photo:', error)
    }
  }

  if (values.type === "in-person") {
    formData.append("venueAddress", JSON.stringify({
      street_one: values["venueAddress.street_one"],
      street_two: values["venueAddress.street_two"] || undefined,
      location: values["venueAddress.location"],
      zip: values["venueAddress.zip"]
    }))
  }

  if (values.durationFrequency === "recurring") {
    const recurringData = {
      frequency: values.recurringFrequency,
      count: values.recurringCount
    }
    formData.delete("firstOccurence")
    formData.delete("startTime")
    formData.delete("endTime")
    formData.delete("recurringFrequency")
    formData.delete("recurringCount")
    formData.append("recurringData", JSON.stringify(recurringData))
  }

  try {
    const response = await API().post(`/events`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    submitValues.value = formData
    newEventData.value = response.data.data
    submitted.value = true
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

function handleLateChange(value: boolean) {
  lateLocked.value = value
}

function setMaxDate(tools: any) {
  const durationFrequency = tools.getFieldValue('durationFrequency')
  if (durationFrequency === 'one-time') {
    return new Date(new Date(tools.getFieldValue('startDateTime')).getTime() + 86400000)
  }
  if (durationFrequency === 'multi-day') {
    return new Date(new Date(tools.getFieldValue('startDateTime')).getTime() + 1026700000)
  }
}

function setEndMinDate(tools: any) {
  const durationFrequency = tools.getFieldValue('durationFrequency')
  if (durationFrequency === 'one-time') {
    return new Date(new Date(tools.getFieldValue('startDateTime')).getTime())
  }
  if (durationFrequency === 'multi-day') {
    // at least 1 day after the start date
    return new Date(new Date(tools.getFieldValue('startDateTime')).getTime() + 86400000)
  }
}
</script>

<template>
  <div class="px-2">
    <div class="container mx-auto EventList">
      <aside>
        <ol class="text-sm">
          <li v-if="currentStep === 1 && !submitted">
            <h2 class="font-bold">Step 1</h2>
            <p>Tell us what type of event you're creating. Once you create an event, the type cannot be changed.</p>
          </li>
          <li v-if="currentStep === 2 && !submitted">
            <h2 class="font-bold">Step 2</h2>
            <p>Tell us about your event. This is the information that will be displayed on Submit. You can always edit and add to this later.</p>
          </li>
          <li v-if="currentStep === 3 && !submitted">
            <h2 class="font-bold">Step 3</h2>
            <p>When (and where) is your event happening? You can edit some venue or virtual details later.</p>
          </li>
          <li v-if="currentStep === 4 && !submitted">
            <h2 class="font-bold">Step 4</h2>
            <p>Configure your event's privacy settings.</p>
          </li>
          <li v-if="submitted">
            <h2 class="font-bold">Completed</h2>
            <p>Your event has been created. You can edit some details later.</p>
          </li>
        </ol>
      </aside>
      <Form @on-submit="handleSubmit" @step-changed="handleStepChanged" :schema="schema" :initial-values="initialValues" v-slot="{ fields, errors, tools, steps }" :steps="4" v-if="!submitted">
        <template v-if="currentStep === 1">
          <section class="block space-y-3 rounded-t-lg px-4 pt-4 dark:bg-submit-900 bg-white">
            <div>
              <Label field="type">Event Type</Label>
              <RadioGroup name="type" :options="radioOptions" :step="1" persist alt />
            </div>
          </section>
        </template>
        <template v-if="currentStep === 2">
          <File name="coverPhoto" :step="2" persist />
          <section class="space-y-3 px-4 pt-4 dark:bg-submit-900 bg-white">
            <div>
              <Label field="name">Name</Label>
              <Field name="name" :step="2" persist alt/>
            </div>
            <div>
              <Label field="category">Category</Label>
              <Field name="category" type="select" :step="2" persist alt>
                <option value="">Select a category</option>
                <option value="submit-event" v-if="isAdmin">Submit Event</option>
                <option value="educational">Educational</option>
                <option value="social">Social</option>
                <option value="kink">Kink Party</option>
                <option value="bdsm">BDSM Party</option>
                <option value="sex">Sex Party</option>
                <option value="conference">Conference or Festival</option>
              </Field>
            </div>
            <div>
              <Label field="description">Description</Label>
              <Editor name="description" editor-style="alt" :step="2" persist/>
            </div>
            <div>
              <Label field="cost">Cost</Label>
              <Editor name="cost" editor-style="alt" :step="2" persist/>
            </div>
            <div>
              <Label field="dressCode">Dress Code (Optional)</Label>
              <Editor name="dressCode" editor-style="alt" :step="2" persist/>
            </div>
            <div>
              <Label field="participantLimit">Participant Limit (Optional)</Label>
              <Field name="participantLimit" type="number" :step="2" :help-text="`If set, the event will be limited to this number of participants.`" persist alt/>
            </div>
            <div>
              <Label field="website">Website (Optional)</Label>
              <Field name="website" subtype="url" :step="2" persist alt/>
            </div>
            <div>
              <Label field="tags">Tags (Optional)</Label>
              <Tags name="tags" :step="2" :help-text="`Max 6. Hit enter or comma to add a tag.`" persist alt/>
            </div>
          </section>
        </template>
        <template v-if="currentStep === 3">
          <section class="block space-y-3 rounded-t-lg px-4 pt-4 dark:bg-submit-900 bg-white">
            <div>
              <Label field="durationFrequency">Duration &amp; Frequency</Label>
              <Field name="durationFrequency" type="select" :step="3" @change="(e) => handleDFChange(e, tools)" persist alt>
                <option value="">Select an option</option>
                <option value="one-time">Single One-Day Event</option>
                <option value="recurring" v-if="isAdmin">Recurring One-Day Event</option>
                <option value="multi-day" v-if="isAdmin">Single Multi-Day Event</option>
              </Field>
            </div>
            <div class="grid grid-cols-2 gap-4" v-if="getFieldValue(tools, 'durationFrequency') === 'one-time' || getFieldValue(tools, 'durationFrequency') === 'multi-day'">
              <div>
                <Label field="startDateTime">Start Date</Label>
                <DatePicker name="startDateTime" :step="3" :min-date="minStartDateTime" @new-date="handleStartDateTime" persist :show-time="false" alt />
              </div>
              <div>
                <Label field="endDateTime">End Date</Label>
                <DatePicker name="endDateTime" :step="3" :min-date="setEndMinDate(tools)" :max-date="setMaxDate(tools)" @new-date="handleEndDateTime" persist :show-time="false" alt />
              </div>
              <div v-if="getFieldValue(tools, 'durationFrequency') === 'one-time'">
                <Label field="startTime">Start Time</Label>
                <DatePicker name="startTime" :step="3" :time-only="true" persist alt />
              </div>
              <div v-if="getFieldValue(tools, 'durationFrequency') === 'one-time'">
                <Label field="endTime">End Time</Label>
                <DatePicker name="endTime" :step="3" :time-only="true" persist alt />
              </div>
            </div>
            <div class="text-sm grid gap-4" v-if="getFieldValue(tools, 'durationFrequency') === 'multi-day'">
              <EventDays name="eventDays" :step="3" :persist="getFieldValue(tools, 'durationFrequency') === 'multi-day'" alt/>
            </div>
            <div class="grid gap-4 text-sm" v-if="getFieldValue(tools, 'durationFrequency') === 'recurring'">
              <div>
                <Label field="firstOccurence">First Occurence Date</Label>
                <DatePicker name="firstOccurence" :step="3" :min-date="minStartDateTime" persist :show-time="false" alt />
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <Label field="startTime">Start Time</Label>
                  <DatePicker name="startTime" :step="3" :time-only="true" persist alt />
                </div>
                <div>
                  <Label field="endTime">End Time</Label>
                  <DatePicker name="endTime" :step="3" :time-only="true" :locked="lateLocked" persist alt />
                  <Checkbox class="mt-1" name="endsAfterMidnight" :step="3" persist @changed="handleLateChange">
                    Ends after midnight
                  </Checkbox>
                </div>
                <div>
                  <Label field="recurringFrequency">Frequency</Label>
                  <Field name="recurringFrequency" type="select" :step="3" :options="recurringFrequencyOptions" persist alt />
                </div>
                <div>
                  <Label field="recurringCount">Number of Occurrences</Label>
                  <Field name="recurringCount" type="number" :step="3" :min="2" :max="6" placeholder="2-6" persist alt />
                </div>
              </div>
              <RecurringDays name="recurringDays" :step="3" :persist="getFieldValue(tools, 'durationFrequency') === 'recurring'" alt />
            </div>
            <div>
              <Label field="timezone">Timezone</Label>
              <Field name="timezone" type="select" :step="3" help-text="The timezone that will be used for the event." persist alt>
                <option value="">Select a timezone</option>
                <option v-for="tz in timezones" :key="tz.value" :value="tz.value">
                  {{ tz.label }}
                </option>
              </Field>
            </div>
          </section>
          <section class="pt-6 pb-3 dark:bg-submit-900 bg-white px-4">
            <hr class="dark:border-gray-700 border-gray-200" />
          </section>
          <section class="space-y-3 dark:bg-submit-900 bg-white pt-3 px-4">
            <template v-if="getEventType(tools) === 'in-person'">
              <div>
                <Label field="venueName">Venue Name</Label>
                <Field name="venueName" :step="3" persist alt/>
              </div>
              <div>
                <h3 class="font-bold text-sm">Venue Address</h3>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <Label field="venueAddress.street_one">Street 1</Label>
                  <Field name="venueAddress.street_one" :step="3" persist alt/>
                </div>
                <div>
                  <Label field="venueAddress.street_two">Street 2</Label>
                  <Field name="venueAddress.street_two" :step="3" persist alt/>
                </div>
                <div>
                  <Location name="venueAddress.location" :step="3" :is-set="false" persist alt/>
                </div>
                <div>
                  <Label field="venueAddress.zip">Zip/Postal Code</Label>
                  <Field name="venueAddress.zip" :step="3" persist alt/>
                </div>
                <div class="col-span-2">
                  <Label field="venueDetails">Venue Details</Label>
                  <Editor name="venueDetails" editor-style="alt" :step="3" help-text="Any additional details about the venue." persist/>
                </div>
                <div>
                  <h3 class="font-bold text-sm">Venue Accessibility &amp; Parking</h3>
                </div>
                <div class="col-span-full grid grid-cols-2 gap-4">
                  <div>
                    <Checkbox name="venueWheelchair" :step="3" persist>
                      Wheelchair Accessible
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox name="venuePublicTransportation" :step="3" persist>
                      Public Transportation Accessible
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox name="venueParking" :step="3" persist>
                      Parking Available
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox name="venueAgeRestricted" :step="3" persist>
                      Age Restricted/ID Required
                    </Checkbox>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="getEventType(tools) === 'virtual'">
              <div>
                <Label field="virtualFormat">Virtual Format</Label>
                <Field name="virtualFormat" type="select" :step="3" @change="(e) => handleVirtualFormatChange(e, tools)" persist alt>
                  <option value="">Select a virtual format</option>
                  <option value="zoom">Zoom</option>
                  <option value="jitsi">Jitsi</option>
                  <option value="google-meet">Google Meet</option>
                  <option value="discord">Discord</option>
                  <option value="skype">Skype</option>
                  <option value="other">Other</option>
                </Field>
              </div>
              <div v-if="getFieldValue(tools, 'virtualFormat') === 'other'">
                <Label field="virtualFormatOther">Other Virtual Format</Label>
                <Field name="virtualFormatOther" :step="3" :persist="getFieldValue(tools, 'virtualFormat') === 'other'" alt />
              </div>
              <div>
                <Label field="virtualUrl">Virtual URL</Label>
                <Field name="virtualUrl" :step="3" persist alt/>
              </div>
              <div>
                <Label field="virtualDetails">Virtual Details</Label>
                <Editor name="virtualDetails" editor-style="alt" :step="3" help-text="Details about how to connect or participate in the virtual event." persist/>
              </div>
              <div>
                <h3 class="font-bold text-sm">Location Restriction</h3>
                <p class="text-sm text-gray-500 dark:text-gray-400">You can choose to restrict this event to a specific location. This is not automatically enforced by our system.</p>
              </div>
              <div v-if="getFieldValue(tools, 'type') === 'virtual'">
                <Checkbox name="virtualLocationEnabled" :step="3" persist @changed="(v) => handleVirtualLocationChange(v, tools)">
                  Restrict this event to a specific location
                </Checkbox>
              </div>
              <div v-if="getFieldValue(tools, 'virtualLocationEnabled') === true">
                <Location name="virtualLocation" :step="3" :isSet="false" persist alt/>
              </div>
            </template>
          </section>
        </template>
        <template v-if="currentStep === 4">
          <section class="block space-y-3 rounded-t-lg px-4 pt-4 dark:bg-submit-900 bg-white">
            <div>
              <h3 class="font-bold text-sm">Privacy Options</h3>
              <p class="text-sm text-gray-500 dark:text-gray-400">An event's privacy settings determine who can see the event and its details. These settings are not visible to those attending or considering to attend and cannot be changed once the event is created.</p>
            </div>
            <div>
              <Checkbox name="requestOnly" :step="4" persist>
                Require users to request to attend this event
              </Checkbox>
            </div>
            <div>
              <Checkbox name="hideVenue" :step="4" :locked="getFieldValue(tools, 'requestOnly')" :locked-value="true" :help-text="getFieldValue(tools, 'requestOnly') ? 'This option is mandatory when requiring requests.' : undefined" persist>
                Hide the {{ getFieldValue(tools, 'type') === 'in-person' ? 'venue details' : 'virtual details' }} from public view (users who have RSVPed will be able to see them)
              </Checkbox>
            </div>
            <div>
              <Checkbox name="addCreatorBlocklist" :step="4" persist>
                Add users from your blocklist to this event's blocklist
              </Checkbox>
            </div>
            <div>
              <Checkbox name="hideFromListings" :step="4" persist>
                Do not show this event in Submit's event listings (it'll still show up on your profile and the profile of those attending or considering to attend)
              </Checkbox>
            </div>
            <div>
              <h3 class="font-bold text-sm">General Options</h3>
              <p class="text-sm text-gray-500 dark:text-gray-400">These settings are not visible to those attending or considering to attend and cannot be changed once the event is created.</p>
            </div>
            <div>
              <Checkbox name="eventInbox" :step="4" persist>
                Enable Inbox for this event (Coming soon)
              </Checkbox>
            </div>
            <div>
              <Checkbox name="faq" :step="4" persist>
                Enable Frequently Asked Questions (FAQ) for this event (Coming soon)
              </Checkbox>
            </div>
          </section>
        </template>
        <div class="flex gap-4 px-4 pb-4 pt-6 rounded-b-lg dark:bg-submit-900 bg-white">
          <button type="button" class="flex peer/prev basis-1/2 py-4 font-display font-bold text-sm justify-center" @click="tools.previousStep" v-if="steps.steps > 1 && steps.currentStep !== 1" :disabled="working">
            Previous
          </button>
          <button class="flex basis-full peer-[]/prev:basis-1/2 py-4 font-display font-bold text-sm rounded-md justify-center bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black" type="submit" :disabled="working">
            {{
            steps.steps > 1 && steps.currentStep !== steps.steps ?
            'Next' :
            working ? 'Creating...' : 'Create Event'
            }}
          </button>
        </div>
        <div v-if="debug">
          <pre class="whitespace-pre-wrap">
{{ fields }}
          </pre>
          <pre class="whitespace-pre-wrap">
{{ errors }}
          </pre>
          <pre class="whitespace-pre-wrap">
{{ steps }}
          </pre>
        </div>
      </Form>
      <div class="space-y-3" v-if="submitted">
        <h2 class="font-bold text-sm">Event Created!</h2>
        <p>You're all set! Your event details are now live, and attendees can start requesting to join if you've chosen that option. We're excited to see your event bring people together!</p>
        <router-link :to="`/e/${newEventData.short}`" class="flex bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black font-display font-bold text-sm rounded-md py-4 px-6 w-fit">
          View Your Event
        </router-link>
      </div>
    </div>
  </div>
</template>
