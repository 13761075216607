<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import { computed } from "vue"

const UserStore = useUserStore()
const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)
const { roles } = storeToRefs(UserStore)
const isAdmin = computed(() => roles.value.includes("admin"))
</script>

<template>
  <main v-if="!loading">
    <PageHeader>Organizations</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page">
        <div class="col-span-full space-y-6">
          <p>This feature is coming soon, check the <router-link class="underline hover:decoration-2 dark:hover:decoration-gold-500" to="/roadmap">roadmap</router-link> and/or <router-link class="underline hover:decoration-2 dark:hover:decoration-gold-500" to="/groups/submit-development">Submit Development</router-link> for updates and more details.</p>
          <p>While you wait, you can <router-link class="underline hover:decoration-2 dark:hover:decoration-gold-500" to="/reservations/organizations">reserve an organization</router-link> to secure the name you want.</p>
          <p v-if="isAdmin">As an admin, you can <router-link class="underline hover:decoration-2 dark:hover:decoration-gold-500" to="/organizations/create">create an organization</router-link>.</p>
        </div>
      </div>
    </div>
  </main>
</template>
