<script setup lang="ts">
import PageHeader from "@/components/core/PageHeader.vue"
import { ref, onBeforeMount } from "vue"
import API from "@/api/api"

const vaultActive = ref<boolean>(false)
const vaultData = ref<any>(null)

onBeforeMount(async () => {
  try {
    const response = await API().get("/vault")
    vaultData.value = response.data.data
  } catch (error) {
    console.error(error)
  }
})
</script>

<template>
  <div>
    <PageHeader>Personal Data Vault</PageHeader>
    <div class="px-2">
      <section class="Page Vault container mx-auto">
        <div class="col-span-1 md:col-span-8" v-if="vaultActive">
          <!-- Start Controls -->
          <div class="grid grid-cols-1">
            <div class="flex justify-between text-[10px] dark:text-indigo-325 mb-6">
              <div>0 selected</div>
              <ul class="flex gap-x-4">
                <li class="flex items-center gap-x-2">
                  <svg class="h-3 dark:fill-indigo-325" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
                  <span>Revoke</span>
                </li>
                <li class="flex items-center gap-x-2">
                  <svg class="h-3 dark:fill-indigo-325" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                  <span>Delete</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- End Controls -->
          <!-- Start Files -->
          <div class="flex flex-col space-y-4">
            <div class="flex flex-row text-[#524F66] border-b pb-2 border-indigo-350 items-center">
              <svg class="w-4 mr-2 fill-[#524F66]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
              <div class="w-full">Metadata (1)</div>
              <div class="text-xs mr-4">1mb</div>
              <svg class="h-4 fill-[#524F66]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
            </div>
            <div class="flex flex-row text-[#524F66] border-b pb-2 border-indigo-350 items-center">
              <svg class="w-4 mr-2 fill-[#524F66]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
              <div class="w-full">Media (1)</div>
              <div class="text-xs mr-4">&lt;&nbsp;1mb</div>
              <svg class="h-4 fill-[#524F66]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
            </div>
          </div>
          <!-- End Files -->
          <!-- Start Totals -->
          <div class="flex flex-col mt-4">
            <div class="flex flex-row text-[#524F66] items-center">
              <div class="w-full">All Data</div>
              <div class="text-xs mr-4">1.4mb</div>
              <svg class="h-4 fill-[#524F66]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
            </div>
          </div>
          <!-- End Totals -->
        </div>

        <div class="col-span-1 md:col-span-8 space-y-6" v-else>
          <p>Vault controls unavailable at this time.</p>
          <p>While specific controls are unavailable right now, Vaults are always active and protecting your data. To learn more about Vaults, and when Vault controls will be available, stay tuned to the <router-link class="underline hover:decoration-2 dark:hover:decoration-gold-500" to="/groups/submit-development">Submit Development</router-link> group.</p>
        </div>

        <div class="col-span-1 row-start-1 grid md:row-start-auto md:col-span-4">
          <div class="flex flex-wrap flex-row mb-8 gap-2 w-full">
            <button class="flex px-10 bg-green-800 text-green-300 flex-grow text-xs font-bold py-4 rounded-md justify-center items-center gap-2" :disabled="!vaultActive">
              <svg class="h-4 fill-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M144 144c0-44.2 35.8-80 80-80c31.9 0 59.4 18.6 72.3 45.7c7.6 16 26.7 22.8 42.6 15.2s22.8-26.7 15.2-42.6C331 33.7 281.5 0 224 0C144.5 0 80 64.5 80 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H144V144z"/></svg>
              <span>Vault Unlocked</span>
            </button>
            <button class="flex flex-grow text-red-600 px-10 bg-[#3D1529] text-xs font-bold py-4 rounded-md justify-center items-center gap-2" v-if="vaultActive">
              <svg class="h-4 fill-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
              <span>Lock</span>
            </button>
            <button class="flex text-indigo-325 px-10 w-full bg-white dark:bg-submit-600 text-xs font-bold py-4 rounded-md justify-center items-center align-middle gap-2" v-if="vaultActive">
              <svg class="h-4 fill-indigo-325" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
              <span>Import Data</span>
            </button>
            <button class="flex text-indigo-325 px-10 w-full bg-white dark:bg-submit-600 text-xs font-bold py-4 rounded-md justify-center items-center align-middle gap-2" v-if="vaultActive">
              <svg class="h-4 fill-indigo-325" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
              <span>Export Data</span>
            </button>
          </div>
          <details class="flex md:hidden group">
            <summary class="flex gap-2">
              <svg class="w-4 fill-[#524F66] group-open:rotate-90 transition-all" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
              <span class="text-sm font-semibold dark:text-white">Vault Details &amp; Grants</span>
            </summary>
            <div class="md:hidden mt-4">
              <h3 class="font-semibold text-sm dark:text-white mb-3">Vault Consent Grants</h3>
              <dl class="grid grid-cols-2 text-sm dark:text-gray-400">
                <dt>Platform Consent:</dt>
                <dd>Granted<span class="text-xs dark:text-gray-600" v-if="vaultActive">&nbsp;(Revoke)</span></dd>
              </dl>
            </div>
            <div class="md:hidden mt-4">
              <h3 class="font-semibold text-sm dark:text-white mb-3">Vault Details</h3>
              <dl class="grid grid-cols-2 gap-y-2 text-sm dark:text-gray-400">
                <dt class="font-semibold">Version (spec):</dt>
                <dd>{{ vaultData ? vaultData.spec : 'loading...' }}</dd>
                <dt class="font-semibold">DNS Identifier</dt>
                <dd>{{ vaultData ? `@${vaultData.dns}` : 'loading...' }}</dd>
                <dt class="font-semibold">Vault Host:</dt>
                <dd>{{ vaultData ? vaultData.host : 'loading...' }}</dd>
                <dt class="font-semibold col-span-2">Vault ID:</dt>
                <dd class="col-span-2 break-modern"><code>{{ vaultData ? vaultData.bech32 : 'loading...' }}</code></dd>
              </dl>
            </div>
          </details>
          <div class="mb-8 hidden md:block text-sm">
            <h3 class="font-semibold dark:text-white mb-2">Vault Consent Grants</h3>
            <dl class="grid grid-cols-2 dark:text-indigo-325">
              <dt>Platform Consent:</dt>
              <dd>Granted<span class="text-xs dark:text-gray-600" v-if="vaultActive">&nbsp;(Revoke)</span></dd>
            </dl>
          </div>
          <div class="text-sm dark:text-gray-400 hidden md:block">
            <h3 class="font-semibold dark:text-white mb-2">Vault Details</h3>
            <dl class="grid grid-cols-2 mb-8 gap-y-2">
              <dt class="font-semibold">Version (spec):</dt>
              <dd>{{ vaultData ? vaultData.spec : 'loading...' }}</dd>
              <dt class="font-semibold">DNS Identifier</dt>
              <dd>{{ vaultData ? `@${vaultData.dns}` : 'loading...' }}</dd>
              <dt class="font-semibold">Vault Host:</dt>
              <dd>{{ vaultData ? vaultData.host : 'loading...' }}</dd>
              <dt class="font-semibold col-span-2">Vault ID:</dt>
              <dd class="col-span-2 break-modern"><code>{{ vaultData ? vaultData.bech32 : 'loading...' }}</code></dd>
            </dl>
            <div class="flex gap-x-2 items-center mb-2" v-if="vaultActive">
              <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm70.3 55.8c-9 9.8-8.3 25 1.5 33.9L148.5 256 71.8 326.3c-9.8 9-10.4 24.1-1.5 33.9s24.1 10.4 33.9 1.5l96-88c5-4.5 7.8-11 7.8-17.7s-2.8-13.1-7.8-17.7l-96-88c-9.8-9-25-8.3-33.9 1.5zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H216z"/></svg>
              <span>Edit your Vault Spec</span>
            </div>
            <div class="flex gap-x-2 items-center" v-if="vaultActive">
              <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M416 32H384c-17.7 0-32 14.3-32 32s14.3 32 32 32h32c17.7 0 32 14.3 32 32v37.5c0 25.5 10.1 49.9 28.1 67.9L498.7 256l-22.6 22.6c-18 18-28.1 42.4-28.1 67.9V384c0 17.7-14.3 32-32 32H384c-17.7 0-32 14.3-32 32s14.3 32 32 32h32c53 0 96-43 96-96V346.5c0-8.5 3.4-16.6 9.4-22.6l45.3-45.3c12.5-12.5 12.5-32.8 0-45.3l-45.3-45.3c-6-6-9.4-14.1-9.4-22.6V128c0-53-43-96-96-96zM160 32c-53 0-96 43-96 96v37.5c0 8.5-3.4 16.6-9.4 22.6L9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l45.3 45.3c6 6 9.4 14.1 9.4 22.6V384c0 53 43 96 96 96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H160c-17.7 0-32-14.3-32-32V346.5c0-25.5-10.1-49.9-28.1-67.9L77.3 256l22.6-22.6c18-18 28.1-42.4 28.1-67.9V128c0-17.7 14.3-32 32-32h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H160z"/></svg>
              <span>Download your Vault Spec</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
