<script setup lang="ts">
import List from "@/components/input/starterpacks/List.vue"
import TypeAhead from "@/components/input/TypeaheadV2.vue"
import PageHeader from "@/components/core/PageHeader.vue"
import { onMounted, ref, reactive, computed } from "vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { usePlatformStore } from "@/stores/Platform"
import Follow from "@/components/actions/Follow.vue"
import { useRoute, useRouter } from "vue-router"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import setupCommons from "@/composables/Common"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const { actionHandler } = setupCommons()

const Platform = usePlatformStore()
const User = useUserStore()
const { roles } = storeToRefs(User)

const schema = zod.object({
  name: zod.string().min(1, { message: "Please enter a name" }),
  description: zod.string().min(5, { message: "Please enter a description" }).max(512, { message: "Limited to 512 characters." }),
  interest: zod.object({
    name: zod.string().optional()
  }, { invalid_type_error: ""}).nullable(),
  users: zod.array(zod.object({
    _id: zod.string(),
    username: zod.string()
  })).min(roles.value.includes("admin") ? 1 : 2, { message: "Please select at least 2 users" }),
  groups: zod.array(zod.object({
    _id: zod.string(),
    name: zod.string()
  })).optional()
})

const initialValues = reactive({
  name: "",
  description: "",
  interest: null,
  users: [],
  groups: []
})

const Route = useRoute()
const Router = useRouter()
const pack = ref(Route.params.pack)

const view = ref("users")
const groups = ref<any[]>([])

const packData = ref<any>(null)
const content = reactive({
  contentAuthor: "",
  contentId: "",
  contentType: "",
  contentAudience: "public"
})

const debug = ref(false)
const editMode = ref(false)
const working = ref(false)
const users = ref<any[]>([])
const currentPage = ref(0)
const isLoading = ref(false)
const hasMore = ref(true)
const observerTarget = ref(null)

const used = ref(false)

const isAdmin = computed(() => roles.value.includes("admin"))
const deleteModalOpen = ref(false)
const toggleDeleteModal = () => deleteModalOpen.value = !deleteModalOpen.value

const error = ref(false)
const errorMessage = ref("")
const errorArray = ref<any[]>([])
const errorCode = ref("")

onMounted(async () => {
  try {
    const path = `/starterpacks/${pack.value}`
    const result = await API().get(path)
    packData.value = result.data.data
    content.contentAuthor = packData.value.user
    content.contentId = packData.value._id
    content.contentType = "starterpack"
    groups.value.push(...packData.value.groups)

    // set initial values
    initialValues.name = packData.value.name
    initialValues.description = packData.value.description
    initialValues.interest = packData.value.interest
    initialValues.users = packData.value.users
    initialValues.groups = packData.value.groups

    used.value = packData.value.used || false

    // Initialize intersection observer after data is loaded
    if (packData.value.users?.length) {
      setupIntersectionObserver()
      await getUsers()
    }
  } catch (err: any) {
    // check the status code
    if (err.response.status === 404) {
      Router.push('/notfound')
    } else {
      console.error(err)
    }
  }
})

async function getUsers() {
  if (isLoading.value || !hasMore.value) return

  isLoading.value = true
  const pageSize = 12
  const start = currentPage.value * pageSize
  const usersBatch = packData.value.users.slice(start, start + pageSize)

  if (usersBatch.length === 0) {
    hasMore.value = false
    isLoading.value = false
    return
  }

  try {
    const result = await API().post(`/starterpacks/users`, {
      pack: packData.value._id,
      users: usersBatch
    })
    users.value.push(...result.data.data)
    currentPage.value++
  } catch (error) {
    console.error('Error fetching users:', error)
  } finally {
    isLoading.value = false
  }
}

async function resetAndRefetchUsers() {
  // Reset all user-related state
  users.value = []
  currentPage.value = 0
  isLoading.value = false
  hasMore.value = true

  // Re-fetch users
  if (packData.value?.users?.length) {
    await getUsers()
  }
}

// function getUserCount(fields: Map<string, any>) {
//   // Check if fields exists and has users entry
//   if (!fields || !fields.has('users')) return 0

//   // Get the users field from the map
//   const usersField = fields.get('users')
//   return usersField?.value?.length || 0
// }

function setupIntersectionObserver() {
  const observer = new IntersectionObserver(
    entries => {
      if (entries[0].isIntersecting) {
        getUsers()
      }
    },
    { threshold: 0.5 }
  )

  if (observerTarget.value) {
    observer.observe(observerTarget.value)
  }
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  try {
    // publish the pack
    await updatePack(values)
    working.value = false
    // use vue router to reload the page
    Router.go(0)
  } catch (err: any) {
    error.value = true

    // Handle axios error with response
    if (err.response && err.response.data) {
      const { message, errors, code } = err.response.data
      errorMessage.value = message || 'An unknown error occurred'
      errorCode.value = code || 'UNKNOWN_ERROR'
      errorArray.value = errors || []
    }
    // Handle other types of errors
    else {
      errorMessage.value = err.message || 'An unexpected error occurred'
      errorCode.value = 'INTERNAL_ERROR'
      errorArray.value = []
    }
  }
}

async function usePack() {
  if (working.value) return
  working.value = true

  try {
    const data = {
      packId: packData.value._id,
      followToken: packData.value.followToken
    }

    await API().post(`/starterpacks/follow`, data)
    await resetAndRefetchUsers()
  } catch (err: any) {
    error.value = true
    working.value = false

    // Handle axios error with response
    if (err.response && err.response.data) {
      const { message, errors, code } = err.response.data
      errorMessage.value = message || 'An unknown error occurred'
      errorCode.value = code || 'UNKNOWN_ERROR'
      errorArray.value = errors || []
    }
    // Handle other types of errors
    else {
      errorMessage.value = err.message || 'An unexpected error occurred'
      errorCode.value = 'INTERNAL_ERROR'
      errorArray.value = []
    }
  }
}

async function updatePack(values: any) {
  try {
    const path = `/starterpacks/${packData.value._id}`
    let data: any = {
      name: values.name,
      description: values.description,
      users: values.users,
      groups: values.groups?.map((group: any) => group._id)
    }
    if (values.interest) {
      data.interest = values.interest._id
      data.interestType = values.interest.type
      data.interestName = values.interest.name
      data.interestSlug = values.interest.slug
    }
    const result = await API().put(path, data)
    return Promise.resolve(result.data)
  } catch (err) {
    working.value = false
    return Promise.reject(err)
  }
}

async function deletePack() {
  if (working.value) return
  working.value = true

  try {
    const path = `/starterpacks/${packData.value._id}`
    await API().delete(path)
    Router.push('/starter-packs')
  } catch (err: any) {
    error.value = true
    working.value = false

    // Handle axios error with response
    if (err.response && err.response.data) {
      const { message, errors, code } = err.response.data
      errorMessage.value = message || 'An unknown error occurred'
      errorCode.value = code || 'UNKNOWN_ERROR'
      errorArray.value = errors || []
    }
    // Handle other types of errors
    else {
      errorMessage.value = err.message || 'An unexpected error occurred'
      errorCode.value = 'INTERNAL_ERROR'
      errorArray.value = []
    }
  }
}

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: packData.value.user,
    contentId: packData.value._id,
    contentType: "starterpack"
  })
}
</script>

<template>
  <main>
    <PageHeader>Starter Packs</PageHeader>
    <div class="px-2">
      <div class="container mx-auto Page" v-if="packData && !editMode">
        <header class="col-span-full">
          <router-link class="text-gray-400 dark:text-gray-500 hover:text-black dark:hover:text-white text-sm" :to="packData.status === 'draft' ? '/starter-packs/yours' : `/starter-packs`">&lt; Back to {{ packData.status === 'draft' ? 'Your' : '' }} Starter Packs</router-link>
        </header>
        <div class="col-span-2 space-y-6">
          <div>
            <h1 class="text-2xl font-display font-semibold">{{ packData.name }}</h1>
            <h3 class="text-sm text-gray-500">by <router-link class="underline hover:text-black dark:hover:text-white" :to="`/${packData.userUsername}`">{{ packData.userUsername }}</router-link> &middot; Used {{ packData.useCount }} times</h3>
            <p class="my-4">{{ packData.description }}</p>
            <span v-if="packData.interest" class="capitalize font-bold">{{ packData.interest.type === 'official' ? 'Kink: ' : 'Interest: '}} </span>
            <router-link v-if="packData.interest" :to="`/${packData.interest.type === 'official' ? 'kinks' : 'interests'}/${packData.interest.slug}`" class="underline">{{ packData.interest.name}}</router-link>
          </div>
          <div class="flex justify-between items-center">
            <ReactV2 :self="packData.self" :reacted="packData.hasReacted ? packData.hasReacted : false" :reactions="packData.reactionsCount" :reaction-data="packData.reactionData ? packData.reactionData : undefined" :content="content" />
            <div class="flex gap-4 text-sm text-gray-400 dark:text-gray-500">
              <button type="button" @click="editMode = true" class="hover:text-black dark:hover:text-white" v-if="packData.self">Edit</button>
              <button type="button" @click="toggleDeleteModal" class="hover:text-black dark:hover:text-white" v-if="packData.self || isAdmin">Delete</button>
              <button v-if="!packData.self" class="flex gap-2 items-center group text-gray-400 dark:hover:text-white text-sm" @click="openReportDialog" type="button">
                <figure
                  :class="[
                    'flex flex-none w-3 h-3 justify-center items-center'
                  ]"
                >
                  <svg class="fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                </figure>
                Report
              </button>
              <TransitionRoot appear :show="deleteModalOpen" as="template">
                <Dialog as="div" @close="toggleDeleteModal" class="relative z-[100]">
                  <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                  >
                    <div class="fixed inset-0 bg-black bg-opacity-25" />
                  </TransitionChild>
                  <div class="fixed inset-0 overflow-y-auto">
                    <div
                      class="flex min-h-full items-center justify-center p-4 text-center"
                    >
                      <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                      >
                        <DialogPanel
                          class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                        >
                          <DialogTitle
                            as="h3"
                            class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                          >
                            Delete Starter Pack
                          </DialogTitle>
                          <div class="flex flex-col mt-2 space-y-4">
                            <p class="text-sm">Are you sure you want to permanently delete this Starter Pack? This action cannot be undone.</p>
                            <div class="flex self-end">
                              <button
                                type="button"
                                @click="toggleDeleteModal"
                                class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                                :disabled="working"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                @click="deletePack"
                                class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                :disabled="working"
                              >
                                {{ working ? "Deleting..." : "Delete" }}
                              </button>
                            </div>
                          </div>
                        </DialogPanel>
                      </TransitionChild>
                    </div>
                  </div>
                </Dialog>
              </TransitionRoot>
            </div>
          </div>
          <nav class="flex items-center justify-between">
            <ul class="flex gap-8 text-sm">
              <li>
                <button
                  :class="[
                    view === 'users' ? 'font-bold text-gold-700 dark:text-gold-500' : ''
                  ]"
                  class="hover:text-black dark:hover:text-white"
                  @click="view = 'users'"
                >
                  Users ({{ users.length }})
                </button>
              </li>
              <li v-if="groups.length">
                <button
                  :class="[
                    view === 'groups' ? 'font-bold text-gold-700 dark:text-gold-500' : ''
                  ]"
                  class="hover:text-black dark:hover:text-white"
                  @click="view = 'groups'"
                >
                  Groups ({{ groups.length }})
                </button>
              </li>
            </ul>
            <button class="dark:bg-gold-500 bg-gold-700 text-black rounded-md px-4 py-2 text-sm font-bold" v-if="view === 'users' && packData.canUsePack && !used" @click="usePack">Follow All</button>
            <div class="text-sm text-gray-400 dark:text-gray-500" v-else-if="view === 'users' && used">You've used this pack.</div>
          </nav>
          <div v-if="view === 'users'">
            <ul class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <li class="flex gap-y-2 gap-x-8 xl:gap-x-4 flex-col sm:flex-row flex-wrap sm:justify-between sm:items-center p-4 bg-white dark:bg-submit-925 rounded-md" v-for="user in users" :key="user._id">
                <div class="flex items-center">
                  <router-link :to="`/${user.followType === 'user' ? user.username : user.handle}`">
                    <figure class="flex flex-none w-12 h-12 bg-neutral-200 dark:bg-submit-500 rounded-full mr-2 items-center text-2xl justify-center font-semibold bg-cover capitalize" :style="user.pfp ? `background-image: url('${user.pfp}')` : ''">
                      {{ user.pfp ? '' : user.firstLetter }}
                    </figure>
                  </router-link>
                  <div>
                    <h1 class="flex text-base lg:text-lg font-display font-semibold sm:leading-6 break-all gap-x-2 items-baseline">
                      <a :href="`/${user.followType === 'user' ? user.username : user.handle}`">{{ user.followType === 'user' ? user.username : user.name }}</a>
                      <span title="Verified" class="inline" v-if="user.verified && user.followType === 'user'">
                        <svg class="h-3.5 fill-[#00C2FF]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                      </span>
                      <span title="Backer" class="inline" v-if="user.backer && user.followType === 'user'">
                        <svg
                          :class="[
                            user.backerBadge === 'default' ? 'dark:fill-gold-500 fill-gold-700' : '',
                            user.backerBadge === 'green' ? 'fill-green-500' : '',
                            user.backerBadge === 'pink' ? 'fill-pink-450' : '',
                            'h-3.5'
                          ]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M160 0c17.7 0 32 14.3 32 32V191.9l0 .1v96c0 35.3 28.7 64 64 64c19.1 0 36.3-8.4 48-21.7c11.7 13.3 28.9 21.7 48 21.7c35.3 0 64-28.7 64-64V224.1l0-.1V96c0-17.7 14.3-32 32-32s32 14.3 32 32V336c0 97.2-78.8 176-176 176H284.8c-59.6 0-116.9-22.9-160-64L12.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V32c0-17.7 14.3-32 32-32zm64 192c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm128 0c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/>
                        </svg>
                      </span>
                      <span title="Submit Staff" class="inline" v-if="user.staff && user.followType === 'user'">
                        <svg class="dark:fill-gold-500 fill-gold-700" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
                        </svg>
                      </span>
                      <span title="Submit Volunteer" class="inline" v-if="user.volunteer && user.followType === 'user'">
                        <svg class="dark:fill-gold-500 fill-gold-700" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
                        </svg>
                      </span>
                      <span v-if="user.followType === 'organization'" class="inline-flex self-center text-[0.625rem] leading-none bg-neutral-100 dark:bg-submit-500 py-1 px-2 rounded-sm font-semibold">Org</span>
                    </h1>
                    <h2 class="text-xs">{{ user.followType === 'user' ? user.meta.metaLabel : user.type === 'socialnetwork' ? 'Social Network' : 'Unknown Type' }}</h2>
                  </div>
                </div>
                <Follow :user="user" />
              </li>
            </ul>
          </div>
          <div v-if="view === 'groups' && groups.length">
            <ul @click="actionHandler" class="grid grid-cols-1 gap-4">
              <li data-to="`/groups/${group.slug}`" class="ListItem p-4 bg-white dark:bg-submit-925 rounded-md cursor-pointer" v-for="group in groups" :key="group">
                <router-link class="text-lg font-bold" :to="`/groups/${group.slug}`">{{ group.name }}</router-link>
                <ul class="flex gap-4 text-xs">
                  <li>Members: {{ group.memberCount }}</li>
                  <li>Posts: {{ group.postCount }}</li>
                </ul>
                <p class="mt-2">{{ group.summary }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container mx-auto Page" v-else-if="editMode">
        <div class="col-span-2">
          <Form :schema="schema" :initial-values="initialValues" @on-submit="handleSubmit" class="space-y-3" v-slot="{ fields, errors, tools }">
            <div>
              <Label field="name">Name</Label>
              <Field name="name" />
            </div>
            <div>
              <Label field="description">Description</Label>
              <Field name="description" type="textarea" rows="3" max-length="512" help-text="Limited to 512 characters." />
            </div>
            <div>
              <TypeAhead name="interest" type="interests" help-text="Optional. Limited to 1 kink or interest." />
            </div>
            <div class="grid md:grid-cols-2 gap-3">
              <div>
                <List name="users" type="users" />
              </div>
              <div>
                <List name="groups" type="groups" />
              </div>
            </div>
            <div class="flex justify-end gap-3">
              <button class="px-4 py-2 font-bold text-sm rounded-lg hover:dark:text-white hover:text-black hover:dark:bg-submit-500 hover:bg-white" type="button" @click="editMode = false">Cancel</button>
              <button class="px-4 py-2 font-bold text-sm dark:bg-submit-500 bg-white dark:text-gold-500 text-gold-700 rounded-lg" type="submit" :disabled="working">{{ working ? "Saving..." : "Update Pack" }}</button>
            </div>
            <div class="dark:bg-red-800 bg-red-200 rounded-lg p-4 space-y-3" v-if="error">
              <div>
                <strong>Error:</strong> {{ errorMessage }}{{ errorArray && errorArray.length > 0 ? ":" : "" }}
              </div>
              <ul class="list-disc list-inside text-sm" v-if="errorArray && errorArray.length > 0">
                <li v-for="errorElement in errorArray" :key="errorElement._id">
                  <router-link class="underline" :to="`/${errorElement.username}`">{{ errorElement.username }}</router-link>: {{ errorElement.reason }}
                </li>
              </ul>
              <div class="text-xs font-bold">
                {{ errorCode }}
              </div>
            </div>
            <div v-if="debug">
              <pre>
{{  fields }}
              </pre>
              <pre>
{{ errors }}
              </pre>
            </div>
          </Form>
        </div>
      </div>
      <div class="container mx-auto Page" v-else>
        Loading...
      </div>
    </div>
  </main>
</template>
